<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div :class="parentClass">
    <div :class="topBarClasses" v-show="step !== 23">
      <button class="backbutton" @click="backAction"><font-awesome-icon icon="chevron-left" /></button>
      <span class="barTotal"><span class="barProgress" :style="styleProgressBar"></span></span>
    </div>
    
    <div class="step step1" v-show="step === 1">
      <h3 class="text-center">Formulaire Comi</h3>
      <p class="color_color_grey_light2 text-center"><br>Des <strong>problèmes de dos</strong> peuvent entraîner des douleurs du dos et/ou des douleurs dans les jambes/fesses, ainsi que des troubles de la sensibilité tels des picotements, fourmillements, ou encore une diminution de la sensibilité dans ces mêmes régions.</p>
    </div>

    <div class="step step2" v-show="step === 2">
      <label>1. Parmi les problèmes suivants, lequel vous gêne le plus ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.genePlue='Douleur du dos'" :class="borderValue(this.formContainer.genePlue==='Douleur du dos')">Douleur du dos<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.genePlue='Douleur dans la jambe/fesse'" :class="borderValue(this.formContainer.genePlue==='Douleur dans la jambe/fesse')">Douleur dans la jambe/fesse<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.genePlue='Troubles de la sensibilité dans le dos/jambes/fesses'" :class="borderValue(this.formContainer.genePlue==='Troubles de la sensibilité dans le dos/jambes/fesses')">Troubles de la sensibilité dans le dos/jambes/fesses<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.genePlue='Aucun de ces problèmes'" :class="borderValue(this.formContainer.genePlue==='Aucun de ces problèmes')">Aucun de ces problèmes<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step3" v-show="step === 3">
      <label>2a. Quelle a été l’intensité de votre douleur de dos durant la semaine écoulée ? <span>(0 = aucune douleur, 10 = la pire douleur imaginable)</span></label>
      <div class="slider-wrapper slider-blue row">
        <div class="col-2"><button class="menus" @click="formContainer.douleurDos--"><font-awesome-icon icon="minus" /></button></div>
        <div class="col-8"><Slider :format="formatToolTip"  :min="-1" :max="10" v-model="formContainer.douleurDos" tooltipPosition="bottom" /></div>
        <div class="col-2"><button class="menus" @click="formContainer.douleurDos++"><font-awesome-icon icon="plus" /></button></div>
      </div>
    </div>

    <div class="step step4" v-show="step === 4">
      <label>2b. Quelle a été l’intensité de votre douleur dans la jambe (sciatique)/fesse durant la semaine écoulée ? <span>(0 = aucune douleur, 10 = la pire douleur imaginable)</span></label>
      <div class="slider-wrapper slider-blue row">
        <div class="col-2"><button class="menus" @click="formContainer.douleurFessesJambes--"><font-awesome-icon icon="minus" /></button></div>
        <div class="col-8"><Slider :format="formatToolTip" :min="-1" :max="10" v-model="formContainer.douleurFessesJambes" tooltipPosition="bottom" /></div>
        <div class="col-2"><button class="menus" @click="formContainer.douleurFessesJambes++"><font-awesome-icon icon="plus" /></button></div>
      </div>
    </div>

    <div class="step step5" v-show="step === 5">
      <label>3. Durant la semaine écoulée, à quel point vos douleurs de dos ont-elles gêné vos activités habituelles (votre travail comme vos activités de la vie quotidienne) ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.geneHabitudes='Pas du tout'" :class="borderValue(this.formContainer.geneHabitudes==='Pas du tout')">Pas du tout<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.geneHabitudes='Un peu'" :class="borderValue(this.formContainer.geneHabitudes==='Un peu')">Un peu<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.geneHabitudes='Modérément'" :class="borderValue(this.formContainer.geneHabitudes==='Modérément')">Modérément<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.geneHabitudes='Beaucoup'" :class="borderValue(this.formContainer.geneHabitudes==='Beaucoup')">Beaucoup<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.geneHabitudes='Enormément'" :class="borderValue(this.formContainer.geneHabitudes==='Enormément')">Enormément<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step6" v-show="step === 6">
      <label>4. Si vous deviez passer le restant de votre vie avec les symptômes que vous avez maintenant, comment vous sentiriez-vous ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.resteVieSymptomes='Très satisfait (e)'" :class="borderValue(this.formContainer.resteVieSymptomes==='Très satisfait (e)')">Très satisfait (e)<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.resteVieSymptomes='Plutôt satisfait (e)'" :class="borderValue(this.formContainer.resteVieSymptomes==='Plutôt satisfait (e)')">Plutôt satisfait (e)<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.resteVieSymptomes='Ni satisfait (e) ni insatisfait (e)'" :class="borderValue(this.formContainer.resteVieSymptomes==='Ni satisfait (e) ni insatisfait (e)')">Ni satisfait (e) ni insatisfait (e)<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.resteVieSymptomes='Plutôt insatisfait (e)'" :class="borderValue(this.formContainer.resteVieSymptomes==='Plutôt insatisfait (e)')">Plutôt insatisfait (e)<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.resteVieSymptomes='Très insatisfait (e)'" :class="borderValue(this.formContainer.resteVieSymptomes==='Très insatisfait (e)')">Très insatisfait (e)<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step7" v-show="step === 7">
      <label>5. En repensant à la semaine écoulée, comment évaluez-vous votre qualité de vie ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.semaineEcouleQualiteVie='Très bonne'" :class="borderValue(this.formContainer.semaineEcouleQualiteVie==='Très bonne')">Très bonne<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleQualiteVie='Bonne'" :class="borderValue(this.formContainer.semaineEcouleQualiteVie==='Bonne')">Bonne<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleQualiteVie='Moyenne'" :class="borderValue(this.formContainer.semaineEcouleQualiteVie==='Moyenne')">Moyenne<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleQualiteVie='Mauvaise'" :class="borderValue(this.formContainer.semaineEcouleQualiteVie==='Mauvaise')">Mauvaise<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleQualiteVie='Très mauvaise'" :class="borderValue(this.formContainer.semaineEcouleQualiteVie==='Très mauvaise')">Très mauvaise<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step8" v-show="step === 8">
      <label>6. Durant la semaine écoulée, vous êtes-vous senti(e) déprimé(e) ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.semaineEcouleDeprime='Pas du tout'" :class="borderValue(this.formContainer.semaineEcouleDeprime==='Pas du tout')">Pas du tout<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleDeprime='Un peu'" :class="borderValue(this.formContainer.semaineEcouleDeprime==='Un peu')">Un peu<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleDeprime='Modérément'" :class="borderValue(this.formContainer.semaineEcouleDeprime==='Modérément')">Modérément<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleDeprime='Beaucoup'" :class="borderValue(this.formContainer.semaineEcouleDeprime==='Beaucoup')">Beaucoup<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleDeprime='Enormément'" :class="borderValue(this.formContainer.semaineEcouleDeprime==='Enormément')">Enormément<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step9" v-show="step === 9">
      <label>7. Durant la semaine écoulée, vous êtes-vous senti(e) anxieux (anxieuse) ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.semaineEcouleAnxieux='Pas du tout'" :class="borderValue(this.formContainer.semaineEcouleAnxieux==='Pas du tout')">Pas du tout<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleAnxieux='Un peu'" :class="borderValue(this.formContainer.semaineEcouleAnxieux==='Un peu')">Un peu<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleAnxieux='Modérément'" :class="borderValue(this.formContainer.semaineEcouleAnxieux==='Modérément')">Modérément<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleAnxieux='Beaucoup'" :class="borderValue(this.formContainer.semaineEcouleAnxieux==='Beaucoup')">Beaucoup<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.semaineEcouleAnxieux='Enormément'" :class="borderValue(this.formContainer.semaineEcouleAnxieux==='Enormément')">Enormément<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step10" v-show="step === 10">
      <label>8. Durant les quatre dernières semaines, pendant combien de jours avez-vous dû diminuer vos activités habituelles (travail, activité domestiques, école, loisirs) en raison de votre problème de dos ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.quatreSemaineDimAct='Aucun'" :class="borderValue(this.formContainer.quatreSemaineDimAct==='Aucun')">Aucun<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.quatreSemaineDimAct='Entre 1 et 7 jours'" :class="borderValue(this.formContainer.quatreSemaineDimAct==='Entre 1 et 7 jours')">Entre 1 et 7 jours<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.quatreSemaineDimAct='Entre 8 et 14 jours'" :class="borderValue(this.formContainer.quatreSemaineDimAct==='Entre 8 et 14 jours')">Entre 8 et 14 jours<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.quatreSemaineDimAct='Entre 15 et 21 jours'" :class="borderValue(this.formContainer.quatreSemaineDimAct==='Entre 15 et 21 jours')">Entre 15 et 21 jours<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.quatreSemaineDimAct='Plus de 22 jours'" :class="borderValue(this.formContainer.quatreSemaineDimAct==='Plus de 22 jours')">Plus de 22 jours<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step11" v-show="step === 11">
      <label>9. Durant les quatre dernières semaines, pendant combien de jours votre problème de dos vous a-t-il empêché de travailler (école, travail, activités domestiques) ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.quatreSemaineEmpTrav='Aucun'" :class="borderValue(this.formContainer.quatreSemaineEmpTrav==='Aucun')">Aucun<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.quatreSemaineEmpTrav='Entre 1 et 7 jours'" :class="borderValue(this.formContainer.quatreSemaineEmpTrav==='Entre 1 et 7 jours')">Entre 1 et 7 jours<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.quatreSemaineEmpTrav='Entre 8 et 14 jours'" :class="borderValue(this.formContainer.quatreSemaineEmpTrav==='Entre 8 et 14 jours')">Entre 8 et 14 jours<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.quatreSemaineEmpTrav='Entre 15 et 21 jours'" :class="borderValue(this.formContainer.quatreSemaineEmpTrav==='Entre 15 et 21 jours')">Entre 15 et 21 jours<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.quatreSemaineEmpTrav='Plus de 22 jours'" :class="borderValue(this.formContainer.quatreSemaineEmpTrav==='Plus de 22 jours')">Plus de 22 jours<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step stepfinal" v-show="step === 12">
      <div class="final-img-wrapper">
        <h2>Merci !</h2>
      </div>
    </div>

    <div class="error" v-show="erreur !== ''">
      {{ erreur }}
    </div>
    <div class="success" v-show="success !== ''">
      {{ success }}
    </div>    
    
    <div class="bottom-actions text-center">
      <button class="btn-emot fullwidth" @click="nextStep">{{ labelAction }}</button>
    </div>
  </div>
  
</template>

<script>
  // https://github.com/vueform/slider#using-with-vue-3
  import Slider from '@vueform/slider'
  // import authentication from "../../api/authentication";
  import backend from "../../api/backend";
  
  export default {
    name: 'FormComi',
    components: {
      Slider,
    },
    data() {
      return {
        step: 1,
        erreur: '',
        success: '',
        isLoading: false,
        topBarClasses : 'topBar profileBar',
        titleProgressbar: 'COMI',
        styleProgressBar: "width: 10%;",
        parentClass: 'form-initial background-blue',
        labelAction: "continuer",
        tooltipPosition: 'bottom',
        formContainer: {
          genePlue:'',
          douleurDos: -1,
          douleurFessesJambes: -1,
          geneHabitudes: '',
          resteVieSymptomes: '',
          semaineEcouleQualiteVie: '',
          semaineEcouleDeprime: '',
          semaineEcouleAnxieux:'',
          quatreSemaineDimAct: '',
          quatreSemaineEmpTrav: '',
          
        }
      }
    },
    methods: {
      backAction() {  
        if (this.step > 1) {
          this.step--;
        } else {
          this.$router.push('/journal');
        }
      },
      formatToolTip(a) {
        console.log('formatToolTip');
        console.log(a);
        if (a === -1) {
          return "Placer le curseur"
        }
        return Math.round(a);
      },
      nextStep(event) {
        if (event) {
          event.preventDefault()
        }
        if (!hasError && this.step === 12) {
          this.$router.push({ path: '/planning'});
        }
        
        this.erreur = '';
        let hasError = false;
        // validation 
        if (this.step === 2 && this.formContainer.genePlue === '' 
            || this.step === 5 && this.formContainer.geneHabitudes === ''
            || this.step === 6 && this.formContainer.resteVieSymptomes === ''
            || this.step === 7 && this.formContainer.semaineEcouleQualiteVie === ''
            || this.step === 8 && this.formContainer.semaineEcouleDeprime === ''
            || this.step === 9 && this.formContainer.semaineEcouleAnxieux === ''
            || this.step === 10 && this.formContainer.quatreSemaineDimAct === ''
            || this.step === 11 && this.formContainer.quatreSemaineEmpTrav === ''
        ) {
            hasError = true;
            this.erreur = "Merci de choisir une option."
        } 
        if ((this.step === 3 && this.formContainer.douleurDos === -1)
          || (this.step === 4 && this.formContainer.douleurFessesJambes === -1)) {
          hasError = true;
          this.erreur = "Merci de sélectionner une valeur entree 0 et 10."
        }
        if (!hasError && this.step === 11) {
          // loader.show
          this.isLoading = true;
          backend.submitFormComi(this.formContainer).then(response => {
            this.isLoading = false;
              if (response.status === 200) {
                if (response.data.result === "ok") {
                  this.success = "Formulaire Comi enregistré avec succès.";
                  this.step++;
                  this.labelAction = "Journal";
                  setTimeout( () => { this.success = ''; this.$router.push({ path: '/formulaire-imc'});}, 1000);
                } else {
                  this.erreur = "Erreur lors de la soumission du formulaire.";
                  if (response.data.errors.length > 0) {
                    this.erreur = this.erreur + response.data.errors.toString();
                  }
                  console.log(response.data.errors);
                }
              } else {
                this.erreur = "Erreur serveur lors de la soumission du formulaire.";
              }
            }
          );
        }
        if (!hasError && this.step !== 11) {
          this.step++; 
        }
      },
      computeProgress() {
        let computedPourc = (this.step / 11) * 100;
        this.styleProgressBar = "width: "+computedPourc+"%;";
      },
      borderValue(isActive) {
        if (isActive) {
          return "selectButton selectedButton";
        } 
        return "selectButton";
      },
    },
    
    watch: {
      step() {
        // Background 
        if (this.step >= 1 && this.step < 12) {
          this.parentClass = "form-initial blue-input";
          this.topBarClasses = 'topBar profileBar';
        }
        // Button action
        if (this.step === 1) {
          this.labelAction = "Continuer";
        } else if (this.step > 1 && this.step < 11) {
          this.labelAction = "Suivant";
        } else if (this.step === 11) {
          this.labelAction = "Envoyer";
        }
        this.computeProgress();
      }
    },
    async mounted() {
      this.computeProgress();
    }
  }
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss" scoped>
  body {
    .form-initial {
      padding: 20px 26px 50px;
    }
    .error {
      margin: 0 0 20px;
      text-align: center;
      color: #E08599;
    }
    .success {
      margin: 0 0 20px;
      text-align: center;
      color: #1e7e34;
    }
    .backbutton {
      position: absolute;
      left: 0;
      top: 12px;
      border-radius: 50%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      width: 36px;
      height: 36px;
      color: #898A8D;
    }
    .topBar {
      padding-left: 56px;
      position: relative;
      h4 {
        background: #0472A8;
        color: #FFF;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.5px;
        display: inline-block;
        padding: 2px 10px;
      }
      .barTotal {
        position: relative;
        display: block;
        height: 6px;
        width: 100%;
        background: #F9F9F9;
        border-radius: 7px;
        top: 28px;
        .barProgress {
          background: #0472A8;
          height: 6px;
          display: block;
          position: absolute;
          left:0;
          top:0;
          border-radius: 7px;
        }
      }
      &.profileBar {
        h4 {
          background: #0472A8; 
        }
        .barTotal .barProgress {
          background: #0472A8;
        }
      }
    }
    .step {
      padding: 75px 0 30px;
      min-height: 400px;
      h3 {
        font-family: 'Lato', sans-serif;
      }
      &.step1 {
        margin-bottom: 50px;
        padding: 75px 0 30px;
      }
      button.selectButton {
        position: relative;
        padding: 16px 40px 16px 40px;
        svg {
          display: none;
          position: absolute;
          right: 25px;
          font-size: 23px;
          top: 30%;
        }
        
      }
      &.stepfinal {
        h2 {
          color: #FFF;
          font-family: 'Lato', sans-serif;
          text-align: center;
          font-size: 36px;
          margin: 50px auto 70px;
        }
        p {
          font-family: 'Inter', sans-serif;
          font-size: 18px;
          text-align: center;
          margin: 50px auto 20px;
          span {
            display: inline-block;
            font-size: 14px;
            margin: 20px auto;
          }
        }
      }
    }
    .blue-input button.selectedButton {
      border-color: #0472A8;
      svg {
        display: inline-block;
        color: #0472A8;
      }
    }
    
    .blue-input {
      input, textarea {
        border-bottom: 1px solid #0472A8;
      }
    }
    input, textarea {
      text-align: center;
      font-size: 24px;
    }
    select {
      max-width: 100%;
      width: 100%;
      padding: 16px 8px;
      font-size: 20px;
    }
    .select-wrapper {
      text-align: center;
      select {
        text-align: center;  
      }
    }
    label {
      margin-bottom: 30px;
      font-size: 26px;
      font-weight: 500;
      line-height: 1.4em;
      span {
        font-size: 16px;
        display: block;
        margin-top: -10px;
      }
    }
    .col-3, .col-6 {
      padding: 0 4px !important;
    }
    .backgroundwhite {
      background: #FFFFFF;
      border-radius: 24px;
      margin: 20px auto;
      width: 270px;
      position: relative;
      overflow: visible;
      height: 230px;
      img {
        position: absolute;
        width: 330px;
        left: 50%;
        margin-left: -165px;
        top: 0;
      }
    }
    .step18 .backgroundwhite {
      width: 317px;
      height: 300px;
      text-align: center;
      img {
        position: static;
        width: auto;
        margin-left: 0;
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    .slider-wrapper {
      margin-bottom: 60px;
    }
    .slider-connect {
      background: #0472A8;
    }
    .slider-tooltip {
      background: #0472A8;
      border: 1px solid #0472A8;
    }
    .slider-handle {
      background: #0472A8;
      border: 3px solid #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.47);
    }
    .dos-wrapper {
      padding: 0 0 25px 70px;
      span {
        margin-left: 20px;
        color: #0472A8;
        font-size: 14px;
      }
    }
  }
  
</style>