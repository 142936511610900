<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div v-show="currentScreen === 'actPhysique'" class="actPhysique-wrapper">
    <div class="actPhysique-header">
      <button class="backbutton" @click="backAction"><font-awesome-icon icon="chevron-left" /></button>
      <h1>Activités physiques</h1>
      <input type="text" v-model="filterVal" placeholder="Rechercher une activité physique" class="rechercheActPhysique">
      <h3><a href="#" @click.prevent.stop="this.currentScreen = 'favoris'"><img src="/img/coeur.png" alt="Favoris">Voir mes favoris</a></h3>
    </div>
    

    <div class="padding-wrapper">
      <div class="activites-physique">
        <div class="activites" v-show="filterActType(activiteType)" v-for="activiteType in actTypes" :key="activiteType">
          <div class="row" >
            <div class="col-5 act-nom" >
              <p>{{ activiteType.nom }}</p>
            </div>
            <div class="col-3 act-met" >{{ activiteType.met }}<span>MET</span></div>
            <div class="col-2 favori"><a href="#" @click.prevent.stop="makeFavoris(activiteType)"><img :src="getImgFavori(activiteType)" alt="Favoris"></a></div>
            <div class="col-2 select"><a href="#" @click.prevent.stop="selectActType(activiteType)" :class="getClassSelectedActType(activiteType)" ><font-awesome-icon icon="plus" /></a></div>
          </div>
        </div>
      </div>

      <div class="padding-footer">
        <p :class="resultClass" v-show="resultTxt !== ''">{{ resultTxt }}</p>
        <button class="btn-emot retourJournal" @click.prevent.stop="this.$router.push('/saisie');" ><span><font-awesome-icon icon="chevron-left" /></span>Enregistrer</button>
        <button class="btn-emot btn-emot-red creerActPhysique" @click.prevent.stop="this.currentScreen = 'creerActPhysique'" >Créer une activité physique<span><font-awesome-icon icon="chevron-right" /></span></button>
      </div>
    </div>
  </div>
  
  <div v-show="currentScreen === 'favoris'" class="favoris-wrapper">
    <div class="favoris-header">
      <button class="backbutton" @click="this.currentScreen='actPhysique'"><font-awesome-icon icon="chevron-left" /></button>
      <h1><img src="/img/coeur_big.png" alt="Favoris">Favoris</h1>
    </div>
    <div class="padding-wrapper">
      <div class="activites-physique">
        <div class="activites" v-show="isFavoris(activiteType)" v-for="activiteType in actTypes" :key="activiteType">
          <div class="row" >
            <div class="col-5 act-nom" >
              <p>{{ activiteType.nom }}</p>
            </div>
            <div class="col-3 act-met" >{{ activiteType.met }}<span>MET</span></div>
            <div class="col-2 deleteFavori"><a href="#" @click.prevent.stop="makeFavoris(activiteType)"><img src="/img/trash.png" alt="Favoris"></a></div>
            <div class="col-2 select"><a href="#" @click.prevent.stop="selectActType(activiteType)" :class="getClassSelectedActType(activiteType)" ><font-awesome-icon icon="plus" /></a></div>
          </div>
        </div>
      </div>
    </div>
    <div class="padding-footer">
      <p :class="resultClass" v-show="resultTxt !== ''">{{ resultTxt }}</p>
      <button class="btn-emot retourJournal" @click.prevent.stop="this.$router.push('/saisie');" ><span><font-awesome-icon icon="chevron-left" /></span>Enregistrer</button>
      <button class="btn-emot btn-emot-red creerActPhysique" @click.prevent.stop="this.currentScreen = 'creerActPhysique'" >Ajouter une activité physique<span><font-awesome-icon icon="chevron-right" /></span></button>
    </div>
  </div>

  <div v-show="currentScreen === 'creerActPhysique'" class="creerActPhysique-wrapper">
    <div class="creerActPhysique-header">
      <button class="backbutton" @click="this.currentScreen='actPhysique'"><font-awesome-icon icon="chevron-left" /></button>
      <h1>Créer une activité physique</h1>
    </div>
    <div class="padding-wrapper">
      <div class="activites-physique">
        <div class="activites">
          <div class="row" >
            <div class="col-7 act-nom" >
              <p><input type="text" placeholder="Titre de l'activité physique " v-model="newActivitePhysique" maxlength="100"></p>
            </div>
            <div class="col-3 act-met" >?<span>MET</span></div>
            <div class="col-2 favori"><a href="#" @click.prevent.stop="newIsFavori=!newIsFavori"><img :src="getImgFavoriNew()" alt="Favoris"></a></div>
          </div>
        </div>
      </div>
      <p class="legende">*Votre EMOTICoach complètera l’équivalent MET</p>
      <div class="padding-footer">
        <p :class="resultClass" v-show="resultTxt !== ''">{{ resultTxt }}</p>
        <button class="btn-emot fullwidth saveActPhysique" @click.prevent.stop="saveActPhysique" >Enregistrer</button>
      </div>      
    </div>
  </div>
</template>

<script>

  import backend from "../api/backend";
  // import router from "../router";
  
  export default {
    name: 'ActivitePhysique',
    components: {
    },
    data() {
      return {
        isLoading: true,
        currentScreen: 'actPhysique',
        newActivitePhysique: '',
        newMet: '0',
        newIsFavori: false,
        resultClass: '',
        resultTxt: '',
        filterVal : '',
        actTypes: [],
        selectedActType : [],
        favorisActType: [],
      }
    },
    methods: {
      backAction(){
        this.$router.push('/saisie');
      },
      filterActType(actType) {
        if (this.filterVal !== '' && this.filterVal !== null && this.filterVal.length > 0) {
          if (actType.nom.toLowerCase().includes(this.filterVal.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
      getImgFavoriNew() {
        if (this.newIsFavori) {
          return "/img/coeur-actif.png";
        } else {
          return "/img/coeur.png";
        }
      },
      getImgFavori(actType) {
        if (this.favorisActType.indexOf(actType.id) === -1) {
          return "/img/coeur.png";
        } else {
          return "/img/coeur-actif.png";
        }
      },
      isFavoris(actType) {
        return this.favorisActType.indexOf(actType.id) !== -1;
      },
      async makeFavoris(actType) {
        console.log(actType);
        if (this.favorisActType.indexOf(actType.id) === -1) {
          this.favorisActType.push(actType.id);
        } else {
          this.favorisActType.splice(this.favorisActType.indexOf(actType.id), 1);
        }
        await backend.submitFavoris(this.favorisActType);
        localStorage.favorisActType = JSON.stringify(this.favorisActType);
      },
      getClassSelectedActType(actType) {
        if (this.selectedActType.indexOf(actType.id) === -1) {
          return "";
        } else {
          return " selected ";
        }
      },
      createActiviteType() {
        this.$router.push('/creer-activite-physique');
      },
      selectActType(actType) {
        if (this.selectedActType.indexOf(actType.id) === -1) {
          this.selectedActType.push(actType.id); 
        } else {
          this.selectedActType.splice(this.selectedActType.indexOf(actType.id), 1);
        }
        localStorage.setItem('selectedActType-' + localStorage.currentSaisie, JSON.stringify(this.selectedActType));
      },
      async saveActPhysique() {
        if (this.newActivitePhysique === '') {
          this.resultTxt = "Merci de renseigner le titre.";
          this.resultClass = "resultTxt error";
        } else {
          this.isLoading = true;
          const submitActPhysiqueResult = await backend.submitActPhysique(this.newActivitePhysique, this.newMet, this.newIsFavori);
          console.log('submitSaisieResult : ' + submitActPhysiqueResult);
          if (submitActPhysiqueResult && submitActPhysiqueResult.result === 'ok') {
            this.newMet = '';
            this.newActivitePhysique = '';
            this.newIsFavori = false;
            this.resultTxt = "Activité physique enregistrée avec succès !";
            this.resultClass = "resultTxt success";
            
            // Add id to selection
            this.selectedActType.push(submitActPhysiqueResult.id );
            localStorage.setItem('selectedActType-' + localStorage.currentSaisie, JSON.stringify(this.selectedActType));
            
            const actTypesFromApi = await backend.getActTypesValues();
            if (actTypesFromApi !== null) {
              this.actTypes = actTypesFromApi;
              localStorage.actTypes = JSON.stringify(actTypesFromApi);
              this.isLoading = false;
            } else {
              this.isLoading = false;
            }
            const favorisActTypeFromApi = await backend.getFavorisActTypesValues();
            if (favorisActTypeFromApi !== null) {
              this.favorisActType = favorisActTypeFromApi;
              localStorage.favorisActType = JSON.stringify(favorisActTypeFromApi);
            }
            setTimeout(() => {this.resultTxt = '';this.currentScreen = 'actPhysique'}, 1000);
          } else {
            if (submitActPhysiqueResult.errors) {
              var thisContext = this;
              submitActPhysiqueResult.errors.forEach(function (err) {
                thisContext.resultTxt = thisContext.resultTxt + err;
              });
            } else {
              this.resultTxt = "Erreur lors de la création de l'activité physique";
            }
            this.resultClass = "resultTxt error";
            this.isLoading = false;
            setTimeout(() => this.resultTxt = '', 3000);
          }
        }
      }
    },
    async mounted() {
      // Get acttype from server
      const actTypesFromApi = await backend.getActTypesValues();
      if (actTypesFromApi !== null) {
        this.actTypes = actTypesFromApi;
        localStorage.actTypes = JSON.stringify(actTypesFromApi);
        this.isLoading = false;
      }
      if (localStorage.favorisActType) {
        this.favorisActType = JSON.parse(localStorage.favorisActType);
      } else {
        // Get acttype from server
        const favorisActTypeFromApi = await backend.getFavorisActTypesValues();
        if (favorisActTypeFromApi !== null) {
          this.favorisActType = favorisActTypeFromApi;
          localStorage.favorisActType = JSON.stringify(favorisActTypeFromApi);
        }
      }
      var currentSelection = localStorage.getItem('selectedActType-' + localStorage.currentSaisie);
      if (currentSelection) {
        this.selectedActType = JSON.parse(currentSelection);
      } else {
        this.selectedActType = [];
      }
    }
  }
</script>

<style lang="scss" scoped>
  body {
    background: #E5E5E5;
    .top-template {
      position: absolute;
      top:0;
      left:0;
      right: 0;
      z-index: 99;
      background: #E5E5E5;
      bottom:0;
    }
    .actPhysique-header,
    .favoris-header,
    .creerActPhysique-header {
      background: url('../../public/img/bg.png') no-repeat center center;
      background-size: 100% auto;
      color: #EDF9FF;
      padding: 40px 0 20px 0;
      .backbutton {
        position: absolute;
        left: 15px;
        top: 15px;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        font-size: 13px;
        color: #0472A8;
        background: #FFF;
        padding-top: 2px;
        border: 1px solid #0472A8; 
      }
      h1 {
        margin: 0 auto 30px !important;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #EDF9FF;
        img {
          margin-right: 10px;
        }
      }
      input[type=text] {
        border-radius: 37px;
        width: 80%;
        padding: 10px 20px;
        display: block;
        margin: 0 auto;
        font-size: 13px;
      }
      h3 {
        text-align: center;
        margin: 20px auto 0;
        a {
          color:#FFF;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          img {
            margin-right: 5px;
          }
        }
      }
    }
    .favoris-header {
      padding-top: 90px;
    }
    .creerActPhysique-header {
      padding: 90px 0;
    }
    .creerActPhysique-wrapper {
      ::placeholder {
        color: #7C7A7A !important;
        font-family: "Inter", sans-serif;
        opacity: 1;
      }
      input {
        border-bottom: 0;
      }
      .act-met {
        padding-top: 10px;
        color: #7C7A7A !important;
        ::placeholder {
          color: #0472A8 !important;
        }        
        input {
          width: 40%;
          color: #0472A8;
        }
      }
      .favori {
        padding-top: 6px;
      }
      .legende {
        color : #5F5F5F;
        font-weight: 300;
        font-size: 13px;
        text-align: center;
        background: #E5E5E5;
        margin: 0;
        padding-top: 15px;
      }
      .padding-footer {
        padding: 100px 15px 30px;
      }
    }
    .padding-wrapper {
      background: transparent;
      position: relative;
      z-index: 999;
      width: 100%;
      padding: 20px 0 0;
      .activites-physique {
        padding-bottom: 20px;
        background: #FFF;
        .activites {
          padding: 15px 21px 0px;
          border-bottom: 1px solid #F5F5F5; 
          .act-nom {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
          }
          .act-met {
            color: #0472A8;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            span {
              font-size: 12px;
              margin-left: 3px;
              font-weight: normal;
              color: #565555;
            }
          }   
          .favori {
            text-align: center;
          }
          .select {
            a {
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
              display: block;
              width: 29px;
              height: 29px;
              border-radius: 50%;
              margin: 0 auto;
              color: #898A8D;
              text-align: center;
              padding-top: 3px;
              position: relative;
              top: -3px;
              &.selected {
                background: #0A89C7;
                color: #FFF;
              }
            }
          }
        }
      }
    }
      
    .padding-footer {
      background: #E5E5E5;
      padding: 30px 0;
    }
    button.btn-emot-red {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 22px;
     margin: 0 auto;
     display: block;
     position: relative;
     width: 80%;
     span {
       margin-left: 10px;
       display: inline-block;
       font-size: 13px;
       text-align: center;
       padding-top: 1px;
     }
    }
    button.btn-emot.retourJournal {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin: 0 auto 15px;
      display: block;
      position: relative;
      width: 80%;
      span {
        margin-right: 10px;
        display: inline-block;
        font-size: 13px;
        text-align: center;
        padding-top: 1px;
      }
    }
  
    p.resultTxt {
      margin: 30px 0 20px;
      text-align: center;
    }
  }

</style>