import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Introduction from '../views/introduction/Introduction.vue'
import Connexion from '../views/Connexion.vue'
import MdpOublie from '../views/MdpOublie.vue'
import ReinitialisationMdp from '../views/ReinitialisationMdp.vue'
import Activation from '../views/Activation.vue'
import FormInitial from '../views/forms/FormInit.vue'
import FormComi from '../views/forms/FormComi.vue'
import FormHad from '../views/forms/FormHad.vue'
import FormIMC from '../views/forms/FormMic'
import Journal from '../views/Journal.vue'
import Progression from '../views/Progression.vue'
import Planning from '../views/Planning'
import Profil from '../views/Profil'
import Accueil from '../views/Accueil.vue'
import Saisie from '../views/Saisie.vue'
import ActivitePhysique from '../views/ActivitePhysique.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: Introduction,
    // children: [
    //   {
    //     // UserProfile will be rendered inside User's <router-view>
    //     // when /user/:id/profile is matched
    //     path: 'profile',
    //     component: UserProfile,
    //   },
    //   {
    //     // UserPosts will be rendered inside User's <router-view>
    //     // when /user/:id/posts is matched
    //     path: 'posts',
    //     component: UserPosts,
    //   }]
  },
  {
    path: '/connexion',
    name: 'Connexion',
    component: Connexion
  }, 
  { path: '/mot-de-passe-oublie', name: 'MdpOublie', component: MdpOublie },
  { path: '/reinitialisation/:verifKey', name: 'ReinitialisationMdp', component: ReinitialisationMdp },
  { path: '/activation/:enableKey', name: 'Activation', component: Activation },
  {
    path: '/formulaire-debut',
    name: 'FormInitial',
    component: FormInitial
  },
  {
    path: '/formulaire-comi',
    name: 'FormComi',
    component: FormComi
  },  
  {
    path: '/formulaire-had',
    name: 'FormHad',
    component: FormHad
  },  
  {
    path: '/formulaire-imc',
    name: 'FormImc',
    component: FormIMC
  },
  {
    path: '/journal',
    name: 'Journal',
    component: Journal
  },
  {
    path: '/progression',
    name: 'Progression',
    component: Progression
  },
  {
    path: '/planning',
    name: 'Planning',
    component: Planning
  },
  {
    path: '/profil',
    name: 'Profil',
    component: Profil
  },
  {
    path: '/saisie',
    name: 'Saisie',
    component: Saisie
  },    
  {
    path: '/activite-physique',
    name: 'ActivitePhysique',
    component: ActivitePhysique
  },
  {
    path: '/accueil',
    name: 'Accueil',
    component: Accueil
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    // window.scrollTo(0, 0);
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })    
  },
})

// router.beforeEach((to, from, next) => {
//   console.log(to + from + next);
//   window.scrollTo(0, 0)
//   // ...
// })

export default router
