<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div class="planning-wrapper">
  <div class="top-template journal">
    <div class="journal-header">
      <button class="backbutton" @click="backAction"><font-awesome-icon icon="chevron-left" /></button>
      <h1>Mon planning</h1>
    </div>
  </div>

  <div class="padding-wrapper">
    <div class="saisie-item saisie-activite" v-for="(activite, index) in activites" :key="activite">
      <select class="daySelect" v-model="activite.jourSemaine">
        <option v-for="jour in joursSemaine" :key="jour" :value="jour">{{ jour }}</option>
      </select>
      <a href="#" class="deleteAct" @click.prevent.stop="deleteAct(index)"><font-awesome-icon icon="xmark" /></a>
      <div class="row">
        <div class="col-5">
          <Select2 v-model="activite.type" :options="actTypes" :settings="{ minimumResultsForSearch: -1, placeholder: 'Activité',escapeMarkup: function (m) {return m;}, templateSelection: formatResultAct, templateResult: formatOptionAct, dropdownAutoWidth: true }" @change="updateMet($event, index)" @select="updateMet($event, index)" />
        </div>
        <div class="col-2 act-met">{{ activite.met}}<span>MET</span></div>
        <div class="col-5 act-duree">
          <vue-timepicker @input="timePickerHandler($event, index)" format="HHHmm" placeholder=" : " :close-on-complete="true" :minute-interval="5" v-model="activite.duree"></vue-timepicker>
        </div>
      </div>
      <div class="error" v-show="activite.erreur !== ''">{{ activite.erreur }}</div>
    </div>
    <button class="btn-emot btn-emot-red" @click="addActivity()"><span><font-awesome-icon icon="plus" /></span> Ajouter un objectif EMOTIV</button>
    <button class="btn-emot enregistrer" :disabled='isButtDisabled' @click="sendPlanning()">Enregistrer mon planning</button>
    <div class="success">{{ successMsg }}</div>
  </div>
  </div>
  <emotiv-footer activeItem="planning"></emotiv-footer>
</template>

<script>
  
  import emotivFooter from "../components/EmotivFooter";
  // https://github.com/phoenixwong/vue2-timepicker
  import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue'
  import backend from "../api/backend";
  import Select2 from 'vue3-select2-component';
  
  export default {
    name: 'Planning',
    components: {
      emotivFooter,
      VueTimepicker,
      Select2
    },
    data() {
      return {
        isLoading: true,
        successMsg : '',
        isButtDisabled: false,
        joursSemaine: ['Jour de la semaine', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
        activites : [
          {
            jourSemaine: 'Jour de la semaine',
            type: '-1',
            met: 0,
            duree: {
              HH: '',
              mm: '',
            },
            erreur: ''
          }
        ],
        actTypes: [
          {
            id: -1,
            nom: 'Activité physique' 
          }
        ]
      }
    },
    methods: {
      async backAction() {
        if (this.sendPlanning()) {
          this.successMsg = 'Planning enregistré';
          this.$router.push({ path: '/journal'});
        }
      },
      timePickerHandler(eventData, index) {
        this.activites[index].duree = eventData; 
        console.log(eventData);
      },
      updateMet(evt, index) {
        console.log(evt);
        console.log(index);
        this.activites[index].met = evt.met
      },
      formatResultAct(act) {
        if (act.id === -1) {
          return '';
        }
        var out = '';
        if (act.picto !== null && act.picto !== '') {
          out = '<img src="'+process.env.VUE_APP_URL_API+'/uploads/picto/' + act.picto + '">';
        }
        out = out + act.nom;
        return out;
      },
      formatOptionAct(act) {
        var out = '';
        if (act.picto !== null && act.picto !== '') {
          out = '<img src="'+process.env.VUE_APP_URL_API+'/uploads/picto/' + act.picto + '">';
        }
        out = out + act.nom;
        return out;
      },
      deleteAct(index) {
        this.activites.splice(index, 1);
      },
      getActiviteTitle(actIndex) {
        let returnedValue = '';
        let context = this;
        this.actTypes.forEach(function (value) {
          if(value.id === context.activites[actIndex].type) {
            returnedValue = value.nom; 
          }
        });
        return returnedValue;
      },
      addActivity() {
        this.activites.push(
          {
            jourSemaine: 'Jour de la semaine',
            type: '-1',
            met: '',
            duree: {
              HH: '00',
              mm: '00',
            },
            erreur: ''
          }
        );
      },
      async sendPlanning() {
        this.isButtDisabled = true;
        // first validate entries
        let hasNoError = true;
        let activities = this.activites; 
        this.activites.forEach(function (value, index) {
          activities[index].erreur = '';
          console.log('index: ' + index + ', value: '+ value.jourSemaine);
          let error = '';
          if (value.jourSemaine === 'Jour de la semaine' || value.jourSemaine === '') {
            error = error + 'Veuillez choisir un jour de la semaine.';
          }
          if (value.type === 'Activité physique' || value.type === '' || value.type === '-1') {
            error = error + ' Veuillez choisir une activité.';
          }
          if (value.duree.HH === '' || value.duree.HH === 'HH'
          || value.duree.mm === '' || value.duree.mm === 'mm') {
            error = error + ' Veuillez choisir une durée.';
          }
          if (error !== '') {
            activities[index].erreur = error;
            hasNoError = false;
          }
        });
        
        if (hasNoError) {
          const resPost = await backend.submitPlanning(this.activites);
          if (resPost !== null) {
            this.isButtDisabled = false;
            console.log('resPost ' + resPost);
            if(resPost === 'ok') {
              this.successMsg = 'Planning enregistré';
              setTimeout( () => this.$router.push('/journal'), 1000);
              return true;
            } else {
              return false;
            }
          }
        } else {
          this.isButtDisabled = false;
        }
        return hasNoError;
      }
    },

    async mounted() {
      // TODO show loader
      const actTypesFromApi = await backend.getActTypesValues();
      if (actTypesFromApi !== null) {
        this.actTypes = actTypesFromApi;
      }
      const plannings = await backend.getPlannings();
      if (plannings !== null) {
        this.activites = plannings;
      }
      if (plannings !== null && actTypesFromApi !== null) {
        this.isLoading = false;
      }
      // TODO stop loader
    }    
  }
</script>


<style lang="scss" scoped>
  body {
    .planning-wrapper {
      background: #E5E5E5;
      min-height: 560px;
      footer {
      }
    }
    .top-template {
      position: absolute;
      top:0;
      left:0;
      right: 0;
      z-index: 99;
    }
    .journal-header {
      background: url('../../public/img/bg.png') no-repeat center top;
      background-size: 100% auto;
      position: absolute;
      top:0;
      left:0;
      right: 0;
      height: 327px;
      color: #EDF9FF;
      .backbutton {
        position: absolute;
        left: 15px;
        top: 15px;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        font-size: 13px;
        color: #0472A8;
        background: #FFF;
        padding-top: 2px;
        border: 1px solid #0472A8;
      }
      h1 {
        margin: 40px auto 30px !important;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #EDF9FF;
      }
    }
    .padding-wrapper {
      padding: 0 0 50px;
      background: transparent;
      position: relative;
      z-index: 999;
      width: calc(100% - 42px);
      margin: 210px auto 0;
      border-radius: 8px;
      .success {
        text-align: center;
        font-size: 14px;
      }
      button {
        width: 80%;
        max-width: 300px;
        margin: 40px auto 20px;
        display: block;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        &.enregistrer {
          margin: 20px auto 0;  
        }
      }
    }
    .saisie-item {
      background: #FFFFFF;
      box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      margin: 10px 0;
      padding: 55px 15px 15px 15px;
      /*height: 126px;*/
      position: relative;
      .error {
        font-size: 13px;
        line-height: 1.3em;
        margin-top: 5px;
      }
      a.deleteAct {
        position: absolute;
        right: 10px;
        top: 10px;
        border: 1px solid #E7E7E7;
        width: 28px;
        height: 28px;
        display: block;
        font-size: 13px;
        text-align: center;
        border-radius: 50%;
        padding-top: 3px;
      }
      select.daySelect {
        position: absolute;
        top: 8px;
        left: 15px;
        border: 1px solid  #B4C572;
        border-radius: 8px;
        color: #565555;
        font-size: 12px;
        padding: 7px 7px;
        option {
          color: #565555;
          font-size: 12px;
        }
      }
      select.typeSelect {
        background: #FFF;
        border: 0.4px solid #D0D0D0;
        border-radius: 8px;
        color: #565555;
        padding: 13px;
        max-width: 100%;
      }
      .act-met {
        color: #0472A8;
        font-weight: 600;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
        padding-top: 5px;
        span {
          font-size: 12px;
          margin-left: 3px;
          font-weight: normal;
          color: #565555;
        }
      }
      .act-duree {
        position: relative;
        span.unit {
          position: absolute;
          right: 20px;
          top: 14px;
          font-size: 12px;
          margin-left: 3px;
          font-weight: normal;
          color: #565555;
        }
      }
    }

    p.resultTxt {
      margin: 30px 0 0;
    }
    footer {
      padding-top: 10px;
    }
    
  }

</style>