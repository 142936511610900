<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div :class="parentClass">
    <div :class="topBarClasses" v-show="step !== 23">
      <button class="backbutton" @click="backAction"><font-awesome-icon icon="chevron-left" /></button>
      <span class="barTotal"><span class="barProgress" :style="styleProgressBar"></span></span>
    </div>
    
    <div class="step step1" v-show="step === 1">
      <h3 class="text-center">Formulaire HAD</h3>
      <p class="color_color_grey_light2 text-center">
        <br>Ne vous attardez pas sur la réponse à faire : votre réaction immédiate à chaque question fournira probablement une meilleure indication de ce que vous éprouvez qu'une réponse longuement méditée.
      </p>
    </div>

    <div class="step step2" v-show="step === 2">
      <label>1. Je me sens tendu(e) ou énervé(e) :</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.tenduEnerve='La plupart du temps'" :class="borderValue(this.formContainer.tenduEnerve==='La plupart du temps')">La plupart du temps<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.tenduEnerve='Souvent'" :class="borderValue(this.formContainer.tenduEnerve==='Souvent')">Souvent<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.tenduEnerve='De temps en temps'" :class="borderValue(this.formContainer.tenduEnerve==='De temps en temps')">De temps en temps<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.tenduEnerve='Jamais'" :class="borderValue(this.formContainer.tenduEnerve==='Jamais')">Jamais<font-awesome-icon icon="check" /></button>
      </div>
    </div>    
    <div class="step step3" v-show="step === 3">
      <label>2. Je prends plaisir aux même choses qu'autrefois :</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.plaisirAutrefois='Oui, tout autant qu\'avant'" :class="borderValue(this.formContainer.plaisirAutrefois==='Oui, tout autant qu\'avant')">Oui, tout autant qu'avant<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.plaisirAutrefois='Pas autant'" :class="borderValue(this.formContainer.plaisirAutrefois==='Pas autant')">Pas autant<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.plaisirAutrefois='Un peu seulement'" :class="borderValue(this.formContainer.plaisirAutrefois==='Un peu seulement')">Un peu seulement<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.plaisirAutrefois='Presque plus'" :class="borderValue(this.formContainer.plaisirAutrefois==='Presque plus')">Presque plus<font-awesome-icon icon="check" /></button>
      </div>
    </div>
    
    <div class="step step4" v-show="step === 4">
      <label>3. J'ai une sensation de peur comme si quelque chose d'horrible allait m'arriver :</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.sensationPeur='Oui, très nettement'" :class="borderValue(this.formContainer.sensationPeur==='Oui, très nettement')">Oui, très nettement<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.sensationPeur='Oui, mais ce n\'est pas trop grave'" :class="borderValue(this.formContainer.sensationPeur==='Oui, mais ce n\'est pas trop grave')">Oui, mais ce n'est pas trop grave<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.sensationPeur='Un peu, mais cela ne m\'inquiète pas'" :class="borderValue(this.formContainer.sensationPeur==='Un peu, mais cela ne m\'inquiète pas')">Un peu, mais cela ne m'inquiète pas<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.sensationPeur='Pas du tout'" :class="borderValue(this.formContainer.sensationPeur==='Pas du tout')">Pas du tout<font-awesome-icon icon="check" /></button>
      </div>
    </div>
    

    <div class="step step5" v-show="step === 5">
      <label>4. Je ris et vois le bon côté des choses :</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.bonCoteDesChoses='Autant que par le passé'" :class="borderValue(this.formContainer.bonCoteDesChoses==='Autant que par le passé')">Autant que par le passé<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.bonCoteDesChoses='Plus autant qu\'avant'" :class="borderValue(this.formContainer.bonCoteDesChoses==='Plus autant qu\'avant')">Plus autant qu'avant<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.bonCoteDesChoses='Vraiment moins qu\'avant'" :class="borderValue(this.formContainer.bonCoteDesChoses==='Vraiment moins qu\'avant')">Vraiment moins qu'avant<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.bonCoteDesChoses='Plus du tout'" :class="borderValue(this.formContainer.bonCoteDesChoses==='Plus du tout')">Plus du tout<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step6" v-show="step === 6">
      <label>5. Je me fais du souci</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.soucis='Très souvent'" :class="borderValue(this.formContainer.soucis==='Très souvent')">Très souvent<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.soucis='Assez souvent'" :class="borderValue(this.formContainer.soucis==='Assez souvent')">Assez souvent<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.soucis='Occasionnellement'" :class="borderValue(this.formContainer.soucis==='Occasionnellement')">Occasionnellement<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.soucis='Très occasionnellement'" :class="borderValue(this.formContainer.soucis==='Très occasionnellement')">Très occasionnellement<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step7" v-show="step === 7">
      <label>6. Je suis de bonne humeur</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.bonneHumeur='Jamais'" :class="borderValue(this.formContainer.bonneHumeur==='Jamais')">Jamais<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.bonneHumeur='Rarement'" :class="borderValue(this.formContainer.bonneHumeur==='Rarement')">Rarement<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.bonneHumeur='Assez souvent'" :class="borderValue(this.formContainer.bonneHumeur==='Assez souvent')">Assez souvent<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.bonneHumeur='La plupart du temps'" :class="borderValue(this.formContainer.bonneHumeur==='La plupart du temps')">La plupart du temps<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step8" v-show="step === 8">
      <label>7. Je peux rester tranquillement assis(e) à ne rien faire et me sentir décontracté(e)</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.tranquil='Oui, quoi qu\'il arrive'" :class="borderValue(this.formContainer.tranquil==='Oui, quoi qu\'il arrive')">Oui, quoi qu'il arrive<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.tranquil='Oui, en général'" :class="borderValue(this.formContainer.tranquil==='Oui, en général')">Oui, en général<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.tranquil='Rarement'" :class="borderValue(this.formContainer.tranquil==='Rarement')">Rarement<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.tranquil='Jamais'" :class="borderValue(this.formContainer.tranquil==='Jamais')">Jamais<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step9" v-show="step === 9">
      <label>8. J'ai l'impression de fonctionner au ralenti</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.ralenti='Presque toujours'" :class="borderValue(this.formContainer.ralenti==='Presque toujours')">Presque toujours<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.ralenti='Très souvent'" :class="borderValue(this.formContainer.ralenti==='Très souvent')">Très souvent<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.ralenti='Parfois'" :class="borderValue(this.formContainer.ralenti==='Parfois')">Parfois<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.ralenti='Jamais'" :class="borderValue(this.formContainer.ralenti==='Jamais')">Jamais<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step10" v-show="step === 10">
      <label>9. J'éprouve des sensations de peur et j'ai l'estomac noué :</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.estomacNoue='Jamais'" :class="borderValue(this.formContainer.estomacNoue==='Jamais')">Jamais<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.estomacNoue='Parfois'" :class="borderValue(this.formContainer.estomacNoue==='Parfois')">Parfois<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.estomacNoue='Assez souvent'" :class="borderValue(this.formContainer.estomacNoue==='Assez souvent')">Assez souvent<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.estomacNoue='Très souvent'" :class="borderValue(this.formContainer.estomacNoue==='Très souvent')">Très souvent<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step11" v-show="step === 11">
      <label>10. Je ne m'intéresse plus à mon apparence :</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.apparence='Plus du tout'" :class="borderValue(this.formContainer.apparence==='Plus du tout')">Plus du tout<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.apparence='Je n\'y accorde pas autant d\'attention que je ne devrais'" :class="borderValue(this.formContainer.apparence==='Je n\'y accorde pas autant d\'attention que je ne devrais')">Je n'y accorde pas autant d'attention que je ne devrais<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.apparence='Il se peut que je n\'y fasse plus autant attention'" :class="borderValue(this.formContainer.apparence==='Il se peut que je n\'y fasse plus autant attention')">Il se peut que je n'y fasse plus autant attention<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.apparence='J\'y prête autant d\'attention que par le passé'" :class="borderValue(this.formContainer.apparence==='J\'y prête autant d\'attention que par le passé')">J'y prête autant d'attention que par le passé<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step12" v-show="step === 12">
      <label>11. J'ai la bougeotte et n'arrive pas à tenir en place :</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.bougeotte='Oui, c\'est tout à fait le cas'" :class="borderValue(this.formContainer.bougeotte==='Oui, c\'est tout à fait le cas')">Oui, c'est tout à fait le cas<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.bougeotte='Un peu'" :class="borderValue(this.formContainer.bougeotte==='Un peu')">Un peu<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.bougeotte='Pas tellement'" :class="borderValue(this.formContainer.bougeotte==='Pas tellement')">Pas tellement<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.bougeotte='Pas du tout'" :class="borderValue(this.formContainer.bougeotte==='Pas du tout')">Pas du tout<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step13" v-show="step === 13">
      <label>12. Je me réjouis à l'idée de faire certaines choses :</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.rejouis='Autant qu\'avant'" :class="borderValue(this.formContainer.rejouis==='Autant qu\'avant')">Autant qu'avant<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.rejouis='Un peu moins qu\'avant'" :class="borderValue(this.formContainer.rejouis==='Un peu moins qu\'avant')">Un peu moins qu'avant<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.rejouis='Biens moins qu\'avant'" :class="borderValue(this.formContainer.rejouis==='Biens moins qu\'avant')">Biens moins qu'avant<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.rejouis='Presque jamais'" :class="borderValue(this.formContainer.rejouis==='Presque jamais')">Presque jamais<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step14" v-show="step === 14">
      <label>13. J'éprouve des sensations soudaines de panique :</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.panique='Vraiment très souvent'" :class="borderValue(this.formContainer.panique==='Vraiment très souvent')">Vraiment très souvent<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.panique='Assez souvent'" :class="borderValue(this.formContainer.panique==='Assez souvent')">Assez souvent<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.panique='Pas très souvent'" :class="borderValue(this.formContainer.panique==='Pas très souvent')">Pas très souvent<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.panique='Jamais'" :class="borderValue(this.formContainer.panique==='Jamais')">Jamais<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step15" v-show="step === 15">
      <label>14. Je peux prendre plaisir à un bon livre ou à une bonne émission de télévision :</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.livreTele='Souvent'" :class="borderValue(this.formContainer.livreTele==='Souvent')">Souvent<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.livreTele='Parfois'" :class="borderValue(this.formContainer.livreTele==='Parfois')">Parfois<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.livreTele='Rarement'" :class="borderValue(this.formContainer.livreTele==='Rarement')">Rarement<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.livreTele='Très rarement'" :class="borderValue(this.formContainer.livreTele==='Très rarement')">Très rarement<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step stepfinal" v-show="step === 16">
      <div class="final-img-wrapper">
        <h2>Merci !</h2>
      </div>
    </div>

    <div class="error" v-show="erreur !== ''">
      {{ erreur }}
    </div>
    <div class="success" v-show="success !== ''">
      {{ success }}
    </div>    
    
    <div class="bottom-actions text-center">
      <button class="btn-emot fullwidth" @click="nextStep">{{ labelAction }}</button>
    </div>
  </div>
  
</template>

<script>
  // import authentication from "../../api/authentication";
  import backend from "../../api/backend";
  
  export default {
    name: 'FormHad',
    data() {
      return {
        step: 1,
        erreur: '',
        success: '',
        isLoading: false,
        topBarClasses : 'topBar profileBar',
        titleProgressbar: 'HAD-A',
        styleProgressBar: "width: 10%;",
        parentClass: 'form-initial background-blue',
        labelAction: "continuer",
        tooltipPosition: 'bottom',
        formContainer: {
          tenduEnerve:'',
          plaisirAutrefois: '',
          sensationPeur: '',
          bonCoteDesChoses: '',
          soucis: '',
          bonneHumeur: '',
          tranquil:'',
          ralenti:'',
          estomacNoue:'',
          apparence: '',
          bougeotte: '',
          rejouis: '',
          panique: '',
          livreTele: ''
        }
      }
    },
    methods: {
      backAction() {  
        if (this.step > 1) {
          this.step--;
        } else {
          this.$router.push('/journal');
        }
      },
      nextStep(event) {
        if (event) {
          event.preventDefault()
        }

        if (!hasError && this.step === 16) {
          this.$router.push({ path: '/planning'});
        }        
        
        this.erreur = '';
        let hasError = false;
        // validation 
        if (this.step === 2 && this.formContainer.tenduEnerve === '' 
            || this.step === 3 && this.formContainer.plaisirAutrefois === ''
            || this.step === 4 && this.formContainer.sensationPeur === ''
            || this.step === 5 && this.formContainer.bonCoteDesChoses === ''
            || this.step === 6 && this.formContainer.soucis === ''
            || this.step === 7 && this.formContainer.bonneHumeur === ''
            || this.step === 8 && this.formContainer.tranquil === ''
            || this.step === 9 && this.formContainer.ralenti === ''
            || this.step === 10 && this.formContainer.sensationPeur === ''
            || this.step === 11 && this.formContainer.apparence === ''
            || this.step === 12 && this.formContainer.bougeotte === ''
            || this.step === 13 && this.formContainer.rejouis === ''
            || this.step === 14 && this.formContainer.panique === ''
            || this.step === 15 && this.formContainer.livreTele === ''
        ) {
            hasError = true;
            this.erreur = "Merci de choisir une option."
        } 
        if (!hasError && this.step === 15) {
          this.isLoading = true;
          backend.submitFormHad(this.formContainer).then(response => {
            this.isLoading = false;
            if (response.status === 200) {
              if (response.data.result === "ok") {
                this.success = "Formulaire Had enregistré avec succès.";
                this.step++;
                this.labelAction = "Journal";
                setTimeout( () => {this.success = ''; this.$router.push({ path: '/formulaire-comi'});}, 1000);
              } else {
                this.erreur = "Erreur lors de la soumission du formulaire.";
                if (response.data.errors.length > 0) {
                  this.erreur = this.erreur + response.data.errors.toString();
                }
                console.log(response.data.errors);
              }
            } else {
              this.erreur = "Erreur serveur lors de la soumission du formulaire.";
            }
            }
          );
        }
        if (!hasError && this.step !== 15) {
          this.step++; 
        }
      },
      computeProgress() {
        let computedPourc = (this.step / 16) * 100;
        this.styleProgressBar = "width: "+computedPourc+"%;";
      },
      borderValue(isActive) {
        if (isActive) {
          return "selectButton selectedButton";
        } 
        return "selectButton";
      },
    },
    
    watch: {
      step() {
        // Background 
        if (this.step >= 1 && this.step < 16) {
          this.parentClass = "form-initial blue-input";
          this.topBarClasses = 'topBar profileBar';
        }
        // Button action
        if (this.step === 1) {
          this.labelAction = "Continuer";
        } else if (this.step > 1 && this.step < 15) {
          this.labelAction = "Suivant";
        } else if (this.step === 15) {
          this.labelAction = "Envoyer";
        }
        this.computeProgress();
      }
    },
   // async created () {
   //    // Authenticate
   //    const isAuthenticate = await authentication.isAuthenticate();
   //    console.log('isAuthenticate : '+isAuthenticate);
   //  }, 
    async mounted() {
      this.computeProgress();
    }
  }
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss" scoped>
  body {
    .form-initial {
      padding: 20px 26px 50px;
    }
    .error {
      margin: 0 0 20px;
      text-align: center;
      color: #E08599;
    }
    .success {
      margin: 0 0 20px;
      text-align: center;
      color: #1e7e34;
    }
    .backbutton {
      position: absolute;
      left: 0;
      top: 12px;
      border-radius: 50%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      width: 36px;
      height: 36px;
      color: #898A8D;
    }
    .topBar {
      padding-left: 56px;
      position: relative;
      h4 {
        background: #0472A8;
        color: #FFF;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.5px;
        display: inline-block;
        padding: 2px 10px;
      }
      .barTotal {
        position: relative;
        display: block;
        height: 6px;
        width: 100%;
        background: #F9F9F9;
        border-radius: 7px;
        top: 28px;
        .barProgress {
          background: #0472A8;
          height: 6px;
          display: block;
          position: absolute;
          left:0;
          top:0;
          border-radius: 7px;
        }
      }
      &.profileBar {
        h4 {
          background: #0472A8; 
        }
        .barTotal .barProgress {
          background: #0472A8;
        }
      }
    }
    .step {
      padding: 75px 0 30px;
      min-height: 400px;
      h3 {
        font-family: 'Lato', sans-serif;
      }
      &.step1 {
        margin-bottom: 50px;
        padding: 75px 0 30px;
      }
      button.selectButton {
        position: relative;
        padding: 16px 40px 16px 40px;
        svg {
          display: none;
          position: absolute;
          right: 25px;
          font-size: 23px;
          top: 30%;
        }
        
      }
      &.stepfinal {
        h2 {
          color: #FFF;
          font-family: 'Lato', sans-serif;
          text-align: center;
          font-size: 36px;
          margin: 50px auto 70px;
        }
        p {
          font-family: 'Inter', sans-serif;
          font-size: 18px;
          text-align: center;
          margin: 50px auto 20px;
          span {
            display: inline-block;
            font-size: 14px;
            margin: 20px auto;
          }
        }
      }
    }
    .blue-input button.selectedButton {
      border-color: #0472A8;
      svg {
        display: inline-block;
        color: #0472A8;
      }
    }
    
    .blue-input {
      input, textarea {
        border-bottom: 1px solid #0472A8;
      }
    }
    input, textarea {
      text-align: center;
      font-size: 24px;
    }
    select {
      max-width: 100%;
      width: 100%;
      padding: 16px 8px;
      font-size: 20px;
    }
    .select-wrapper {
      text-align: center;
      select {
        text-align: center;  
      }
    }
    label {
      margin-bottom: 30px;
      font-size: 26px;
      font-weight: 500;
      line-height: 1.4em;
      span {
        font-size: 16px;
        display: block;
        margin-top: -10px;
      }
    }
    .col-3, .col-6 {
      padding: 0 4px !important;
    }
    .backgroundwhite {
      background: #FFFFFF;
      border-radius: 24px;
      margin: 20px auto;
      width: 270px;
      position: relative;
      overflow: visible;
      height: 230px;
      img {
        position: absolute;
        width: 330px;
        left: 50%;
        margin-left: -165px;
        top: 0;
      }
    }
    .step18 .backgroundwhite {
      width: 317px;
      height: 300px;
      text-align: center;
      img {
        position: static;
        width: auto;
        margin-left: 0;
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    .slider-wrapper {
      margin-bottom: 60px;
    }
    .slider-connect {
      background: #0472A8;
    }
    .slider-tooltip {
      background: #0472A8;
      border: 1px solid #0472A8;
    }
    .slider-handle {
      background: #0472A8;
      border: 3px solid #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.47);
    }
    .dos-wrapper {
      padding: 0 0 25px 70px;
      span {
        margin-left: 20px;
        color: #0472A8;
        font-size: 14px;
      }
    }
  }
  
</style>