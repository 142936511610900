<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div class="top-template connexion">
    <h1>{{ labelMainButton }}</h1>
      <div class="input-wrapper"><input v-model.trim="idPatient" name="idPatient" type="text" :placeholder="placeholderLogin" required /></div>
      <div class="input-wrapper" v-show="!isConnecion"><input v-model.trim="email" name="email" type="email" placeholder="Votre adresse Email*" required /></div>
      <div class="input-wrapper" v-show="!isConnecion"><input v-model.trim="email2" name="email2" type="email" placeholder="Confirmer votre adresse Email*" required /></div>
      <div class="input-wrapper">
        <input v-model="password" name="password" :type="passwordFieldType" placeholder="Mot de passe*" required />
        <button class="switchVisibility" @click="switchVisibility"><img src="/img/eye.png" alt="eye"></button>
      </div>
    <div class="input-wrapper" v-show="!isConnecion">
      <input  v-model="password2" name="password2" :type="passwordFieldType2" placeholder="Confirmez le mot de passe*" required />
      <button class="switchVisibility" @click="switchVisibility2"><img src="/img/eye.png" alt="eye"></button>
    </div>
      <p :class="resultClass" v-show="resultTxt !== '' && resultTxt !== 'null'">{{ resultTxt }}</p>
    <label class="cgu mt-3" for="cgu" v-show="!isConnecion"><input id="cgu" type="checkbox" v-model="checkedCgu" required>J’accepte les conditions de service et la politique de confidentialité</label>
      <input type="submit" @click="submitForm" class="btn-emot" :value="labelMainButton" >
      <p class="subConnexion mt-3">{{ textInscrit }}<button @click="switchConnexion">{{ labelSwitch }}</button></p>
      <p class="subConnexion mt-1">Mot de passe oublié ? <router-link class="" to="/mot-de-passe-oublie">Cliquer ici</router-link></p>
    
  </div>
</template>

<script>

  // import axios from 'axios'
  import authentication from "../api/authentication";
  import backend from "@/api/backend";
  
  export default {
    name: 'Connexion',
    data() {
      return {
        isLoading: true,
        passwordFieldType: "password",
        passwordFieldType2: "password",
        placeholderLogin:  "Email ou ID patient*",
        labelSwitch: "Inscription",
        labelMainButton: "Se connecter",
        textInscrit: "Vous n'êtes pas encore inscrit ?",
        isConnecion: true,
        idPatient: null,
        email: null,
        email2: null,
        password: null,
        password2: null,
        checkedCgu: false,
        hasError: false,
        autorisation: null,
        resultTxt: null,
        resultClass: 'error'
      }
    },
    methods: {
      switchVisibility() {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      },
      switchVisibility2() {
        this.passwordFieldType2 = this.passwordFieldType2 === "password" ? "text" : "password";
      },      
      switchConnexion() {
        this.isConnecion = !this.isConnecion;
        this.labelSwitch = this.labelSwitch === "Inscription" ? "Connexion" : "Inscription"; 
        this.labelMainButton = this.labelMainButton === "S'inscrire" ? "Se connecter" : "S'inscrire"; 
        this.placeholderLogin = this.placeholderLogin === "Votre ID patient*" ? "Email ou ID patient*" : "Votre ID patient*";
        this.textInscrit = this.textInscrit.includes('êtes pas encore inscrit') ? 'Déjà inscrit/e ?' : 'Vous n\'êtes pas encore inscrit/e ?';
        this.hasError = false;
        this.resultTxt = "";
      },
      resetFields() {
        this.email = '';
        this.email2 = '';
        this.password = '';
        this.password2 = '';
        this.idPatient = '';
      },
      submitForm() {
        // axios
        //   .post('http://emotivapp.backend:8741/api/testapi', {
        //     email: 'projetsbrif+patient1@gmail.com',
        //     password: 'dW@%4LpXscWB7*mzZKpv'
        //   }, config).then(response =>  {
        //   this.idPatient = response;
        // })

        this.hasError = false;
        this.resultTxt = "";
        if (this.isConnecion && (!this.idPatient || !this.password)) {
          this.hasError = true;
          this.resultTxt = "Un ou plusieurs champs ne sont pas renseignés, vérifiez et essayez à nouveau.";
        }
        if (!this.isConnecion && this.email !== this.email2 ) {
          this.hasError = true;
          this.resultTxt = "Les deux emails ne correspondent pas.";
        }
        if (!this.isConnecion && this.password !== this.password2 ) {
          this.hasError = true;
          this.resultTxt = "Les deux mots de passe ne correspondent pas.";
        }
        if (!this.isConnecion && (!this.idPatient || !this.email || !this.password ) ) {
          this.hasError = true;
          this.resultTxt = "Un ou plusieurs champs ne sont pas renseignés, vérifiez et essayez à nouveau.";
        }
        if (!this.isConnecion && !this.checkedCgu  ) {
          this.hasError = true;
          this.resultTxt = "Merci d'accepter les conditions générales d'utilisation.";
        }
        if (!this.hasError) {
          this.isLoading = true;
          if (this.isConnecion) {
            this.resultClass = 'error';
            authentication.login(this.idPatient, this.password).then(response => {
              // this.idPatient = response;
              if (response.status === 200 && response.data.result === 'ok') {
                localStorage.apitoken = response.data.token;
                backend.refreshConfig();
                this.$router.push('/journal');
              } else {
                this.hasError = true;
                this.resultClass = 'error';
                if (response.data.message) {
                  this.resultTxt = response.data.message;  
                } else {
                  this.resultTxt = "Email, ID Patient ou mot de passe erroné." 
                }
              }
              this.isLoading = false;
            });
          } else {
            authentication.registerUser(this.idPatient, this.email, this.password).then(response => {
              // this.idPatient = response;
              if (response.status === 200) {
                if (response.data.result === 'ok') {
                  this.resultClass = 'success';
                  this.resultTxt = "Inscription validée. Pour finaliser votre inscription, merci de cliquer sur le lien envoyé dans l'email d'activation que vous venez de recevoir.";
                  this.resetFields();
                  setTimeout( () => {this.switchConnexion(); this.resultTxt=''; }, 5000);
                } else {
                  this.hasError = true;
                  this.resultClass = 'error';
                  this.resultTxt = response.data.error;
                }
              } else {
                this.hasError = true;
                this.resultClass = 'error';
                this.resultTxt = "Email, ID Patient ou mot de passe erroné."
              }
              this.isLoading = false;
            });
          }
        } else {
          this.resultClass = 'error';
        }
      }
    },
    async created () {
      // Authenticate
      if (localStorage.connexionMsg !== "" && localStorage.connexionMsg !== "undefined") {
        if (localStorage.connexionMsgType !== "" && localStorage.connexionMsgType !== "undefined") {
          this.resultClass = 'success';
          localStorage.connexionMsgType = "";
        } else {
          this.hasError = true;
        }
        this.resultTxt = localStorage.connexionMsg;
      }
      localStorage.connexionMsg = null;

      
      if ((this.$route.query.ori && this.$route.query.ori === 'introduction') ||
          (localStorage.connexionInscriptionMode && localStorage.connexionInscriptionMode !== '' && localStorage.connexionInscriptionMode !== "undefined")) {
        this.switchConnexion();
        this.resultTxt = '';
        localStorage.connexionInscriptionMode = '';
      }
      this.isLoading = false; 
      const isAuthenticate = await authentication.isAuthenticate();
      console.log('isAuthenticate : '+isAuthenticate);
      if (isAuthenticate) {
        this.$router.push('journal');
      } 
    },
  }
</script>

<style lang="scss">
  body .error {
    text-align: center; 
  }
  h1 {
    text-align: center;
    margin: 50px auto !important;
    font-weight: 600 !important;
    font-size: 30px;
  }
  button.switchVisibility {
    position: absolute;
    right: 0;
    top:8px;
    width: auto !important;
    background: #FFF;
  }
  label.cgu {
    color: #7D7D7D;
    font-size: 13px;
    font-weight: 500;
    padding: 0 20px;
    margin: 0 0 10px;
  }
</style>