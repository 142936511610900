<template>
  <div class="top-templatehome text-center">
    <img src="/img/logo_welcome.png" alt="Emotivapp"><br>
    <h1>Oups, la page n'existe pas..</h1>
    <div class="row">
      <div class="col-12">
        <router-link class="btn btn-emot " to="/">Accueil</router-link>
      </div>
    </div>      
  </div>
</template>

<script>
  // @ is an alias to /src
  // import TheHeader from '@/components/TheHeader.vue'

  
  
  export default {
    name: 'NotFound',
  }
</script>