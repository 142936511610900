<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div class="grey-wrapper">
    <div class="top-template journal">
      <div class="progression-header">
        <ul>
          <li><a href="#" :class="getActivePeriode('semaine')" @click.prevent.stop="changeActivePeriod('semaine')">Semaine</a></li>
          <li><a href="#" :class="getActivePeriode('mois')" @click.prevent.stop="changeActivePeriod('mois')">Mois</a></li>
          <li><a href="#" :class="getActivePeriode('annee')" @click.prevent.stop="changeActivePeriod('annee')">Année</a></li>
        </ul>
        <div class="date-nav">
          <button @click="prevPeriod"><font-awesome-icon icon="chevron-left" /></button>
          <span>{{ getCurrentPeriod() }}</span>
          <button @click="nextPeriod"><font-awesome-icon icon="chevron-right" /></button>
        </div>
        
        <div class="indicator-header">
          <div class="row">
            <div class="col-4">
              <div class="indicator-wrapper">
                <img src="/img/prog-activite.png" alt="Activités">
                <span class="d-block indic-mesure">{{ saisieTotaux.activites }}</span>
                <span class="d-block indic-label">Activités</span>
              </div>
            </div>
            <div class="col-4">
              <div class="indicator-wrapper">
                <img src="/img/prog-points.png" alt="Points">
                <span class="d-block indic-mesure">{{ saisieTotaux.points }}<span>/{{ saisieTotaux.pointsObjectifs }}</span></span>
                <span class="d-block indic-label">Points - MET</span>
              </div>
            </div>
            <div class="col-4">
              <div class="indicator-wrapper">
                <img src="/img/prog-duree.png" alt="Durée">
                <span class="d-block indic-mesure">{{ saisieTotaux.duree }}</span>
                <span class="d-block indic-label">Min</span>
              </div>
            </div>          
          </div>
        </div>
      </div>
    </div>

    <div class="padding-wrapper">
      <div class="progression-bloc progression-activite">
        <h2>Progression de l'activité</h2>
        <legend>Intensité de l'activité</legend>
        <canvas id="chartActivite"></canvas>
      </div>

      <div class="progression-bloc progression-saisie">
        <select v-model="activeSaisieChart" @change="changeActiveSaisieChart()">
          <option value="humeur" selected="selected">Humeur</option>
          <option value="sommeil">Sommeil</option>
          <option value="stress">Stress</option>
        </select>
        <label v-show="activeSaisieChart==='humeur'">{{ this.saisieTotaux.humeur }} <span>humeurs</span></label>
        <label v-show="activeSaisieChart==='sommeil'">{{ this.saisieTotaux.sommeil }} <span>Nuits</span></label>
        <label v-show="activeSaisieChart==='stress'">{{ this.saisieTotaux.stress }} <span>Niveaux de stress</span></label>
        <canvas id="chartSaisie"></canvas>
        <div class="progression-saisie-footer">
          <ul class="saisie-footer-humeur" v-show="activeSaisieChart==='humeur'">
            <li class="excellent">
              <div>
                <img src="/img/humeur_excellent.png" alt="Excellent">
                <span>Excellent</span>
                <span class="counter">{{ saisieData.humeur[0] }}</span>
              </div>
            </li>
            <li class="bien">
              <div>
                <img src="/img/humeur_bien.png" alt="Bien">
                <span>Bien</span>
                <span class="counter">{{ saisieData.humeur[1] }}</span>
              </div>
            </li>
            <li class="mouai">
              <div>
                <img src="/img/humeur_mouai.png" alt="Mouai">
                <span>Mouais</span>
                <span class="counter">{{ saisieData.humeur[2] }}</span>
              </div>
            </li>
            <li class="mauvais">
              <div>
                <img src="/img/humeur_mauvais.png" alt="Mauvais">
                <span>Mauvaise</span>
                <span class="counter">{{ saisieData.humeur[3] }}</span>
              </div>
            </li>
            <li class="tres-mauvais">
              <div>
                <img src="/img/humeur_tres-mauvais.png" alt="Tres mauvais">
                <span>Horrible</span>
                <span class="counter">{{ saisieData.humeur[4] }}</span>
              </div>
            </li>
          </ul>
          <ul class="saisie-footer-sommeil" v-show="activeSaisieChart==='sommeil'">
            <li class="bon">
              <div>
                <img src="/img/sommeil_bon.png" alt="bon">
                <span>Bonne nuit</span>
                <span class="counter">{{ saisieData.sommeil[0] }}</span>
              </div>
            </li>
            <li class="moyen">
              <div>
                <img src="/img/sommeil_moyen.png" alt="moyen">
                <span>Nuit moyenne</span>
                <span class="counter">{{ saisieData.sommeil[1] }}</span>
              </div>
            </li>
            <li class="mauvais">
              <div>
                <img src="/img/sommeil_mauvais.png" alt="mauvais">
                <span>Mauvaise nuit</span>
                <span class="counter">{{ saisieData.sommeil[2] }}</span>
              </div>
            </li>
          </ul>
          <ul class="saisie-footer-stress" v-show="activeSaisieChart==='stress'">
            <li class="nostress">
              <div>
                <img src="/img/stress_saisie_no.png" alt="No stress">
                <span>No stress</span>
                <span class="counter">{{ saisieData.stress[0] }}</span>
              </div>
            </li>
            <li class="modere">
              <div>
                <img src="/img/stress_saisie_modere.png" alt="Modéré">
                <span>Modéré</span>
                <span class="counter">{{ saisieData.stress[1] }}</span>
              </div>
            </li>
            <li class="intense">
              <div>
                <img src="/img/stress_saisie_intense.png" alt="Intense">
                <span>Intense</span>
                <span class="counter">{{ saisieData.stress[2] }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="progression-bloc progression-symptomes">
        <h2>Progression des symptômes</h2>
        <label class="dos"><a href="#" @click.prevent.stop="toggleDos"><span>&nbsp;</span>Dos</a></label>
        <label class="fesses-et-jambes"><a href="#" @click.prevent.stop="toggleFesses"><span>&nbsp;</span>Fesses et jambes</a></label>
        <canvas id="chartSymptomes"></canvas>
      </div>

    </div>
  </div>
  <emotiv-footer activeItem="progression"></emotiv-footer>

</template>

<script>

import emotivFooter from "../components/EmotivFooter";
import backend from "../api/backend";
import { DateTime } from "luxon";
import { Chart, registerables } from "chart.js";
// import { DoughnutChart } from 'vue-chart-3';
Chart.register(...registerables);

var activiteChart = null;
var saisieChart = null;
var symptomesChart = null;

Chart.defaults.elements.arc.borderWidth = 0;
// Chart.defaults.elements.arc.roundedCornersFor = {
//   "start": 0, //0th position of Label 1
//   "end": 3 //2nd position of Label 2
// };
// Chart.defaults.elements.arc.hoverBorderColor = 'white';
Chart.defaults.datasets.doughnut.cutout = '73%';

export default {
  name: 'Progression',
  components: {
    emotivFooter,
    // DoughnutChart
  },

  data() {
    return {
      isLoading: true,
      hasError: false,
      errorTxt: null,
      activePeriod: 'semaine',
      nowTime: null,
      startPeriod: null,
      endPeriod: null,
      weekDays: {
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: ''
      },
      activiteData : [],
      activiteAxeX : {
        'semaine': ['Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.', 'Dim.'],
        'annee': ['Jan.', 'Fev.', 'Mar.', 'Avr.', 'Mai.', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
        'mois': [], 
      },
      activeSaisieChart: 'humeur',
      saisieLabels : {
        // 'humeur': ['#C95470','#E08599','#F1B278','#F9D091','#9AB87C'],
        'humeur': ['#9AB87C','#F9D091','#F1B278','#E08599', '#C95470'],
        'sommeil': ['#9AB87C','#EDBE88','#E08599'],
        'stress': ['#9AB87C', '#EDBE88', '#E08599'],
      },
      saisieData: {
        'humeur': [],
        'sommeil': [],
        'stress': [],
      },
      saisieTotaux: {
        'humeur': 0,
        'sommeil': 0,
        'stress': 0, 
        'activites': 0,
        'points': 0,
        'duree': '0h00',
        'pointsObjectifs': 0
      },
      symptomesData: {
        'dos': [],
        'fessesJambes':[]
      },
      testData: {
        labels: ['Paris', 'Nîmes', 'Toulon', 'Perpignan', 'Autre'],
        datasets: [
          {
            data: [30, 40, 60, 70, 5],
            backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED'],
          },
        ],
      }
    }
  },
  methods: {
    calculateCurrentWeek() {
      let out = 'Du '+ this.startPeriod.toObject().day;
      if (this.startPeriod.toFormat('LLLL') !== this.endPeriod.toFormat('LLLL')) {
        out = out +  ' ' + this.startPeriod.toFormat('LLLL');
      }
      this.currentWeek = out + ' au ' + this.endPeriod.toObject().day + ' ' + this.endPeriod.toFormat('LLLL');
    },
    formatedMonth() {
      return this.nowTime.toFormat('LLLL') + ' ' + this.nowTime.toFormat('yyyy');
    },
    formatedYear() {
      return this.nowTime.toFormat('yyyy');
    },
    getActivePeriode(period) {
      if (this.activePeriod === period) {
        return "period-active";
      } else {
        return "";
      }
    },
    updateWeekEvents() {
      let weekDays = this.weekDays;
      weekDays[0] = this.startPeriod.toFormat('d');
      let tmpDay = DateTime.fromObject(this.startPeriod.toObject());
      let weekDaysNb = [1,2,3,4,5,6];
      weekDaysNb.forEach(function (value) {
        tmpDay = tmpDay.plus({days: 1});
        weekDays[value]= tmpDay.toFormat('d');
      });
    },
    getDayLetter(indexDay) {
      console.log('indexDay: ' + indexDay);
      if (indexDay === '0') return "Lun.";
      if (indexDay === '1') return "Mar.";
      if (indexDay === '2') return "Mer.";
      if (indexDay === '3') return "Jeu.";
      if (indexDay === '4') return "Ven.";
      if (indexDay === '5') return "Sam.";
      if (indexDay === '6') return "Dim.";
    },
    getCurrentPeriod() {
      if (this.activePeriod === 'semaine') {
        return this.currentWeek;
      }
      if (this.activePeriod === 'mois') {
        return this.formatedMonth();
      }
      if (this.activePeriod === 'annee') {
        return this.formatedYear();
      }
    },
    changeActivePeriod(newPeriod) {
      this.activePeriod = newPeriod;
      if (this.activePeriod === 'semaine') {
        this.startPeriod = this.nowTime.startOf('week');
        this.endPeriod = this.nowTime.endOf('week');
        this.calculateCurrentWeek();
        this.updateWeekEvents();
      }
      if (this.activePeriod === 'mois') {
        this.startPeriod = this.nowTime.startOf('month');
        this.endPeriod = this.nowTime.endOf('month');
      }
      if (this.activePeriod === 'annee') {
        this.startPeriod = this.nowTime.startOf('year');
        this.endPeriod = this.nowTime.endOf('year');
      }      
      this.updatePeriodData(true);
    },
    changeActiveSaisieChart() {
      saisieChart.data.datasets[0].data = this.saisieData[this.activeSaisieChart];
      saisieChart.data.datasets[0].backgroundColor = this.saisieLabels[this.activeSaisieChart];
      saisieChart.update();
    },
    prevPeriod() {
      if (this.activePeriod === 'semaine') {
        this.nowTime = this.nowTime.minus({weeks: 1});
        this.startPeriod = this.startPeriod.minus({weeks: 1});
        this.endPeriod = this.endPeriod.minus({weeks: 1});
        this.calculateCurrentWeek();
        this.updateWeekEvents();
      }
      if (this.activePeriod === 'mois') {
        this.nowTime = this.nowTime.minus({months: 1});
        this.startPeriod = this.nowTime.startOf('month');
        this.endPeriod = this.nowTime.endOf('month');
      }
      if (this.activePeriod === 'annee') {
        this.nowTime =  this.nowTime.minus({years: 1});
        this.startPeriod = this.nowTime.startOf('year');
        this.endPeriod = this.nowTime.endOf('year');
      }
      this.updatePeriodData(false);
    },
    nextPeriod() {
      if (this.activePeriod === 'semaine') {
        this.nowTime = this.nowTime.plus({weeks: 1});
        this.startPeriod = this.startPeriod.plus({weeks: 1});
        this.endPeriod = this.endPeriod.plus({weeks: 1});
        this.calculateCurrentWeek();
        this.updateWeekEvents();
      }
      if (this.activePeriod === 'mois') {
        this.nowTime = this.nowTime.plus({months: 1});
        this.startPeriod = this.nowTime.startOf('month');
        this.endPeriod = this.nowTime.endOf('month');
      }
      if (this.activePeriod === 'annee') {
        this.nowTime = this.nowTime.plus({years: 1});
        this.startPeriod = this.nowTime.startOf('year');
        this.endPeriod = this.nowTime.endOf('year');
      }
      this.updatePeriodData(false);
    },
    async updatePeriodData(updateCharts) {
      this.isLoading = true;
      const progressionData = await backend.getProgressionData(this.activePeriod, this.startPeriod.toISODate(), this.endPeriod.toISODate());
      if (progressionData !== null) {
        console.log('reponse dans le journal ', progressionData);
        this.activiteData = progressionData.activiteByPeriod;
        this.saisieData.humeur = progressionData.compteurs.humeur;
        this.saisieData.sommeil = progressionData.compteurs.sommeil;
        this.saisieData.stress = progressionData.compteurs.stress;
        this.saisieTotaux.humeur = progressionData.compteurs.totaux.humeur;
        this.saisieTotaux.sommeil = progressionData.compteurs.totaux.sommeil;
        this.saisieTotaux.stress = progressionData.compteurs.totaux.stress;
        this.symptomesData.dos = progressionData.compteurs.symptomes.dos;
        this.symptomesData.fessesJambes = progressionData.compteurs.symptomes.fessesJambes;
        this.saisieTotaux.activites = progressionData.compteurs.totaux.activites;
        this.saisieTotaux.points = progressionData.compteurs.totaux.points;
        this.saisieTotaux.pointsObjectifs = progressionData.compteurs.totaux.pointsObjectifs;
        this.saisieTotaux.duree = progressionData.compteurs.totaux.duree;
        this.updateCharts(updateCharts);
        this.isLoading = false;
      }
    },
    updateCharts(updateCharts) {
      // Activite
      activiteChart.data.labels = this.activiteAxeX[this.activePeriod];
      activiteChart.data.datasets[0].data = this.activiteData;
      if (updateCharts && this.activePeriod === 'mois') {
        this.initChartActivityLine();
      }
      if (updateCharts && this.activePeriod === 'semaine') {
        this.initChartActivityBar();
      }
      activiteChart.update();
      
      // Saisie
      this.changeActiveSaisieChart();
      
      // Symptomes
      symptomesChart.data.labels = this.activiteAxeX[this.activePeriod];
      symptomesChart.data.datasets[0].data = this.symptomesData.dos;
      symptomesChart.data.datasets[1].data = this.symptomesData.fessesJambes;
      symptomesChart.update();
    },
    initSaisieChart() {
      if (saisieChart) {
        saisieChart.destroy();
      }
      const chartsaisie = document.getElementById('chartSaisie');
      var thisContext = this;
      saisieChart = new Chart(chartsaisie, {
        type: 'doughnut',
        data: {
          datasets: [{
            // label: '# of Votes',
            data: thisContext.saisieData[thisContext.activeSaisieChart],
            backgroundColor: thisContext.saisieLabels[thisContext.activeSaisieChart],
            // data: [300, 50, 100],
            // backgroundColor: [
            //   'rgb(255, 99, 132)',
            //   'rgb(54, 162, 235)',
            //   'rgb(255, 205, 86)'
            // ],            
            hoverOffset: 4,
            borderJoinStyle:'round',
          }]
        },
        // plugins: [{
        //   afterUpdate: function(chart) {
        //     if (chart.options.elements.arc.roundedCornersFor !== undefined) {
        //       var arcValues = Object.values(chart.options.elements.arc.roundedCornersFor);
        //       arcValues.forEach(function(arcs) {
        //         arcs = Array.isArray(arcs) ? arcs : [arcs];
        //         arcs.forEach(function(i) {
        //           var arc = chart.getDatasetMeta(0).data[i];
        //           arc.round = {
        //             x: (chart.chartArea.left + chart.chartArea.right) / 2,
        //             y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
        //             radius: (arc.outerRadius + arc.innerRadius) / 2,
        //             thickness: (arc.outerRadius - arc.innerRadius) / 2,
        //             backgroundColor: arc.options.backgroundColor
        //           }
        //         });
        //       });
        //     }
        //   },
        //   afterDraw: (chart) => {
        //     if (chart.options.elements.arc.roundedCornersFor !== undefined) {
        //       var {
        //         ctx
        //       } = chart;
        //       var arc,
        //           roundedCornersFor = chart.options.elements.arc.roundedCornersFor;
        //       for (var position in roundedCornersFor) {
        //         var values = Array.isArray(roundedCornersFor[position]) ? roundedCornersFor[position] : [roundedCornersFor[position]];
        //         values.forEach(p => {
        //           arc = chart.getDatasetMeta(0).data[p];
        //           var startAngle = Math.PI / 2 - arc.startAngle;
        //           var endAngle = Math.PI / 2 - arc.endAngle;
        //           ctx.save();
        //           ctx.translate(arc.round.x, arc.round.y);
        //           ctx.fillStyle = arc.options.backgroundColor;
        //           ctx.beginPath();
        //           if (position == "start") {
        //             ctx.arc(arc.round.radius * Math.sin(startAngle), arc.round.radius * Math.cos(startAngle), arc.round.thickness, 0, 2 * Math.PI);
        //           } else {
        //             ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
        //           }
        //           ctx.closePath();
        //           ctx.fill();
        //           ctx.restore();
        //         });
        //       }
        //     }
        //   }
        // }],        
        options: {
          //borderWidth: 30,
          //hoverBorderWidth: 30,
          borderJoinStyle:'round',
//          borderRadius: {
//            outerEnd: 50,
//            innerEnd:50
 //         },
          responsive: true,
          plugins: {
            legend: {
              display: false
            },
          },
          cutoutPercentage: 80,
          rotation: -90,
          circumference: 180,
        }
      });
    },
    initChartActivityBar() {
      if (activiteChart) {
        activiteChart.destroy();
      }
      const chartActivite = document.getElementById('chartActivite');
      var thisContext = this;
      activiteChart = new Chart(chartActivite, {
        type: 'bar',
        data: {
          labels: thisContext.activiteAxeX.semaine,
          datasets: [{
            // label: '# of Votes',
            data: thisContext.activiteData,
            borderWidth: 1,
            borderSkipped: false,
            borderRadius: Number.MAX_VALUE,
            backgroundColor: '#9AB87C',
          }]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true
            },
            xAxes: [{
              barThickness: 20,
              maxBarThickness: 50,
            }]
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });
    },
    initChartActivityLine() {
      if (activiteChart) {
        activiteChart.destroy();
      }
      var thisContext = this;
      const chartActivite = document.getElementById('chartActivite');
      activiteChart = new Chart(chartActivite, {
        type: 'line',
        data: {
          labels: thisContext.activiteAxeX[thisContext.activePeriod],
          datasets: [{
            // label: '# of Votes',
            data: thisContext.activiteData,
            borderWidth: 1,
            borderColor: '#9AB87C',
            borderRadius: Number.MAX_VALUE,
            fill: {
              target: 'origin',
              above: 'rgba(154, 184, 124, 0.5)',   // Area will be red above the origin
              below: 'rgba(154, 184, 124, 0.5)'
            },
            cubicInterpolationMode: 'monotone',
            tension: 0.4
          }]
        },
        options: {
          responsive: true,
          spanGaps: true,
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });      
    },
    initChartSynptomes() {
      if (symptomesChart) {
        symptomesChart.destroy();
      }
      var thisContext = this;
      const chartSymptomes = document.getElementById('chartSymptomes');
      symptomesChart = new Chart(chartSymptomes, {
        type: 'line',
        data: {
          labels: thisContext.activiteAxeX[thisContext.activePeriod],
          datasets: [{
            // label: '# of Votes',
            data: thisContext.symptomesData.dos,
            borderWidth: 1,
            borderColor: '#0F87C2',
            borderRadius: Number.MAX_VALUE,
            cubicInterpolationMode: 'monotone',
            tension: 0.4,
            hidden: false,
          },
            {
              // label: '# of Votes',
              data: thisContext.symptomesData.fessesJambes,
              borderWidth: 1,
              borderColor: '#E08599',
              borderRadius: Number.MAX_VALUE,
              cubicInterpolationMode: 'monotone',
              tension: 0.4,
              hidden: false,
            }]
        },
        options: {
          responsive: true,
          spanGaps: true,
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });      
    },
    toggleDos() {
      symptomesChart.data.datasets[0].hidden = !symptomesChart.data.datasets[0].hidden;
      symptomesChart.update();
      return false;
    },
    toggleFesses() {
      symptomesChart.data.datasets[1].hidden = !symptomesChart.data.datasets[1].hidden;
      symptomesChart.update();
      return false;
    },
    initCharts() {
      // Activite
      this.initChartActivityBar();
      this.initSaisieChart();
      this.initChartSynptomes();
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    
    // before
    for (let i = 1; i < 32; i++) {
      this.activiteAxeX.mois.push(''+i);
    }
    
    const nowTime = DateTime.now().setLocale('fr');
    this.nowTime = nowTime; 
    this.startPeriod = nowTime.startOf('week');
    this.endPeriod = nowTime.endOf('week');
    this.calculateCurrentWeek();
    this.updateWeekEvents();

    const progressionData = await backend.getProgressionData(this.activePeriod, this.startPeriod.toISODate(), this.endPeriod.toISODate());
    if (progressionData !== null) {
      console.log('reponse dans le journal ', progressionData);
      this.activiteData = progressionData.activiteByPeriod;
      this.saisieData.humeur = progressionData.compteurs.humeur;
      this.saisieData.sommeil = progressionData.compteurs.sommeil;
      this.saisieData.stress = progressionData.compteurs.stress;
      this.saisieTotaux.humeur = progressionData.compteurs.totaux.humeur;
      this.saisieTotaux.sommeil = progressionData.compteurs.totaux.sommeil;
      this.saisieTotaux.stress = progressionData.compteurs.totaux.stress;
      this.symptomesData.dos = progressionData.compteurs.symptomes.dos;
      this.saisieTotaux.activites = progressionData.compteurs.totaux.activites;
      this.saisieTotaux.points = progressionData.compteurs.totaux.points;
      this.saisieTotaux.pointsObjectifs = progressionData.compteurs.totaux.pointsObjectifs;
      this.saisieTotaux.duree = progressionData.compteurs.totaux.duree;
      this.symptomesData.fessesJambes = progressionData.compteurs.symptomes.fessesJambes;
      this.initCharts();
      this.isLoading = false;
    }
    
    
  }
}
</script>

<style lang="scss" scoped>
body {
  background: rgba(229, 229, 229, 0.5) !important;

  .top-template {
    padding: 0;
  }
  .progression-header {
    background: url('../../public/img/bgJournal.png') no-repeat center top;
    background-size: cover;
    margin: 0;
    padding-top: 10px;
    height: 370px;
    position: relative;
    ul {
      margin: 30px auto 0;
      text-align: center;
      li {
        display: inline-block;
        width: 90px;
        position: relative;
        a {
          display: block;
          border-radius: 4px;
          color: #A0B7C2;
          background: #005F8D;
          padding: 4px 0;
          &.period-active {
            background: #FFF;
            color: #0472A8;
            position: relative;
            z-index: 999;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.35);
          }
        }
        &:first-child {
          position: relative;
          right: -5px;
          width: 95px;
          padding-right: 5px;
        }
        &:last-child {
          position: relative;
          left: -5px;
          width: 95px;
          padding-left: 5px;
        }
      }
    }
    .date-nav {
      bottom: auto;
      top: 120px;
    }
    .indicator-header {
      width: 303px;
      margin: 110px auto 0;
      .indicator-wrapper {
        text-align: center;
        color: #FFF;
        img {
          background: #fff;
          padding: 15px;
          border-radius: 50%;
        } 
        .indic-mesure {
          font-size: 24px;
          span {
            font-size: 14px;  
          }
        }
        .indic-label {
          font-size: 12px;
        }
      }
    }
  }
  .padding-wrapper {
    padding: 0 21px;
    margin-top: -50px;
    padding-bottom: 30px;
    position: relative;
    .progression-bloc {
      background: #FFF;
      box-shadow: 0px 3px 21px rgba(0, 0, 0, 0.17);
      padding: 20px;
      border-radius: 19px;
      margin-bottom: 20px;
      h2 {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
      }
      >legend {
        font-size: 12px;
        color: #717171;
        text-align: center;
        float: none;
      }
      &.progression-saisie {
        position: relative;
        >select {
          width: 200px;
          display: block;
          margin: 10px auto 0;
          font-size: 20px;
          font-weight: 600;
          text-align: center;
          background: #FFF;
          padding: 5px 0;
        }
        >label {
          position: absolute;
          z-index: 9999;
          width: 160px;
          text-align: center;
          left: 50%;
          margin-left: -80px;
          font-size: 64px;
          color: #B9B9B9;
          top: 350px;
          span {
            display: block;
            font-size: 12px;
            color: #9F9F9F;
            margin-top: -15px;
          }
        }
      }
      &.progression-symptomes {
        text-align: center;
        >label {
          font-size: 12px;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
          border-radius: 8px;
          display: inline-block;
          color: #717171;
          padding: 5px 15px;
          margin: 5px 10px 20px;
          span {
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            position: relative;
            top: 4px;
            margin-right: 7px;
          }
          &.dos {
            span {
              background: #0472A8; 
            }
          }
          &.fesses-et-jambes {
            span {
              background: #E08599; 
            }
          }
        }
      }
      .progression-saisie-footer {
        border-top: 0.5px solid #BDBDBD;
        ul {
          margin: 0 auto;
          padding: 10px 0;
          text-align: center;
          li {
            display: inline-block;
            margin: 0 10px;
            position: relative;
            img {
              max-width: 25px;
            }
            span {
              display: block;
              font-size: 12px;
              color: #565555;
              font-family: 'Inter', sans-serif;
              margin-top: 5px;
            }
            span.counter {
              position: absolute;
              top:0px;
              right: 16px;
              border-radius: 50%;
              color: #FFF;
              background: #E08599;
              font-size: 11px;
              display: block;
              text-align: center;
              width: 14px;
              height: 14px;
            }            
          }
        }
        ul.saisie-footer-humeur {
          li {
            margin: 0;
            width: 20%;
            padding-top: 10px;
            > div {
              width: 75px;
              position: relative;
              margin: 0 auto;
            }
            span.counter {
              top:-6px;
              right: 16px;
            }
            &.tres-mauvais span.counter {
              background: #E08599;
            }
            &.mauvais span.counter {
              background: #E08599;
            }
            &.mouai span.counter {
              background: #F1B278;
            }
            &.bien span.counter {
              background: #F9D091;
            }
            &.excellent span.counter {
              background: #9AB87C;
            }
          }
        }
        ul.saisie-footer-sommeil {
          li {
            margin: 0;
            width: 33%;
            padding-top: 10px;
            > div {
              width: 100px;
              position: relative;
              margin: 0 auto;
            }
            img {
              max-width: 60px;
            }
            span.counter {
              top:0;
              right: 11px;
            }
            &.mauvais span.counter {
              background: #E08599;
            }
            &.moyen span.counter {
              background: #EDBE88;
            }
            &.bon span.counter {
              background: #9AB87C;
            }
          }
        }
        ul.saisie-footer-stress {
          li {
            margin: 0;
            width: 33%;
            padding-top: 10px;
            > div {
              width: 75px;
              position: relative;
              margin: 0 auto;
            }
            img {
              max-width: 60px;
            }
            span.counter {
              top: -5px;
              right: 0px;
            }
            &.intense span.counter {
              background: #E08599;
            }
            &.modere span.counter {
              background: #EDBE88;
            }
            &.nostress span.counter {
              background: #9AB87C;
            }
          }
        }
      }
    }
  }
  .header-icon {
    text-align: center;
    span {
      color: #FFF;
      font-size: 12px;
      display: block;
    }
  }
  
  .date-nav {
    text-align: center;
    color: #FFF;
    position: absolute;
    bottom: 42px;
    left: 50%;
    margin-left: -50%;
    width: 100%;
    span {
      margin: 0 7px;
      background: #005F8D;
      border-radius: 22px;
      padding: 10px 15px;
      font-size: 14px;
      text-transform: capitalize;
    }
    button {
      color: #FFF;
      border: 1px solid #FFF;
      width: 27px;
      height: 27px;
      display: inline-block;
      border-radius: 50%;
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 520px) {
  body .padding-wrapper .progression-bloc.progression-saisie > label {
    top: 250px;
  }
}
@media screen and (max-width: 375px) {
  body .padding-wrapper .progression-bloc.progression-saisie > label {
    top: 185px;
  }
}
@media screen and (max-width: 300px) {
  body .padding-wrapper .progression-bloc.progression-saisie > label {
    top: 150px; 
  }
}

</style>