import axios from "axios";
import router from '../router';

export default {
  isAuthenticate() {
    return new Promise((resolve) => {
      if (!localStorage.apitoken) {
        router.push('/connexion');
      }
      axios.post(process.env.VUE_APP_URL_API + '/auth/checkToken', {
        token: localStorage.apitoken,
      }).catch(function(error) {
        console.log('Error', error);
        var response = error.response;
        if (response && (response.status === 401 || response.status === 403)) {
          console.log(response.data);
          if (response.data.message !== "") {
            localStorage.connexionMsg = response.data.message;
          }
          router.push('/connexion');
        }
      }).then(response => {
        if (response.status !== 200 || response.data.result !== 'ok') {
          if (response.data.message !== "") {
            localStorage.connexionMsg = response.data.message; 
          }
          router.push('/connexion');
        } else {
          resolve(true);
        }
      });
    });
  },
  login(email, password) {
      localStorage.apitoken = '';
      return axios.post(process.env.VUE_APP_URL_API + '/auth/connexion', {
        email: email,
        password: password
      })
  },
  registerUser(idPatient, email, password) {
    return axios.post(process.env.VUE_APP_URL_API + '/auth/inscription', {
      idPatient: idPatient,
      email: email,
      password: password
    })
  },  
  reinitPassword(emailOrIdPatient) {
    return axios.post(process.env.VUE_APP_URL_API + '/auth/reinitPassword', {
      emailOrIdPatient: emailOrIdPatient,
    })
  },
  resetPassword(verifKey, newPassword) {
    return axios.post(process.env.VUE_APP_URL_API + '/auth/reinitialisation-confirm', {
      verifKey: verifKey,
      newPassword: newPassword
    })
  },
  activation(enableKey) {
    return axios.post(process.env.VUE_APP_URL_API + '/auth/confirm-activation', {
      enableKey: enableKey
    })
  },
};