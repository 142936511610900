<template>
  <footer>
    <div class="row">
      <button class="buttonAdd" @click="goSaisie()"><font-awesome-icon icon="plus" /></button>
      <div class="col-3">
        <router-link :class="getState('journal')" to="/journal">
          <img :src="getSrcImage('journal')" alt="Journal"><br>
          Journal
        </router-link>
      </div>
      <div class="col-3 footer-progression">
        <router-link :class="getState('progression')" to="/progression">
          <img :src="getSrcImage('progression')" alt="Progression"><br>
          Progrès
        </router-link>
      </div>
      <div class="col-3 footer-planning">
        <router-link :class="getState('planning')" to="/planning">
          <img :src="getSrcImage('planning')" alt="Planning"><br>
          Planning
        </router-link>
      </div>
      <div class="col-3">
        <router-link :class="getState('profil')" to="/profil">
          <img :src="getSrcImage('profil')" alt="Profil"><br>
          Profil
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'EmotivFooter',
  props: {
    activeItem: { required: true, type: String, default: 'progression' },
  },
  methods: {
    getSrcImage(item) {
      if (item === 'journal') {
        let state = "inactive";
        if (this.activeItem === 'journal') {
          state = "active";
        }
        return "img/journal-" + state + ".png";
      }
      if (item === 'progression') {
        let state = "inactive";
        if (this.activeItem === 'progression') {
          state = "active";
        }        
        return "img/progression-" + state + ".png";
      }
      if (item === 'planning') {
        let state = "inactive";
        if (this.activeItem === 'planning') {
          state = "active";
        }
        return "img/planning-" + state + ".png";
      }
      if (item === 'profil') {
        let state = "inactive";
        if (this.activeItem === 'profil') {
          state = "active";
        }
        return "img/profil-" + state + ".png";
      }      
     return "";
    },
    getState(item) {
      if (item === 'journal' && this.activeItem === 'journal') {
        return "itemActive";
      }
      if (item === 'progression' && this.activeItem === 'progression') {
        return "itemActive";
      }
      if (item === 'planning' && this.activeItem === 'planning') {
        return "itemActive";
      }
      if (item === 'profil' && this.activeItem === 'profil') {
        return "itemActive";
      }      
      return "";
    },
    goSaisie() {
      this.$router.push("/saisie");
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  footer {
    padding-bottom: 20px;
    .row {
      position: relative;
      margin-left:0 !important;
      margin-right: 0!important;
      .buttonAdd {
        position: absolute;
        left: 50%;
        margin-left: -29px;
        width: 58px;
        height: 58px;
        background: linear-gradient(89.97deg, #0472A8 -28.11%, #0D9DE3 183.52%);
        border: 3px solid #FFFFFF;
        box-shadow: 0px 7px 7px rgba(185, 185, 185, 0.51);
        border-radius: 53px;
        text-align: center;
        color: #FFF;
        font-size: 26px;
      }
      .col-3 {
        text-align: center;
        &.footer-progression {
          position: relative;
          left: -20px;
        }
        &.footer-planning {
          position: relative;
          right: -20px;
        }        
      }
      img {
        /*display: block;*/
        margin: 0 auto;
      }
      a{
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #C7C7C7;
        &.itemActive {
          color: #0472A8;
        }
      }
    }
  }
</style>
