<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div :class="parentClass">
    <div :class="topBarClasses" v-show="step !== 23">
      <button class="backbutton" @click="backAction"><font-awesome-icon icon="chevron-left" /></button>
      <h4>{{ titleProgressbar }}</h4>
      <span class="barTotal"><span class="barProgress" :style="styleProgressBar"></span></span>
    </div>
    
    <div class="step step1" v-show="step === 1">
      <div class="backgroundwhite">
      <img src="/img/connaissance.png" alt="Faisons connaissance !">
      </div>
      <h3 class="text-center">Faisons connaissance !</h3>
      <p class="color_color_grey_light2 text-center">Mieux vous connaître pour mieux vous accompagner</p>
      <p class="instructions">Ce questionnaire est <span>non obligatoire</span> et ne vous sera <span>proposé qu'une seule fois</span>.</p>
    </div>

    <div class="step step2" v-show="step === 2">
      <label>Hello, quel est votre prénom ou pseudo ?</label>
      <div class="input-length-wrapper">
        <input type="text" v-model="formContainer.prenom" maxlength="30">
        <span class="input-length">{{ formContainer.prenom.length }}/30</span>
      </div>
    </div>

<!--    <div class="step step3" v-show="step === 3">-->
<!--      <label>Quel est le jour de votre anniversaire ?</label>-->
<!--      <div class="date-wrapper row">-->
<!--        <div class="col-3">-->
<!--          <select v-model="formContainer.annivDay" >-->
<!--            <option v-for="day in 31" :key="day"> {{ day }}</option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <select v-model="formContainer.annivMonth">-->
<!--            <option v-for="(month, index) in months" :key="month" :value="index+1">{{ month }}</option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="col-3">-->
<!--          <select v-model="formContainer.annivYear">-->
<!--            <option v-for="year in 100" :key="year">{{ year + 1942 }}</option>-->
<!--          </select>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    
    <div class="step step4" v-show="step === 4">
      <label>Quel est votre genre ?</label>
      <div class="btn-choice-wrapper">
        <button @click="this.formContainer.genre='femme'" :class="borderValue(this.formContainer.genre==='femme')">Femme<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.genre='homme'" :class="borderValue(this.formContainer.genre==='homme')">Homme<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step5" v-show="step === 5">
      <label>Dites-nous en plus sur vous</label>
      <div class="mensuration-wrapper">
        <label>Taille<input type="number" v-model="formContainer.taille" min="120" max="250"><span>Cm</span></label>
        <label>Poids actuel<input type="number" v-model="formContainer.poidsActuel" min="30" max="400"><span>Kg</span></label>
        <label>Poids idéal<input type="number" v-model="formContainer.poidsIdeal" min="30" max="400"><span>Kg</span></label>
      </div>
    </div>

    <div class="step step6" v-show="step === 6">
      <label>Comment évaluez-vous votre niveau de douleur ces derniers jours ?</label>
      <div class="dos-wrapper">
        <img src="/img/dos.png" alt="Douleur dos">
        <span>Dos</span>
      </div>
      <div class="slider-wrapper row">
        <div class="col-2"><button class="menus" @click="formContainer.douleurDos--"><font-awesome-icon icon="minus" /></button></div>
        <div class="col-8"><Slider :format="formatToolTip" :min=-1 :max=100 v-model="formContainer.douleurDos" tooltipPosition="bottom" /></div>
        <div class="col-2"><button class="menus" @click="formContainer.douleurDos++"><font-awesome-icon icon="plus" /></button></div>
      </div>
      <div class="dos-wrapper">
        <img src="/img/fesses-jambes.png" alt="Douleur fesses et jambes">
        <span>Fesses et jambes</span>
      </div>
      <div class="slider-wrapper row">
        <div class="col-2"><button class="menus" @click="formContainer.douleurFessesJambes--"><font-awesome-icon icon="minus" /></button></div>
        <div class="col-8"><Slider :format="formatToolTip" :min=-1 :max=100 v-model="formContainer.douleurFessesJambes" tooltipPosition="bottom" /></div>
        <div class="col-2"><button class="menus" @click="formContainer.douleurFessesJambes++"><font-awesome-icon icon="plus" /></button></div>
      </div>
    </div>

    <div class="step step7" v-show="step === 7">
      <label>{{ formContainer.prenom }}, quel aspect voulez-vous améliorer ?</label>
      <div class="btn-choice-wrapper">
        <button @click="toggleArray(formContainer.aspect, 'Connaissance de la lombalgie')" :class="borderValue(this.formContainer.aspect.includes('Connaissance de la lombalgie'))">Connaissance de la lombalgie<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.aspect, 'Améliorer ma santé')" :class="borderValue(this.formContainer.aspect.includes('Améliorer ma santé'))">Améliorer ma santé<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.aspect, 'Prendre confiance en moi')" :class="borderValue(this.formContainer.aspect.includes('Prendre confiance en moi'))">Prendre confiance en moi<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.aspect, 'Booster la motivation')" :class="borderValue(this.formContainer.aspect.includes('Booster la motivation'))">Booster la motivation<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.aspect, 'Réduire le stress')" :class="borderValue(this.formContainer.aspect.includes('Réduire le stress'))">Réduire le stress<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.aspect, 'Perdre du poids')" :class="borderValue(this.formContainer.aspect.includes('Perdre du poids'))">Perdre du poids<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step8" v-show="step === 8">
      <label>En améliorant votre santé vous souhaitez pouvoir... </label>
      <div class="btn-choice-wrapper">
        <button @click="toggleArray(formContainer.souhait, 'Reprendre mon travail')" :class="borderValue(this.formContainer.souhait.includes('Reprendre mon travail'))">Reprendre mon travail<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.souhait, 'Améliorer ma vie sociale')" :class="borderValue(this.formContainer.souhait.includes('Améliorer ma vie sociale'))">Améliorer ma vie sociale<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.souhait, 'Me sentir mieux dans mon corps')" :class="borderValue(this.formContainer.souhait.includes('Me sentir mieux dans mon corps'))">Me sentir mieux dans mon corps<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.souhait, 'Faire du sport régulièrement')" :class="borderValue(this.formContainer.souhait.includes('Faire du sport régulièrement'))">Faire du sport régulièrement<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step9" v-show="step === 9">
      <label>Quel est selon vous le plus important pour que votre programme soit une réussite ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.plusImportant='Obtenir des résultats rapides'" :class="borderValue(this.formContainer.plusImportant==='Obtenir des résultats rapides')">Obtenir des résultats rapides<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.plusImportant='Qu’il soit facile à suivre'" :class="borderValue(this.formContainer.plusImportant==='Qu’il soit facile à suivre')">Qu’il soit facile à suivre<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.plusImportant='Autre'" :class="borderValue(this.formContainer.plusImportant==='Autre')">Autre<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step10" v-show="step === 10">
      <label>Quels sont selon vous les freins potentiels ?</label>
      <div class="btn-choice-wrapper">
        <button @click="toggleArray(formContainer.freinsPotentiels, 'Le manque de soutien')" :class="borderValue(this.formContainer.freinsPotentiels.includes('Le manque de soutien'))">Le manque de soutien<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.freinsPotentiels, 'La peur de la douleur')" :class="borderValue(this.formContainer.freinsPotentiels.includes('La peur de la douleur'))">La peur de la douleur<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.freinsPotentiels, 'Le manque de temps')" :class="borderValue(this.formContainer.freinsPotentiels.includes('Le manque de temps'))">Le manque de temps<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.freinsPotentiels, 'Le manque de plaisir ')" :class="borderValue(this.formContainer.freinsPotentiels.includes('Le manque de plaisir '))">Le manque de plaisir <font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.freinsPotentiels, 'Autre')" :class="borderValue(this.formContainer.freinsPotentiels.includes('Autre'))">Autre<font-awesome-icon icon="check" /></button>
      </div>
    </div>     
    
    <div class="step step11" v-show="step === 11">
      <label>Qu’est-ce que vous avez le plus apprécié pendant le stage ?</label>
      <div class="btn-choice-wrapper">
        <button @click="toggleArray(formContainer.plusaprecie, 'Les conseils experts')" :class="borderValue(this.formContainer.plusaprecie.includes('Les conseils experts'))">Les conseils experts<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.plusaprecie, 'Comprendre la lombalgie chronique')" :class="borderValue(this.formContainer.plusaprecie.includes('Comprendre la lombalgie chronique'))">Comprendre la lombalgie <br>chronique<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.plusaprecie, 'Les ateliers sportifs')" :class="borderValue(this.formContainer.plusaprecie.includes('Les ateliers sportifs'))">Les ateliers sportifs<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.plusaprecie, 'Me sentir mieux dans mon corps')" :class="borderValue(this.formContainer.plusaprecie.includes('Me sentir mieux dans mon corps'))">Me sentir mieux dans mon corps<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.plusaprecie, 'Me sentir mieux dans ma tête')" :class="borderValue(this.formContainer.plusaprecie.includes('Me sentir mieux dans ma tête'))">Me sentir mieux dans ma tête<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step12" v-show="step === 12">
      <img src="/img/environnement.png" alt="Faisons connaissance !">
      <h3 class="text-center">Découvrons votre <br>environnement !</h3>
      <p class="color_color_grey_light2 text-center">"Parmi vos proches, votre cadre de<br>travail : mieux cerner vos mode<br>et rythme de vie"</p>
    </div>

    <div class="step step13" v-show="step === 13">
      <label>Vous sentez-vous soutenu/e par votre entourage ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.soutenuEntourage='Je suis soutenu/e'" :class="borderValue(this.formContainer.soutenuEntourage==='Je suis soutenu/e')">Je suis soutenu/e<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.soutenuEntourage='Je ne suis pas soutenu/e'" :class="borderValue(this.formContainer.soutenuEntourage==='Je ne suis pas soutenu/e')">Je ne suis pas soutenu/e<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.soutenuEntourage='Je peux obtenir de l’aide'" :class="borderValue(this.formContainer.soutenuEntourage==='Je peux obtenir de l’aide')">Je peux obtenir de l’aide<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.soutenuEntourage='Je me sens incompris/e'" :class="borderValue(this.formContainer.soutenuEntourage==='Je me sens incompris/e')">Je me sens incompris/e<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step14" v-show="step === 14">
      <label>Quelles sont vos sources de stress ?</label>
      <div class="btn-choice-wrapper">
        <button @click="toggleArray(formContainer.sourceStress, 'Le travail')" :class="borderValue(this.formContainer.sourceStress.includes('Le travail'))">Le travail<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.sourceStress, 'La famille')" :class="borderValue(this.formContainer.sourceStress.includes('La famille'))">La famille<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.sourceStress, 'Les finances')" :class="borderValue(this.formContainer.sourceStress.includes('Les finances'))">Les finances<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.sourceStress, 'Je ne suis pas stressé/e')" :class="borderValue(this.formContainer.sourceStress.includes('Je ne suis pas stressé/e'))">Je ne suis pas stressé/e<font-awesome-icon icon="check" /></button>
        <button @click="toggleArray(formContainer.sourceStress, 'Autre')" :class="borderValue(this.formContainer.sourceStress.includes('Autre'))">Autre<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step15" v-show="step === 15">
      <label>Au quotidien, avez-vous du temps libre...</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.tempsLibre='Beaucoup de temps libre'" :class="borderValue(this.formContainer.tempsLibre==='Beaucoup de temps libre')">Beaucoup de temps libre<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.tempsLibre='Peu de temps libre'" :class="borderValue(this.formContainer.tempsLibre==='Peu de temps libre')">Peu de temps libre<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.tempsLibre='Pas du tout de temps libre'" :class="borderValue(this.formContainer.tempsLibre==='Pas du tout de temps libre')">Pas du tout de temps libre<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.tempsLibre='Je peux en trouver'" :class="borderValue(this.formContainer.tempsLibre==='Je peux en trouver')">Je peux en trouver<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step16" v-show="step === 16">
      <label>Dans quel secteur d’activité travaillez-vous ?</label>

      <div class="select-wrapper">
        <select v-model="formContainer.secteurActivite" >
          <option v-for="sect in secteursActivite" :key="sect" :title="sect" :value="sect" > {{ sect }}</option>
        </select>
      </div>

      <label class="mt-4">Quelle profession exercez-vous ?</label>

      <div class="input-length-wrapper">
        <input type="text" v-model="formContainer.profession" maxlength="50">
        <span class="input-length">{{ formContainer.profession.length }}/50</span>
      </div>
    </div>

    <div class="step step17" v-show="step === 17">
      <label>Comment vous sentez-vous au travail ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.sentezTravail='Je travaille trop'" :class="borderValue(this.formContainer.sentezTravail==='Je travaille trop')">Je travaille trop<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.sentezTravail='J’ai un bon équilibre vie/travail'" :class="borderValue(this.formContainer.sentezTravail==='J’ai un bon équilibre vie/travail')">J’ai un bon équilibre vie/travail<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.sentezTravail='Je ne travaille pas actuellement'" :class="borderValue(this.formContainer.sentezTravail==='Je ne travaille pas actuellement')">Je ne travaille pas actuellement<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.sentezTravail='Je ne suis pas satisfait'" :class="borderValue(this.formContainer.sentezTravail==='Je ne suis pas satisfait')">Je ne suis pas satisfait<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step18" v-show="step === 18">
      <div class="backgroundwhite">
        <img src="/img/habitudes.png" alt="Parlons de vous au quotidien !">
      </div>
      <h3 class="text-center">Parlons de vous au quotidien !</h3>
      <p class="color_color_grey_light2 text-center">En savoir plus sur vos<br>habitudes pour mieux<br>appréhender votre vie de tous<br>les jours</p>
    </div>

    <div class="step step19" v-show="step === 19">
      <label>Quelles sont vos habitudes alimentaires ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.habitudesAlimentaires='Cuisine équilibrée'" :class="borderValue(this.formContainer.habitudesAlimentaires==='Cuisine équilibrée')">Cuisine équilibrée<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.habitudesAlimentaires='Grignotage'" :class="borderValue(this.formContainer.habitudesAlimentaires==='Grignotage')">Grignotage<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.habitudesAlimentaires='Junk Food / déséquilibrée'" :class="borderValue(this.formContainer.habitudesAlimentaires==='Junk Food / déséquilibrée')">Junk Food / déséquilibrée<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.habitudesAlimentaires='Mixte'" :class="borderValue(this.formContainer.habitudesAlimentaires==='Mixte')">Mixte<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step20" v-show="step === 20">
      <label>A quoi ressemble votre journée type ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.journeeType='À moitié assis/e, à moitié debout'" :class="borderValue(this.formContainer.journeeType==='À moitié assis/e, à moitié debout')">À moitié assis/e, à moitié debout<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.journeeType='Assis/e toute la journée'" :class="borderValue(this.formContainer.journeeType==='Assis/e toute la journée')">Assis/e toute la journée<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.journeeType='Debout la plupart du temps'" :class="borderValue(this.formContainer.journeeType==='Debout la plupart du temps')">Debout la plupart du temps<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.journeeType='Debout toute la journée'" :class="borderValue(this.formContainer.journeeType==='Debout toute la journée')">Debout toute la journée<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step step21" v-show="step === 21">
      <label>Quelle(s) activité(s) physique(s) aimeriez-vous mettre en place et pratiquer ?</label>

      <div class="btn-choice-wrapper">
        <button @click="formContainer.enduranceCardio=!formContainer.enduranceCardio" :class="borderValue(formContainer.enduranceCardio)">Endurance / cardio<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.renforcementMusc=!formContainer.renforcementMusc" :class="borderValue(formContainer.renforcementMusc)">Renforcement musculaire<font-awesome-icon icon="check" /></button>
        <input type="text" placeholder="Autres" v-model="formContainer.actPhysiqueAutreSelect" maxlength="50">        
      </div>
      
<!--      <div class="select-wrapper">-->
<!--        <select v-model="formContainer.enduranceCardio" >-->
<!--          <option value="">Endurance / cardio</option>-->
<!--          <option v-for="item in enduranceCardioSelect" :key="item"> {{ item }}</option>-->
<!--        </select>-->
<!--      </div>-->
<!--      <div class="select-wrapper mt-3">-->
<!--        <select v-model="formContainer.renforcementMusc" >-->
<!--          <option value="">Renforcement musculaire</option>-->
<!--          <option v-for="item in renfMuscSelect" :key="item"> {{ item }}</option>-->
<!--        </select>-->
<!--      </div>-->
<!--      <div class="select-wrapper mt-3">-->
<!--        <select v-model="formContainer.actPhysiqueAutre" >-->
<!--          <option value="">Autres</option>-->
<!--          <option v-for="item in actPhysiqueAutreSelect" :key="item"> {{ item }}</option>-->
<!--        </select>-->
<!--      </div>-->
    </div>

    <div class="step step22" v-show="step === 22">
      <label>A quelle fréquence faites-vous des exercices musculaires ?</label>
      <div class="btn-choice-wrapper">
        <button @click="formContainer.frequenceExercMusc= 'Au moins une fois par semaine'" :class="borderValue(this.formContainer.frequenceExercMusc==='Au moins une fois par semaine')">Au moins une fois par semaine<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.frequenceExercMusc= 'Au moins 2 fois par mois'" :class="borderValue(this.formContainer.frequenceExercMusc==='Au moins 2 fois par mois')">Au moins 2 fois par mois<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.frequenceExercMusc= '1 fois par mois'" :class="borderValue(this.formContainer.frequenceExercMusc==='1 fois par mois')">1 fois par mois<font-awesome-icon icon="check" /></button>
        <button @click="formContainer.frequenceExercMusc= 'Moins d’une fois par mois'" :class="borderValue(this.formContainer.frequenceExercMusc==='Moins d’une fois par mois')">Moins d’une fois par mois<font-awesome-icon icon="check" /></button>
      </div>
    </div>

    <div class="step stepfinal" v-show="step === 23">
      <div class="final-img-wrapper">
        <h2>{{ formContainer.prenom }},<br>il est temps de créer<br>votre programme EMOTIVapp  !</h2>
      </div>
      <p>“La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre."
        <span>Albert Einstein</span></p>
    </div>

    <div class="error" v-show="erreur !== ''">
      {{ erreur }}
    </div>
    <div class="success" v-show="success !== ''">
      {{ success }}
    </div>    
    
    <div class="bottom-actions text-center">
      <button class="btn-emot fullwidth" :disabled='isButtDisabled' @click="nextStep">{{ labelAction }}</button>
    </div>
  </div>
  
</template>

<script>
  // https://github.com/vueform/slider#using-with-vue-3
  import Slider from '@vueform/slider'
  // import authentication from "../../api/authentication";
  import backend from "../../api/backend";
  
  export default {
    name: 'FormInit',
    components: {
      Slider,
    },
    data() {
      return {
        step: 1,
        isLoading: true,
        erreur: '',
        success: '',
        topBarClasses : 'topBar profileBar',
        titleProgressbar: 'PROFIL',
        styleProgressBar: "width: 10%;",
        parentClass: 'form-initial background-pink',
        labelAction: "Continuer",
        isButtDisabled: false,
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        tooltipPosition: 'bottom',
        secteursActivite: ['sect1', 'sect2', 'sect3'],
        cuisineEquilibreSelect: ['sect1', 'sect2', 'sect3'],
        grignotageSelect: ['sect1', 'sect2', 'sect3'],
        junkFoodSelect: ['sect1', 'sect2', 'sect3'],
        enduranceCardioSelect: ['sect1', 'sect2', 'sect3'],
        renfMuscSelect: ['sect1', 'sect2', 'sect3'],
        actPhysiqueAutreSelect: ['sect1', 'sect2', 'sect3'],
        formContainer: {
          prenom: "",
          annivDay: 1,
          annivMonth: 1,
          annivYear: 1990,
          genre: '',
          taille: '',
          poidsActuel: '',
          poidsIdeal: '',
          douleurDos: -1,
          douleurFessesJambes: -1,
          aspect: [],
          souhait: [],
          plusImportant: '',
          freinsPotentiels: [],
          plusaprecie: [],
          soutenuEntourage: '',
          sourceStress: [],
          tempsLibre: '',
          secteurActivite: '',
          profession: '',
          sentezTravail: '',
          habitudesAlimentaires: '',
          journeeType: '',
          enduranceCardio:false,
          renforcementMusc: false,
          actPhysiqueAutre: '',
          frequenceExercMusc: '',
        }
      }
    },
    methods: {
      backAction() {  
        if (this.step > 1) {
          if(this.step === 4) {
            this.step--;
          }
          this.step--;
        } else {
          this.$router.push('/introduction');
        }
      },
      formatToolTip(a) {
        console.log('formatToolTip');
        console.log(a);
        if (a === -1) {
          return "Placer le curseur"
        }
        return Math.round(a);
      },
      nextStep(event) {
        if (event) {
          event.preventDefault()
        }
        this.erreur = '';
          let hasError = false;
        // validation 
        if (this.step === 2) {
          if (this.formContainer.prenom.length < 3) {
            hasError = true;
            this.erreur = "Merci de renseigner votre prénom ou pseudo (3 caract min)."
          } else {
            this.step++;
          }
        } else if (this.step === 4) {
          //   if (this.formContainer.genre === '') {
          //   hasError = true;
          //   this.erreur = "Merci de choisir votre genre."
          // }
        } else if (this.step === 7) {
          // if (this.formContainer.aspect.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir un aspect à améliorer."
          // }
        } else if (this.step === 8) {
          // if (this.formContainer.souhait.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option."
          // }
        } else if (this.step === 9) {
          // if (this.formContainer.plusImportant.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option."
          // }
        } else if (this.step === 10) {
          // if (this.formContainer.freinsPotentiels.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option."
          // }
        } else if (this.step === 11) {
          // if (this.formContainer.plusaprecie.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option."
          // }
        } else if (this.step === 13) {
          // if (this.formContainer.soutenuEntourage.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option."
          // }
        } else if (this.step === 14) {
          // if (this.formContainer.sourceStress.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option."
          // }
        } else if (this.step === 15) {
          // if (this.formContainer.tempsLibre.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option."
          // }
        } else if (this.step === 17) {
          // if (this.formContainer.sentezTravail.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option."
          // }
        } else if (this.step === 16) {
          // if (this.formContainer.profession === '' || this.formContainer.profession === '') {
          //   hasError = true;
          //   this.erreur = "Merci de choisir un secteur et une profession."
          // }
        } else if (this.step === 17) {
          // if (this.formContainer.sentezTravail.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option."
          // }
        } else if (this.step === 19) {
          // if (this.formContainer.cuisineEquilibre === '' || this.formContainer.grignotage === '' || this.formContainer.junkFood === '') {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option dans chaque liste."
          // }
        } else if (this.step === 20) {
          // if (this.formContainer.journeeType.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option."
          // }
        } else if (this.step === 21) {
          // if (this.formContainer.enduranceCardio === '' || this.formContainer.renforcementMusc === '' || this.formContainer.actPhysiqueAutre === '') {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option dans chaque liste."
          // }
        } else if (this.step === 22) {
          // if (this.formContainer.frequenceExercMusc.length === 0) {
          //   hasError = true;
          //   this.erreur = "Merci de choisir une option."
          // }
        } else if (this.step === 23) {
          this.isButtDisabled = true;
          // loader.show
          backend.submitFormInit(this.formContainer).then(response => {
              if (response.status === 200) {
                if (response.data.result === "ok") {
                  this.success = "Programme créé avec succès !";
                  setTimeout( () => this.$router.push({ path: '/planning'}), 1000);
                } else {
                  this.erreur = "Erreur lors de la soumission du formulaire";
                  console.log(response.data.errors);
                }
              } else {
                this.erreur = "Erreur lors de la soumission du formulaire";
                console.log(response);
              }
            }
          );
        }
        if (!hasError && this.step !== 23) {
          this.step++; 
        }
      },
      computeProgress() {
        let computedPourc = (this.step / 11) * 100;
        if (this.step >= 12 && this.step < 18) {
          let stepInit = this.step - 11;
          let stepFinal = 18 - 12;
          computedPourc = (stepInit / stepFinal) * 100;
          console.log('stepInit '+stepInit + ', stepFinal '+stepFinal+' computedPourc '+computedPourc);
        }
        if (this.step >= 18 ) {
          let stepInit = this.step - 17;
          let stepFinal = 22 - 17;
          computedPourc = (stepInit / stepFinal) * 100;
        }
        this.styleProgressBar = "width: "+computedPourc+"%;";
      },
      borderValue(isActive) {
        if (isActive) {
          return "selectButton selectedButton";
        } 
        return "selectButton";
      },
      toggleArray(arrayP, valP) {
        if (arrayP.includes(valP)) {
          arrayP.splice (arrayP.indexOf('valP'), 1);
        } else {
          arrayP.push(valP);
        }
      }
    },
    
    watch: {
      step() {
        // Background 
        if (this.step === 1) {
          this.parentClass = 'form-initial background-pink';
        } else if (this.step > 1 && this.step < 12) {
          this.parentClass = "form-initial pink-input";
          this.topBarClasses = 'topBar profileBar';
          this.titleProgressbar = 'Profil';
        } else if (this.step === 12) {
          this.parentClass = "form-initial background-green";
          this.topBarClasses = "topBar envBar";
          this.titleProgressbar = 'Environnement';
        } else if (this.step > 12 && this.step < 18) {
          this.parentClass = "form-initial green-input";
          this.topBarClasses = "topBar envBar";
          this.titleProgressbar = 'Environnement';
        } else if (this.step === 18) {
          this.parentClass = "form-initial background-yellow";
          this.topBarClasses = "topBar habitudeBar";
          this.titleProgressbar = 'Habitudes et comportements';
        } else if (this.step > 18 && this.step < 23) {
          this.parentClass = "form-initial yellow-input";
          this.topBarClasses = "topBar habitudeBar";
          this.titleProgressbar = 'Habitudes et comportements';
        } else if (this.step === 23) {
          this.parentClass = 'form-initial background-blue';
        }
        // Button action
        if (this.step === 1) {
          this.labelAction = "Continuer";
        } else if (this.step > 1 && this.step < 23) {
          this.labelAction = "Continuer";
        } else if (this.step === 23) {
          this.labelAction = "Créer mon programme";
        }
        this.computeProgress();
      }
    },
   // async created () {
   //    // Authenticate
   //    const isAuthenticate = await authentication.isAuthenticate();
   //    console.log('isAuthenticate : '+isAuthenticate);
   //  }, 
    async mounted() {
      
      this.computeProgress();
      const secteursFromApi = await backend.getCspValues();
      if (secteursFromApi !== null) {
        this.secteursActivite = secteursFromApi;
      }
      const formInitDone = await backend.isFormInitDone();
      if (formInitDone != null) {
        if (!formInitDone.needFormInit) {
          this.$router.push('/journal');    
        } 
      }
      if (formInitDone != null && secteursFromApi !== null) {
        this.isLoading = false; 
      }
    },
  }
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss" scoped>
  body {
    .form-initial {
      padding: 20px 26px 50px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      &.background-blue {
        padding: 0;
      }
    }
    .error {
      margin: 0 0 20px;
      text-align: center;
      color: #E08599;
    }
    .success {
      margin: 0 0 20px;
      text-align: center;
      color: #1e7e34;
    }
    .backbutton {
      position: absolute;
      left: 0;
      top: 12px;
      border-radius: 50%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      width: 36px;
      height: 36px;
      color: #898A8D;
    }
    .topBar {
      padding-left: 56px;
      position: relative;
      h4 {
        background: #E08599;
        color: #FFF;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.5px;
        display: inline-block;
        padding: 2px 10px;
      }
      .barTotal {
        position: relative;
        display: block;
        height: 6px;
        width: 100%;
        background: #F9F9F9;
        border-radius: 7px;
        .barProgress {
          background: #E08599;
          height: 6px;
          display: block;
          position: absolute;
          left:0;
          top:0;
          border-radius: 7px;
        }
      }
      &.profileBar {
        h4 {
          background: #E08599; 
        }
        .barTotal .barProgress {
          background: #E08599;
        }
      }
      &.envBar {
        h4 {
          background: #B6C07A
        }
        .barTotal .barProgress {
          background: #B6C07A;
        }
      }
      &.habitudeBar {
        h4 {
          background: #ECBB6D
        }
        .barTotal .barProgress {
          background: #ECBB6D;
        }
      }
    }
    .step {
      padding: 50px 0 30px;
      min-height: 400px;
      h3 {
        font-family: 'Lato', sans-serif;
      }
      &.step1 {
        margin-bottom: 50px;
        padding: 30px 0;
        .instructions {
          font-size: 12px;
          text-align: center;
          margin-top: 40px;
          span {
            color: #0A89C7;            
          }
        }
      }
      button.selectButton {
        position: relative;
        svg {
          display: none;
          position: absolute;
          right: 15px;
          font-size: 23px;
          top: 30%;
        }
        
      }
      &.stepfinal {
        padding-top: 0;
        .final-img-wrapper {
          background: url('../../../public/img/bgFormInit.png') no-repeat center top;
          //background-size: 100% auto;
          background-size: cover;
          padding: 160px 0 150px;
          border-radius: 0px 0px 400px 400px / 0px 0px 70px 70px;
        }
        h2 {
          color: #FFF;
          font-family: 'Lato', sans-serif;
          text-align: center;
          font-size: 36px;
          margin: 0 auto;
        }
        p {
          font-family: 'Inter', sans-serif;
          font-size: 18px;
          text-align: center;
          margin: 50px auto 20px;
          padding: 0 20px;
          span {
            display: block;
            font-size: 14px;
            margin: 20px auto;
          }
        }
      }
    }
    .pink-input button.selectedButton {
      border-color: #E2A1AF;
      svg {
        display: inline-block;
        color: #E2A1AF;
      }
    }
    .green-input button.selectedButton {
      border-color: #B6C07A;
      svg {
        display: inline-block;
        color: #B6C07A;
      }
    }     
    .yellow-input button.selectedButton {
      border-color: #ECBA6D;
      svg {
        display: inline-block;
        color: #ECBA6D;
      }
    }    
    .pink-input {
      input, textarea {
        border-bottom: 1px solid #E08599;
      }
    }
    input, textarea {
      text-align: center;
      font-size: 24px;
    }
    select {
      max-width: 100%;
      width: 100%;
      padding: 16px 8px;
      font-size: 20px;
    }
    .select-wrapper {
      text-align: center;
      select {
        text-align: center;  
      }
    }
    label {
      margin-bottom: 30px;
      font-size: 34px;
      font-weight: 500;
      line-height: 1.4em;
    }
    .col-3, .col-6 {
      padding: 0 4px !important;
    }
    .mensuration-wrapper {
      label {
        border-bottom: 1px solid #E08599;
        display: block;
        width: 100%;
        padding: 5px 40px 5px 20px;
        font-weight: 500;
        font-size: 24px;
        position: relative;
        input {
          position: absolute;
          right: 18%;
          top: -2px;
          border: none;
          color: #E08599;
          font-weight: 500;
          font-size: 36px;
          width: 100px;
          padding: 0;
          text-align: right;
        }
        span {
          color: #979797;
          font-size: 18px;
          position: absolute;
          right: 20px;
          top:0;          
        }
      }
    }
    .backgroundwhite {
      background: #FFFFFF;
      border-radius: 24px;
      margin: 20px auto;
      width: 270px;
      position: relative;
      overflow: visible;
      height: 230px;
      img {
        position: absolute;
        width: 330px;
        left: 50%;
        margin-left: -165px;
        top: 0;
      }
    }
    .step18 .backgroundwhite {
      width: 317px;
      height: 300px;
      text-align: center;
      img {
        position: static;
        width: auto;
        margin-left: 0;
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    .slider-wrapper {
      margin-bottom: 60px;
    }
    .slider-connect {
      background: #E08599;
    }
    .slider-tooltip {
      background: #0472A8;
      border: 1px solid #0472A8;
    }
    .slider-handle {
      background: #E08599;
      border: 3px solid #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.47);
    }
    .dos-wrapper {
      padding: 0 0 25px 70px;
      span {
        margin-left: 20px;
        color: #0472A8;
        font-size: 14px;
      }
    }
    .bottom-actions {
      padding: 0 20px;
    }
  }
  
</style>