<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div :class="parentClass">
    <div :class="topBarClasses" v-show="step !== 23">
      <button class="backbutton" @click="backAction"><font-awesome-icon icon="chevron-left" /></button>
    </div>
    
    <div class="step step1" v-show="step === 1">
      <label>IMC</label>
      <div class="mensuration-wrapper">
        <label>Taille<input type="number" v-model="formContainer.taille" min="120" max="250"><span>Cm</span></label>
        <label>Poids actuel<input type="number" v-model="formContainer.poidsActuel" min="30" max="400"><span>Kg</span></label>
      </div>
    </div>

    <div class="error" v-show="erreur !== ''">
      {{ erreur }}
    </div>
    <div class="success" v-show="success !== ''">
      {{ success }}
    </div>    
    
    <div class="bottom-actions text-center">
      <button class="btn-emot fullwidth" @click="nextStep">{{ labelAction }}</button>
    </div>
  </div>
  
</template>

<script>
  import backend from "../../api/backend";
  
  export default {
    name: 'FormMic',
    data() {
      return {
        step: 1,
        erreur: '',
        success: '',
        isLoading: false,
        topBarClasses : 'topBar profileBar',
        parentClass: 'form-initial background-blue',
        labelAction: "continuer",
        formContainer: {
          taille:'',
          poidsActuel: '',
        }
      }
    },
    methods: {
      backAction() {  
        if (this.step > 1) {
          this.step--;
        } else {
          this.$router.push('/journal');
        }
      },
      nextStep(event) {
        if (event) {
          event.preventDefault()
        }
        
        this.erreur = '';
        let hasError = false;
        // validation 
        if (this.step === 1 && (this.formContainer.taille === '' || this.formContainer.poidsActuel === '')  ) {
            hasError = true;
            this.erreur = "Merci de renseigner taille et poids."
        } 
        if (!hasError && this.step === 1) {
          this.isLoading = true;
          backend.submitMic(this.formContainer).then(response => {
            this.isLoading = false;
            if (response.status === 200) {
              if (response.data.result === "ok") {
                this.success = "IMC enregistré avec succès.";
                this.labelAction = "Journal";
                setTimeout( () => {this.success = ''; this.$router.push({ path: '/journal'});}, 1000);
              } else {
                this.erreur = "Erreur lors de la soumission du formulaire.";
                if (response.data.errors.length > 0) {
                  this.erreur = this.erreur + response.data.errors.toString();
                }
                console.log(response.data.errors);
              }
            } else {
              this.erreur = "Erreur serveur lors de la soumission du formulaire.";
            }
            }
          );
        }
      },
    },
    
    watch: {
      step() {
        // Background 
        if (this.step >= 1 && this.step < 3) {
          this.parentClass = "form-initial blue-input";
          this.topBarClasses = 'topBar profileBar';
        }
        this.labelAction = "Envoyer";
      }
    },
  }
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss" scoped>
  body {
    .form-initial {
      padding: 20px 26px 50px;
    }
    .error {
      margin: 0 0 20px;
      text-align: center;
      color: #E08599;
    }
    .success {
      margin: 0 0 20px;
      text-align: center;
      color: #1e7e34;
    }
    .backbutton {
      position: absolute;
      left: 0;
      top: 12px;
      border-radius: 50%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      width: 36px;
      height: 36px;
      color: #898A8D;
    }
    .topBar {
      padding-left: 56px;
      position: relative;
      h4 {
        background: #0472A8;
        color: #FFF;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.5px;
        display: inline-block;
        padding: 2px 10px;
      }
      .barTotal {
        position: relative;
        display: block;
        height: 6px;
        width: 100%;
        background: #F9F9F9;
        border-radius: 7px;
        top: 28px;
        .barProgress {
          background: #0472A8;
          height: 6px;
          display: block;
          position: absolute;
          left:0;
          top:0;
          border-radius: 7px;
        }
      }
      &.profileBar {
        h4 {
          background: #0472A8; 
        }
        .barTotal .barProgress {
          background: #0472A8;
        }
      }
    }
    .step {
      padding: 75px 0 30px;
      min-height: 400px;
      h3 {
        font-family: 'Lato', sans-serif;
      }
      &.step1 {
        margin-bottom: 50px;
        padding: 75px 0 30px;
      }
      button.selectButton {
        position: relative;
        padding: 16px 40px 16px 40px;
        svg {
          display: none;
          position: absolute;
          right: 25px;
          font-size: 23px;
          top: 30%;
        }
        
      }
      &.stepfinal {
        h2 {
          color: #FFF;
          font-family: 'Lato', sans-serif;
          text-align: center;
          font-size: 36px;
          margin: 50px auto 70px;
        }
        p {
          font-family: 'Inter', sans-serif;
          font-size: 18px;
          text-align: center;
          margin: 50px auto 20px;
          span {
            display: inline-block;
            font-size: 14px;
            margin: 20px auto;
          }
        }
      }
    }
    .blue-input button.selectedButton {
      border-color: #0472A8;
      svg {
        display: inline-block;
        color: #0472A8;
      }
    }
    
    .blue-input {
      input, textarea {
        border-bottom: 1px solid #0472A8;
      }
    }
    input, textarea {
      text-align: center;
      font-size: 24px;
    }
    select {
      max-width: 100%;
      width: 100%;
      padding: 16px 8px;
      font-size: 20px;
    }
    .select-wrapper {
      text-align: center;
      select {
        text-align: center;  
      }
    }
    label {
      margin-bottom: 30px;
      font-size: 26px;
      font-weight: 500;
      line-height: 1.4em;
      span {
        font-size: 16px;
        display: block;
        margin-top: -10px;
      }
    }
    .col-3, .col-6 {
      padding: 0 4px !important;
    }
    .backgroundwhite {
      background: #FFFFFF;
      border-radius: 24px;
      margin: 20px auto;
      width: 270px;
      position: relative;
      overflow: visible;
      height: 230px;
      img {
        position: absolute;
        width: 330px;
        left: 50%;
        margin-left: -165px;
        top: 0;
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
  
</style>