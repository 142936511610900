<template>
  <div class="top-template introduction">
    <img src="/img/logo_slider.png" alt="Logo Emotivapp">
    <swiper
        :modules="this.modules"
        :slides-per-view="1"
        :space-between="50"
        navigation
        :pagination="{ clickable: true }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        @touchStart="onTouchStart"
        @touchEnd="onTouchEnd"
    >
      <swiper-slide>
          <div class="slide-container">
<!--            <button class="backbutton" @click="backAction"><font-awesome-icon icon="chevron-left" /></button>-->
            <img src="/img/intro1_b.png" alt="Restez actif et épanoui au quotidien">
            <p>Restez actif(ve) et <br>épanoui(e) au quotidien</p>
          </div>
      </swiper-slide>
      <swiper-slide>
          <div class="slide-container">
            <img src="/img/intro2_b.png" alt="Maintenir la motivation, la clé pour garder le cap">
            <p>Maintenir la motivation, la <br>clé pour garder le cap</p>
          </div>
      </swiper-slide>
      <swiper-slide>
          <div class="slide-container">
<!--            <button class="nextbutton" @click="nextAction"><font-awesome-icon icon="chevron-right" /></button>-->
            <img src="/img/intro3_b.png" alt="Suivez votre activité et l’amélioration de votre santé">
            <p>Suivez votre activité et <br>l’amélioration de votre <br>santé</p>
          </div>
      </swiper-slide>
    </swiper>
<!--    <carousel :settings="settings" >-->
<!--      <slide class="intro1">-->
<!--        <div class="slide-container">-->
<!--          <button class="backbutton" @click="backAction"><font-awesome-icon icon="chevron-left" /></button>-->
<!--          <img src="/img/intro1_b.png" alt="Restez actif et épanoui au quotidien">-->
<!--          <p>Restez actif(ve) et <br>épanoui(e) au quotidien</p>-->
<!--        </div>-->
<!--      </slide>-->
<!--      <slide class="intro2">-->
<!--        <div class="slide-container">-->
<!--          <img src="/img/intro2_b.png" alt="Maintenir la motivation, la clé pour garder le cap">-->
<!--          <p>Maintenir la motivation, la <br>clé pour garder le cap</p>-->
<!--        </div>-->
<!--      </slide>-->
<!--      <slide class="intro3">-->
<!--        <div class="slide-container">-->
<!--          <button class="nextbutton" @click="nextAction"><font-awesome-icon icon="chevron-right" /></button>-->
<!--          <img src="/img/intro3_b.png" alt="Suivez votre activité et l’amélioration de votre santé">-->
<!--          <p>Suivez votre activité et <br>l’amélioration de votre <br>santé</p>-->
<!--        </div>-->
<!--      </slide>-->
<!--      <template #addons>-->
<!--&lt;!&ndash;        <navigation />&ndash;&gt;-->
<!--        <pagination />-->
<!--      </template>-->
<!--    </carousel>-->
  </div>
</template>

<script>

  // https://madewithvuejs.com/vue3-carousel 
  import 'vue3-carousel/dist/carousel.css';
  import { Swiper } from "swiper/vue/swiper";
  import { SwiperSlide } from "swiper/vue/swiper-slide";
  import { Pagination, Navigation, A11y } from 'swiper';
  
  // Import Swiper styles
  import "swiper/swiper.min.css";
  import 'swiper/modules/navigation/navigation.scss';
  import 'swiper/modules/pagination/pagination.scss';
  
  // import authentication from "@/api/authentication";
  // import backend from "@/api/backend";
  
  export default {
    name: 'Introduction',
    components: {
      // Carousel,
      // Slide,
      // Pagination,
      Swiper,
      SwiperSlide
    },
    data() {
      return {
        settings: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        currentSlide: 0,
        slideStart:0,
        slideEnd:0,
        mySlideCount:0,
        modules: [Navigation, Pagination, A11y]
      }
    },
    methods: {
      onSwiper(swiper)  {
        console.log(swiper);
        console.log(Navigation);
      },
      onSlideChange(swiper) {
        console.log(swiper.activeIndex);
        this.currentSlide = swiper.activeIndex;
      },
      navigationPrev(ev) {
        if (ev.target && ev.target.classList.contains('swiper-button-disabled') && this.mySlideCount === 0) {
          this.$router.push('/accueil');
        }
        if (this.mySlideCount > 0) {
          this.mySlideCount = this.mySlideCount - 1; 
        }
        console.log('navigationPrev ' + this.mySlideCount);
        console.log(ev);
      },
      navigationNext(ev) {
        if (ev.target && ev.target.classList.contains('swiper-button-disabled') && this.mySlideCount === 2) {
          console.log('Connexion !');
          this.$router.push({ path: '/connexion', query: { ori: 'introduction' }});
        }
        if (this.mySlideCount < 2) {
          this.mySlideCount = this.mySlideCount + 1;
        }
        console.log('navigationNext ' + this.mySlideCount);
        console.log(ev);
      },      
      onTouchStart (swiper, event) {
        console.log('onTouchStart');
        if (this.currentSlide === 2) {
          if (event.clientX) {
            this.slideStart = event.clientX; 
          }
          if (event.changedTouches && event.changedTouches[0].screenX) {
            this.slideStart = event.changedTouches[0].screenX;
          }
          console.log('start '+this.slideStart);
        }
      },
      onTouchEnd(swiper, event) {
        console.log('onTouchEnd');
        // console.log(event);
        if (this.currentSlide === 2) {
          if (event.clientX) {
            this.slideEnd = event.clientX;
          }
          if (event.changedTouches && event.changedTouches[0].screenX) {
            this.slideEnd = event.changedTouches[0].screenX;
          }
          console.log('end '+this.slideEnd);
          console.log(event);
          if (this.slideEnd < this.slideStart) {
            console.log('Inscription !');
            this.$router.push({ path: '/connexion', query: { ori: 'introduction' }});
          }
        }
      },
      backAction() {
        this.$router.push('/accueil');
      },
      nextAction() {
        localStorage.connexionInscriptionMode = 'Inscription';
        // this.$router.push('/connexion?o=fromIntro');
        this.$router.push({ path: '/connexion', query: { ori: 'introduction' }});
      },
    },
    async created () {
      localStorage.connexionInscriptionMode = 'Inscription';
    },
    mounted() {
      document.getElementsByClassName("swiper-button-prev")[0].addEventListener('click', this.navigationPrev);
      document.getElementsByClassName("swiper-button-next")[0].addEventListener('click', this.navigationNext);
    }
  }
</script>

<style lang="scss" scoped>
  .introduction {
    padding-top: 40px;
    text-align: center;
    .slide-container {
      text-align: center;
      position: relative;
      .backbutton, .nextbutton {
        position: absolute;
        left: 0;
        top: -60px;
        border-radius: 50%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        width: 36px;
        height: 36px;
        color: #898A8D;
      }
      .nextbutton {
        left: auto;
        right: 0;
        top: 0;
      }
      .backbutton {
        top: 0;
      }
      img {
        display:block;
        margin: 0 auto;
      }
      p {
        margin: 30px auto 40px;
        color: #060606;
        font-size: 30px;
        line-height: 36px;
      }
    }
  }

</style>