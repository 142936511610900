<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div class="top-template journal">
    <div class="journal-header">
      <button class="backbutton" @click="backAction"><font-awesome-icon icon="chevron-left" /></button>
      <h1>Aujourd'hui ...</h1>
      <div class="date-nav">
        <button @click="prevDay"><font-awesome-icon icon="chevron-left" /></button>
        <span>{{ currentDayFormated }}</span>
        <button v-show="isNextActive" @click="nextDay"><font-awesome-icon icon="chevron-right" /></button>
      </div>
    </div>
  </div>

  <div class="padding-wrapper">
    <div class="saisie-item saisie-activite">
      <h3>
        <a href="#" @click.prevent.stop="opener.activite = !opener.activite" class="saisie-opener" >
          <img src="/img/saisie_activite.png" alt="Activite" >
          <span>Activité</span>
          <span class="arrow-opener" v-show="!opener.activite"><font-awesome-icon icon="chevron-down"  /></span>
          <span class="arrow-opener" v-show="opener.activite"><font-awesome-icon icon="chevron-up"  /></span>
        </a>
      </h3>
      <div class="saisie-content" v-show="opener.activite">
        <div class="activites" v-for="(activite, index) in saisie.activites" :key="activite">
          <div class="row">
            <a v-show="!activite.isInPlanning" href="#" class="deleteAct" @click.prevent.stop="deleteAct(index)"><font-awesome-icon icon="xmark" /></a>
            <div class="col-4" v-show="activite.isNew">
              <Select2 v-show="activite.isNew" v-model="activite.type" :options="actTypes" :settings="{ minimumResultsForSearch: -1, placeholder: 'Activité',escapeMarkup: function (m) {return m;}, templateSelection: formatResultAct, templateResult: formatOptionAct, dropdownAutoWidth: true }" @change="updateMet($event, activite)" @select="updateMet($event, activite)" />
            </div>
            <div class="col-4" v-show="!activite.isNew">{{ activite.type }}</div>
            <div class="col-2 act-met">{{ activite.met}}<span>MET</span></div>
            <div class="col-4 act-duree">
              <vue-timepicker @input="timePickerHandler($event, index)" placeholder=" H " format="HHHmm" :close-on-complete="true" :minute-interval="5" v-model="activite.duree"></vue-timepicker>
            </div>
            <div class="col-2">
              <div v-show="activite.isInPlanning" @click="checkActivite(activite)" :class="getCkeckedClass(activite)"><font-awesome-icon icon="check" /></div>
            </div>
          </div>
          <div v-show="activite.error !== ''" class="row error">{{ activite.error }}</div>
        </div>
        <button class="btn-emot btn-emot-red" @click="addActivite" ><span><font-awesome-icon icon="plus" /></span>Ajouter une activité physique</button>
      </div>
    </div>

    <div class="saisie-item saisie-humeur">
      <h3>
        <a href="#" @click.prevent.stop="opener.humeur = !opener.humeur" class="saisie-opener" >
          <img src="/img/saisie_humeur.png" alt="Humeur" >
          <span :class="indicatorHumeur(saisie.humeur)">Humeur</span>
          <span class="arrow-opener" v-show="!opener.humeur"><font-awesome-icon icon="chevron-down"  /></span>
          <span class="arrow-opener" v-show="opener.humeur"><font-awesome-icon icon="chevron-up"  /></span>
        </a>
      </h3>
      <div class="saisie-content humeur-content" v-show="opener.humeur">
        <div class="row">
          <div class="humeur-item humeur-excellent"><a href="#" :class="getHumeurClass('excellent')" @click.prevent.stop="saisie.humeur='excellent'"><img src="/img/humeur_excellent.png" alt="Excellente humeur" >Excellent</a></div>
          <div class="humeur-item humeur-bien"><a href="#" :class="getHumeurClass('bien')" @click.prevent.stop="saisie.humeur='bien'"><img src="/img/humeur_bien.png" alt="Humeur bien" >Bien</a></div>
          <div class="humeur-item humeur-mouai"><a href="#" :class="getHumeurClass('mouai')" @click.prevent.stop="saisie.humeur='mouai'"><img src="/img/humeur_mouai.png" alt="Mouai humeur" >Mouai</a></div>
          <div class="humeur-item humeur-mauvais"><a href="#" :class="getHumeurClass('mauvais')" @click.prevent.stop="saisie.humeur='mauvais'"><img src="/img/humeur_mauvais.png" alt="Mauvaise humeur" >Mauvais</a></div>
          <div class="humeur-item humeur-tres-mauvais"><a href="#" :class="getHumeurClass('tres-mauvais')" @click.prevent.stop="saisie.humeur='tres-mauvais'"><img src="/img/humeur_tres-mauvais.png" alt="Tres Mauvaise humeur" >Très Mauvais</a></div>
          <div class="clear"></div>
        </div>
      </div>
    </div>

    <div class="saisie-item saisie-symptome">
      <h3>
        <a href="#" @click.prevent.stop="opener.symptomes = !opener.symptomes" class="saisie-opener" >
          <img src="/img/saisie_symptome.png" alt="Humeur" >
          <span :class="indicatorSymptomes(saisie.symptomes)">Symptomes</span>
          <span class="arrow-opener" v-show="!opener.symptomes"><font-awesome-icon icon="chevron-down"  /></span>
          <span class="arrow-opener" v-show="opener.symptomes"><font-awesome-icon icon="chevron-up"  /></span>
        </a>
      </h3>
      <div class="saisie-content" v-show="opener.symptomes">
        <label>Intensité de la douleur</label>
        <div class="dos-wrapper">
          <img src="/img/dos.png" alt="Douleur dos">
          <span>Dos</span>
        </div>
        <div class="slider-wrapper row">
          <div class="col-2"><button class="menus" @click.prevent.stop="saisie.symptomes.douleurDos--"><font-awesome-icon icon="minus" /></button></div>
          <div class="col-8"><Slider :format="formatToolTip" :min="-1" :max="100" v-model="saisie.symptomes.douleurDos" tooltipPosition="bottom" /></div>
          <div class="col-2"><button class="menus" @click.prevent.stop="saisie.symptomes.douleurDos++"><font-awesome-icon icon="plus" /></button></div>
        </div>
        <div class="dos-wrapper">
          <img src="/img/fesses-jambes.png" alt="Douleur fesses et jambes">
          <span>Fesses et jambes</span>
        </div>
        <div class="slider-wrapper row">
          <div class="col-2"><button class="menus" @click.prevent.stop="saisie.symptomes.douleurFessesJambes--"><font-awesome-icon icon="minus" /></button></div>
          <div class="col-8"><Slider :format="formatToolTip" :min="-1" :max="100"  v-model="saisie.symptomes.douleurFessesJambes" tooltipPosition="bottom" /></div>
          <div class="col-2"><button class="menus" @click.prevent.stop="saisie.symptomes.douleurFessesJambes++"><font-awesome-icon icon="plus" /></button></div>
        </div>
      </div>
    </div>

    <div class="saisie-item saisie-sommeil">
      <h3>
        <a href="#" @click.prevent.stop="opener.sommeil = !opener.sommeil" class="saisie-opener" >
          <img src="/img/saisie_sommeil.png" alt="Sommeil" >
          <span :class="indicatorSommeil(saisie.sommeil)">Sommeil</span>
          <span class="arrow-opener" v-show="!opener.sommeil"><font-awesome-icon icon="chevron-down"  /></span>
          <span class="arrow-opener" v-show="opener.sommeil"><font-awesome-icon icon="chevron-up"  /></span>
        </a>
      </h3>
      <div class="saisie-content" v-show="opener.sommeil">
        <div class="row">
          <div class="col-4 sommeil-bon"><a href="#" :class="getSommeilClass('bon')" @click.prevent.stop="saisie.sommeil='bon'"><img src="/img/sommeil_bon.png" alt="Bon sommeil" >Bonne nuit</a></div>
          <div class="col-4 sommeil-moyen"><a href="#" :class="getSommeilClass('moyen')" @click.prevent.stop="saisie.sommeil='moyen'"><img src="/img/sommeil_moyen.png" alt="Sommeil moyen" >Nuit moyenne</a></div>
          <div class="col-4 sommeil-mauvais"><a href="#" :class="getSommeilClass('mauvais')" @click.prevent.stop="saisie.sommeil='mauvais'"><img src="/img/sommeil_mauvais.png" alt="Mauvais sommeil" >Mauvaise nuit</a></div>
        </div>
      </div>
    </div>

    <div class="saisie-item saisie-stress">
      <h3>
        <a href="#" @click.prevent.stop="opener.stress = !opener.stress" class="saisie-opener" >
          <img src="/img/saisie_stress.png" alt="Stress" >
          <span :class="indicatorStress(saisie.stress)">Stress</span>
          <span class="arrow-opener" v-show="!opener.stress"><font-awesome-icon icon="chevron-down"  /></span>
          <span class="arrow-opener" v-show="opener.stress"><font-awesome-icon icon="chevron-up"  /></span>
        </a>
      </h3>
      <div class="saisie-content" v-show="opener.stress">
        <div class="row">
          <div class="col-4 stress-no"><a href="#" :class="getStressClass('no')" @click.prevent.stop="saisie.stress='no'"><img src="/img/stress_saisie_no.png" alt="No Stress" >No Stress</a></div>
          <div class="col-4 stress-modere"><a href="#" :class="getStressClass('modere')" @click.prevent.stop="saisie.stress='modere'"><img src="/img/stress_saisie_modere.png" alt="Stress modéré" >Modéré</a></div>
          <div class="col-4 stress-intense"><a href="#" :class="getStressClass('intense')" @click.prevent.stop="saisie.stress='intense'"><img src="/img/stress_saisie_intense.png" alt="Stress intense" >Intense</a></div>
        </div>
      </div>
    </div>

    <div class="saisie-item saisie-faitMarquant">
      <textarea v-model="saisie.faitMarquant" placeholder="Evènement important aujourd'hui ? Le fait marquant du jour..."></textarea>
    </div>
    
    <p :class="resultClass" v-show="resultTxt !== ''">{{ resultTxt }}</p>
    <button class="btn-emot fullwidth envoyerSaisie" @click.prevent.stop="submitSaisie">Enregistrer</button>
    
  </div>
  
</template>

<script>
  
  import { DateTime } from "luxon";
  import Slider from '@vueform/slider'
  import backend from "../api/backend";
  import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue'
  import Select2 from 'vue3-select2-component';
  
  export default {
    name: 'Saisie',
    components: {
      Slider,
      VueTimepicker,
      Select2
    },
    data() {
      return {
        resultClass: '',
        isLoading: true,
        resultTxt: '',
        currentDay: '',
        currentDayFormated: '',
        isNextActive: true,
        actTypes: [],
        saisie: {
          activites: [],
          humeur: '',
          symptomes: {
            douleurDos: -1,
            douleurFessesJambes: -1
          },
          sommeil: '',
          stress: '',
          faitMarquant: '',
          currentDate: null
        },
        opener: {
          activite: true,
          humeur: false,
          symptomes: false,
          sommeil: false,
          stress: false
        },
        openerActivite: true,
      }
    },
    methods: {
      backAction(){
        this.$router.push('/journal');
      },
      calculateCurrentDay() {
        this.currentDayFormated = this.currentDay.toFormat('cccc d LLLL');
        this.saisie.currentDate = this.currentDay.toFormat('yyyy-LL-dd');
      },
      formatToolTip(a) {
        console.log('formatToolTip');
        console.log(a);
        if (a === -1) {
          return "Placer le curseur"
        }
        return Math.round(a);
      },
      getCkeckedClass(activite) {
        console.log("done " + activite.done);
        if (activite.done) {
          return "checked done";
        } else {
          return "checked";
        }
      },
      checkActivite(activite) {
        activite.error = '';
        if(activite.type !== -1 
            && activite.duree.HH !== '' && activite.duree.HH !== ' ' && activite.duree.HH !== null
            && activite.duree.mm !== '' && activite.duree.mm !== ' ' && activite.duree.mm !== null) {
          activite.done = !activite.done;
        } else {
          activite.error = 'Merci de choisir une activité et une durée.'
        }
      },
      async updateDayData() {
        this.isLoading = true;
        // Remove selection of previous currentDay
        localStorage.removeItem('selectedActType-' + localStorage.currentSaisie);
        localStorage.currentSaisie = this.currentDay.toFormat('yyyy-LL-dd');

        console.log('now '+DateTime.now().toFormat('yyyy-LL-dd'));
        console.log('currentDay '+this.currentDay.toFormat('yyyy-LL-dd'));
        if (DateTime.now().toFormat('yyyy-LL-dd') <= this.currentDay.toFormat('yyyy-LL-dd')) {
          this.isNextActive = false;
        } else {
          this.isNextActive = true;
        }
        
        // get existing saisie if already exists
        const existingSaisie = await backend.getExistingSaisie(this.currentDay.toFormat('cccc'), this.currentDay.toFormat('yyyy-LL-dd'));
        if (existingSaisie !== null) {
          this.saisie.humeur = existingSaisie.humeur;
          this.saisie.symptomes = existingSaisie.symptomes;
          this.saisie.sommeil = existingSaisie.sommeil;
          this.saisie.stress = existingSaisie.stress;
          this.saisie.faitMarquant = existingSaisie.faitMarquant;
        } 
        // Empty case
        if (!this.saisie.symptomes) {
          this.saisie.symptomes =  {
            douleurDos: -1,
            douleurFessesJambes: -1
          };
        }

        // Get activity from planning
        const activitiesParJour = await backend.getActivitiesParJour(this.currentDay.toFormat('cccc'), this.currentDay.toFormat('yyyy-LL-dd'));
        if (activitiesParJour !== null) {
          this.saisie.activites = activitiesParJour;

          // Then get activity selection from local storage
          var currentSelection = localStorage.getItem('selectedActType-' + localStorage.currentSaisie);
          if (currentSelection) {
            console.log('currentSelection : ' +  currentSelection);
            var selectedActTypeArr = JSON.parse(currentSelection);
            var thisContext = this;
            selectedActTypeArr.forEach(function (selectedActTypeVal) {
              var selectedActTypeValFound = false;
              thisContext.saisie.activites.forEach(function (activite) {
                if (activite.typeId === selectedActTypeVal) {
                  selectedActTypeValFound = true;
                }
              });
              if (!selectedActTypeValFound) {
                thisContext.actTypes.forEach(function (actType) {
                  if (actType.id === selectedActTypeVal) {
                    console.log('actType = ' + actType.id);
                    thisContext.saisie.activites.push({
                      type: actType.nom,
                      typeId: actType.id,
                      met: actType.met,
                      duree: {
                        HH: '00',
                        mm: '00',
                      },
                      done: false,
                      isNew: false,
                      isInPlanning: false,
                      error: ''
                    });
                  }
                });
              }
            });
          } else {
            this.selectedActType = [];
          }
          this.isLoading = false;
        }
      },
      timePickerHandler(eventData, index) {
        this.saisie.activites[index].duree = eventData;
        console.log(eventData);
      },
      prevDay() {
        this.currentDay = this.currentDay.minus({days: 1});
        this.calculateCurrentDay();
        this.updateDayData();
      },
      nextDay() {
        this.currentDay = this.currentDay.plus({days: 1});
        this.calculateCurrentDay();
        this.updateDayData();
      },
      getHumeurClass(humeur) {
        if (this.saisie.humeur === humeur) {
          return "activeHumeur";
        }
      },
      getSommeilClass(sommeil) {
        if (this.saisie.sommeil === sommeil) {
          return "activeSommeil";
        }
      },
      getStressClass(stress) {
        if (this.saisie.stress === stress) {
          return "activeStress";
        }
      },
      async addActivite() {
        this.$router.push({ path: '/activite-physique'})
      },
      deleteAct(index) {
        console.log(this.saisie.activites[index].typeId);
        // Remove actType from localstorage
        var currentSelection = localStorage.getItem('selectedActType-' + localStorage.currentSaisie);
        if (currentSelection) {
          var selectedActTypeArr = JSON.parse(currentSelection);
          var indexActTypeId = selectedActTypeArr.indexOf(this.saisie.activites[index].typeId);
          selectedActTypeArr.splice(indexActTypeId, 1);
          localStorage.setItem('selectedActType-' + localStorage.currentSaisie, JSON.stringify(selectedActTypeArr));
        }
        // Remove from activities
        this.saisie.activites.splice(index, 1);
      },
      updateMet(evt, activite) {
        console.log('updateMet.event ' + evt);
        console.log('updateMet.activite ' + activite);
        // TODO var options = evt.target.options;
        // if (options.selectedIndex > -1) {
        //   activite.met = options[options.selectedIndex].getAttribute('data-met');
        // }
        activite.met = '3';
      },
      formatResultAct(act) {
        var out = '';
        if (act.picto != null && act.picto != '') {
          out = '<img src="'+process.env.VUE_APP_URL_API+'/uploads/picto/' + act.picto + '">';
        }
        out = out + act.nom;
        return out;
      },
      formatOptionAct(act) {
        var out = '';
        if (act.picto != null && act.picto != '') {
          out = '<img src="'+process.env.VUE_APP_URL_API+'/uploads/picto/' + act.picto + '">';
        }
        out = out + act.nom;
        return out;
      },
      indicatorHumeur(humeur) {
        if (humeur === 'excellent') return "indicator indicator-green"; 
        if (humeur === 'bien') return "indicator indicator-orange"; 
        if (humeur === 'mouai') return "indicator indicator-orange"; 
        if (humeur === 'mauvais') return "indicator indicator-red";
        if (humeur === 'tres-mauvais') return "indicator indicator-redred";
      },
      indicatorSommeil(sommeil) {
        if (sommeil === 'bon') return "indicator indicator-green";
        if (sommeil === 'moyen') return "indicator indicator-orange";
        if (sommeil === 'mauvais') return "indicator indicator-red";
      },
      indicatorStress(stress) {
        if (stress === 'no') return "indicator indicator-green";
        if (stress === 'modere') return "indicator indicator-orange";
        if (stress === 'intense') return "indicator indicator-red";
      },
      indicatorSymptomes(symptomes) {
        if (symptomes.douleurDos === -1 && symptomes.douleurFessesJambes === -1) {
          return "";
        }
        let pireSymptome = symptomes.douleurDos > symptomes.douleurFessesJambes ? symptomes.douleurDos : symptomes.douleurFessesJambes;
        if (pireSymptome > 33 && pireSymptome < 67) {
          return "indicator indicator-orange";
        }
        if (pireSymptome <= 33) {
          return "indicator indicator-green";
        }
        if (pireSymptome >= 67) {
          return "indicator indicator-red";
        }
      },
      async submitSaisie() {
        if (this.saisie.activites.length === 0 
            && this.saisie.symptomes.douleurDos === -1
            && this.saisie.symptomes.douleurFessesJambes === -1
            && (!this.saisie.humeur)
            && (!this.saisie.sommeil)
            && (!this.saisie.stress)
            && (!this.saisie.faitMarquant)
            ) {
          this.resultClass = "resultTxt error";
          this.resultTxt = "Aucune donnée saisie..";
        } else {
          this.isLoading = true;
          // loader.show
          const submitSaisieResult = await backend.submitSaisie(this.saisie);
          console.log('submitSaisieResult : ' + submitSaisieResult);
          if (submitSaisieResult && submitSaisieResult.result === 'ok') {
            this.resultTxt = "Saisie enregistrée avec succès !";
            this.resultClass = "resultTxt success";
            this.isLoading = false;
            localStorage.removeItem('selectedActType-' + localStorage.currentSaisie);
            // localStorage.removeItem('currentSaisie');
            setTimeout( () => { this.resultTxt=""; this.$router.push('/journal') }, 1000);
          } else {
            if (submitSaisieResult.errors) {
              this.resultTxt = submitSaisieResult.errors;
            } else {
              this.resultTxt = "Erreur lors de la soumission des données";
            }
            this.resultClass = "resultTxt error";
            this.isLoading = false;
          }
        }
      }
      
    },
    async created () {
      // Authenticate
      // const isAuthenticate = await authentication.isAuthenticate();
      // console.log('isAuthenticate : '+isAuthenticate);
      // if (isAuthenticate) {
      //   if (!localStorage.initialFormDone) {
      //     this.$router.push('formulaire-debut');
      //   } 
      // }
    },
    async mounted() {

      console.log("Param Query " + this.$route.query.currentday);
      console.log("localStorage.currentSaisie " + localStorage.currentSaisie);
      if (localStorage.currentSaisie) {
        this.currentDay = DateTime.fromISO(localStorage.currentSaisie).setLocale('fr');
      } else if (this.$route.query.currentday) {
        this.currentDay = DateTime.fromISO(this.$route.query.currentday).setLocale('fr');
      } else {
        this.currentDay = DateTime.now().setLocale('fr'); 
      }
      this.calculateCurrentDay();

      console.log('now '+DateTime.now().toFormat('yyyy-LL-dd'));
      console.log('currentDay '+this.currentDay.toFormat('yyyy-LL-dd'));
      if (DateTime.now().toFormat('yyyy-LL-dd') <= this.currentDay.toFormat('yyyy-LL-dd')) {
        this.isNextActive = false;
      } else {
        this.isNextActive = true;
      }
      
      // Get acttype for select box
      if (localStorage.actTypes) {
        this.actTypes = JSON.parse(localStorage.actTypes);
      } else {
        // Get acttype from server
        const actTypesFromApi = await backend.getActTypesValues();
        if (actTypesFromApi !== null) {
          this.actTypes = actTypesFromApi;
          localStorage.actTypes = JSON.stringify(actTypesFromApi);
        }
      }

      localStorage.currentSaisie = this.currentDay.toFormat('yyyy-LL-dd');
      
      // get existing saisie if already exists
      const existingSaisie = await backend.getExistingSaisie(this.currentDay.toFormat('cccc'), this.currentDay.toFormat('yyyy-LL-dd'));
      if (existingSaisie !== null) {
        this.saisie.humeur = existingSaisie.humeur;
        this.saisie.symptomes = existingSaisie.symptomes;
        this.saisie.sommeil = existingSaisie.sommeil;
        this.saisie.stress = existingSaisie.stress;
        this.saisie.faitMarquant = existingSaisie.faitMarquant;
      }
      // Empty case
      if (!this.saisie.symptomes) {
        this.saisie.symptomes =  {
              douleurDos: -1,
              douleurFessesJambes: -1
        };
      }
      
      // Get activity from planning
      const activitiesParJour = await backend.getActivitiesParJour(this.currentDay.toFormat('cccc'), this.currentDay.toFormat('yyyy-LL-dd'));
      if (activitiesParJour !== null) {
        this.saisie.activites = activitiesParJour;
        
        // Then get activity selection from local storage
        var currentSelection = localStorage.getItem('selectedActType-' + localStorage.currentSaisie);
        if (currentSelection) {
          console.log('currentSelection : ' +  currentSelection);
          var selectedActTypeArr = JSON.parse(currentSelection);
          var thisContext = this;
          selectedActTypeArr.forEach(function (selectedActTypeVal) {
            var selectedActTypeValFound = false;
            thisContext.saisie.activites.forEach(function (activite) {
              if (activite.typeId === selectedActTypeVal) {
                selectedActTypeValFound = true;
              }
            });
            if (!selectedActTypeValFound) {
              thisContext.actTypes.forEach(function (actType) {
                if (actType.id === selectedActTypeVal) {
                  console.log('actType = ' + actType.id);
                  thisContext.saisie.activites.push({
                    type: actType.nom,
                    typeId: actType.id,
                    met: actType.met,
                    duree: {
                      HH: '00',
                      mm: '00',
                    },
                    done: false,
                    isNew: false,
                    isInPlanning: false,
                    error: ''
                  });
                }
              });
            }
            // check if planning act are store in selectedActTypeArr TODO à terminer
            // activitiesParJour.forEach(function (activitiesParJourVal) {
            //   if (selectedActTypeArr.indexOf(activitiesParJourVal.typeId) === -1) {
            //     selectedActTypeArr.push(activitiesParJourVal.typeId);
            //   }
            // });
            // localStorage.setItem('selectedActType-' + localStorage.currentSaisie, JSON.stringify(selectedActTypeArr));
          });
        } else {
          this.selectedActType = [];
        }
        // 
        
        this.isLoading = false;
        // this.actTypes = actTypesFromApi;
        // activitiesParJour.forEach(function (value, index) {
        //   this.saisie.activites.push({
        //     type: value.activiteType,
        //     met: value.met,
        //     duree: {
        //       HH: value.duree.HH,
        //       mm: value.duree.mm,
        //     },
        //     done: value.done,
        //     isNew: false,
        //     erreur: ''
        //   });
        //   console.log('index: ' + index + ', value: ' + value.actType);
        // });
        
      } 
      
      // this.saisie.activites.push({
      //   type: 'Etirement',
      //   met: 4,
      //   duree: {
      //     HH: '01',
      //     mm: '30',
      //   },
      //   isNew: false,
      //   erreur: ''
      // });
      // this.saisie.activites.push({
      //   type: 'Vélo d\'appartement',
      //   met: 4,
      //   duree: {
      //     HH: '00',
      //     mm: '45',
      //   },
      //   isNew: false,
      //   erreur: ''
      // });
    }
  }
</script>

<style lang="scss" scoped>
  body {
    .top-template {
      position: absolute;
      top:0;
      left:0;
      right: 0;
      z-index: 99;
      background: #E5E5E5;
      bottom:0;
    }
    .journal-header {
      background: url('../../public/img/bg.png') no-repeat center top;
      background-size: 100% auto;
      margin: 0;
      position: absolute;
      top:0;
      left:0;
      right: 0;
      height: 327px;
      color: #EDF9FF;
      .backbutton {
        position: absolute;
        left: 15px;
        top: 15px;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        font-size: 13px;
        color: #0472A8;
        background: #FFF;
        padding-top: 2px;
        border: 1px solid #0472A8; 
      }
      h1 {
        margin: 40px auto 30px !important;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #EDF9FF;
      }
      .date-nav {
        button, span {
          color: #EDF9FF;
        }
        span {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .padding-wrapper {
      padding: 0;
      background: transparent;
      position: relative;
      z-index: 999;
      width: calc(100% - 42px);
      margin: 135px auto 0;
      border-radius: 8px;
    }
    .date-nav {
      text-align: center;
      color: #FFF;
      span {
        margin: 0 7px;
      }
      button {
        color: #FFF;
        border: 1px solid #FFF;
        width: 27px;
        height: 27px;
        display: inline-block;
        border-radius: 50%;
        font-size: 12px;
      }
    }
    .saisie-item {
      background: #FFFFFF;
      box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      margin: 10px 0;
      padding: 15px;
      h3 {
        color: #000000;
        font-weight: 500;
        font-size: 18px;
        text-align: left;
        margin-bottom: 0;
        a {
          color: #000000;
          display: block;
          position: relative;
          width: 100%;
          img {
            margin: 0 15px 0 0;
          }
          span.indicator:after {
            content: " ";
            width: 11px;
            height: 11px;
            margin-left: 10px;
            border-radius: 50%;
            display: inline-block;
          }
          span.indicator-green:after {
            background: #699A37;
          }
          span.indicator-orange:after {
            background: #ECBA6D;
          }
          span.indicator-red:after {
            background: #E08599;
          }          
          span.indicator-redred:after {
            background: #C95470;
          }
          .arrow-opener {
            position: absolute;
            right: 10px;
            top: -4px;
            width: 30px;
            height: 30px;
            border: 0.4px solid #DADADA;
            color: #565555;
            border-radius: 50%;
            font-size: 13px;
            text-align: center;
            padding-top: 7px;
          }
        }
      }
      .saisie-content {
        padding-top: 20px;
      }
      .activites {
        .row {
          padding: 10px 0;
          margin-bottom: 10px;
          border-bottom: 1px #EAEAEA solid;
        }
        .col-4 {
          color: #263238;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }
        .col-2 {
          color: #0472A8;
          font-weight: 500;
          font-size: 16px;
          span {
            font-size: 12px;
            margin-left: 3px;
            font-weight: normal;
            color: #565555;
          }
        }
        .activite-duree {
          position: relative;
          top: -7px;
          input {
            border: 0.4px solid #D2D2D2;
            border-radius: 8px;
            padding: 8px 12px;
            color: #0472A8;
            font-weight: 500;
            font-size: 16px;
          }
          span {
            font-size: 12px;
            margin-left: 3px;
            font-weight: normal;
            color: #565555;
            position: absolute;
            right:10px;
            top: 10px;
          }
        }
        .checked {
          width: 31px;
          height: 31px;
          color: #969696;
          background: #DADADA;
          text-align: center;
          padding-top: 5px;
          cursor: pointer ;
          font-size: 13px;
          border-radius: 50%;
          &.done {
            background: #0C86C2;
            color: #FFF;
          }
        }
      }
     &.saisie-activite {
       .activites {
         background: #FFFFFF;
         margin: 10px 0;
         padding: 0;
         /*height: 126px;*/
         position: relative;
       }
       
       .error {
         font-size: 13px;
         line-height: 1.3em;
         margin-top: 5px;
       }
       a.deleteAct {
         position: absolute;
         right: 10px;
         top: 10px;
         border: 1px solid #E7E7E7;
         width: 28px;
         height: 28px;
         display: block;
         font-size: 13px;
         text-align: center;
         border-radius: 50%;
         padding: 3px 0 0;
       }
       select.typeSelect {
         background: #FFF;
         border: 0.4px solid #D0D0D0;
         border-radius: 8px;
         color: #565555;
         padding: 13px;
         max-width: 100%;
       }
       .act-met {
         color: #0472A8;
         font-weight: 600;
         font-size: 16px;
         font-family: 'Lato', sans-serif;
         padding-top: 5px;
         span {
           font-size: 12px;
           margin-left: 3px;
           font-weight: normal;
           color: #565555;
         }
       }
       .act-duree {
         position: relative;
         span.unit {
           position: absolute;
           right: 20px;
           top: 11px;
           font-size: 10px;
           margin-left: 3px;
           font-weight: normal;
           color: #565555;
         }
       }       
       button.btn-emot-red {
         font-family: 'Lato';
         font-style: normal;
         font-weight: 700;
         font-size: 18px;
         line-height: 22px;
         margin: 0 auto;
         display: block;
         position: relative;
         width: 100%;
         span {
           left: 25px;
           width: 25px;
           height: 25px;
           margin-right: 10px;
           display: inline-block;
           font-size: 13px;
           border: 0.4px solid #FFFFFF;
           border-radius: 50%;
           text-align: center;
           padding-left: 1px;
         }
       }
     } 
      &.saisie-humeur {
        .saisie-content {
          max-width: 100%;
          overflow: scroll;
          .row {
            width: 146%;
            padding-bottom: 15px;
            .humeur-item {
              float:left;
              width: 20%;
              margin-right: 0;
            }
          }
          .col-3 {
            padding: 0;
          }
          a {
            transition: all 0.1s ease-in-out;
            text-align: center;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #9E9E9E;
            display: inline-block;
            padding: 10px;
            max-width: 100%;
            &.activeHumeur {
              border: 3px solid #ECBA6D;
              border-radius: 17px;
            }
            img {
              margin-bottom: 10px;
            }
          }
          .humeur-excellent a.activeHumeur {
            border-color: #9AB87C;
          }
          .humeur-bien a.activeHumeur {
            border-color: #ECBA6D;
          }
          .humeur-mouai a.activeHumeur {
            border-color: #ECBA6D;
          }
          .humeur-mauvais a.activeHumeur {
            border-color: #E08599;
          }
          .humeur-tres-mauvais a.activeHumeur {
            border-color: #C95470;
          }
        }
      }
      &.saisie-symptome {
        label {
          color: #888888;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          padding-left: 40px;
          margin-bottom: 30px;
        }
      }
      &.saisie-sommeil {
        .saisie-content {
          a {
            transition: all 0.1s ease-in-out;
            text-align: center;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #9E9E9E;
            display: inline-block;
            padding: 10px;
            &.activeSommeil {
              border: 3px solid #ECBA6D;
              border-radius: 17px;
            }
            img {
              margin-bottom: 10px;
            }
          }
          .sommeil-bon a.activeSommeil {
            border-color: #9AB87C;
          }
          .sommeil-moyen a.activeSommeil {
            border-color: #EDBE88;
          }
          .sommeil-mauvais a.activeSommeil {
            border-color: #E08599;
          }
        }        
      }
      &.saisie-stress {
        .saisie-content {
          a {
            transition: all 0.1s ease-in-out;
            text-align: center;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #9E9E9E;
            display: inline-block;
            padding: 10px;
            &.activeStress {
              border: 3px solid #ECBA6D;
              border-radius: 17px;
            }
            img {
              margin-bottom: 10px;
            }
          }
          .stress-no a.activeStress {
            border-color: #9AB87C;
          }
          .stress-modere a.activeStress {
            border-color: #EDBE88;
          }
          .stress-intense  a.activeStress {
            border-color: #E08599;
          }
        }
      }
      &.saisie-faitMarquant {
        textarea {
          border: none;
          border-radius: 11px;
          padding: 5px 10px 5px 50px;
          color: #7B7B7B;
          background: #E7E7E7 url('../../public/img/faitMarquant.png') no-repeat 18px 10px;
        }
      }
    }
    textarea::-webkit-input-placeholder { /* Edge */
      color: #7B7B7B;
    }

    textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #7B7B7B;
    }

    textarea::placeholder {
      color: #7B7B7B !important;
    }
    button.envoyerSaisie {
      margin-top: 30px;
    }
    p.resultTxt {
      margin: 30px 0 0;
    }
  }

</style>