<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div class="grey-wrapper">
  <div class="top-template journal">
    <div class="animation" v-show="showAnimation">
      <img src="/img/animation_star2.gif" alt="Animation">
    </div>
    <div class="journal-header">
      <h1>
<!--        <img src="/img/logo_white.png" alt="Logo Emotivapp">-->
        <span>Activité</span>
      </h1>
<!--      <img class="homme-icon" src="/img/homme.png" alt="Homme">-->
      <img class="homme-icon" :src="hommeImg" alt="Homme">
      <div class="header-icon humeur-icon"><img  :src="getIconState('/img/header-journal/humeur', iconsActif.humeur)" alt="Humeur"><span>Humeur</span></div>
      <div class="header-icon symptomes-icon"><img :src="getIconState('/img/header-journal/symptomes', iconsActif.symptomes)" alt="Symptomes"><span>Symptômes</span></div>
      <div class="header-icon stress-icon"><img :src="getIconState('/img/header-journal/stress', iconsActif.stress)" alt="Stress"><span>Stress</span></div>
      <div class="header-icon sommeil-icon"><img :src="getIconState('/img/header-journal/nuit', iconsActif.sommeil)" alt="Sommeil"><span>Sommeil</span></div>
      <div class="date-nav">
        <button @click="prevWeek"><font-awesome-icon icon="chevron-left" /></button>
        <span>{{ currentWeek }}</span>
        <button @click="nextWeek"><font-awesome-icon icon="chevron-right" /></button>
      </div>
    </div>
  </div>

  <div class="padding-wrapper">
    <div class="objectifs">
      <h4>Objectifs hebdo</h4>
      <span class="obj-total"><span class="obj-progress" :style="styleObjBar"></span></span><img src="/img/coupe.png" alt="Objectifs"><span class="obj-txt-total"><span class="obj-txt">{{ objectif }}</span>/{{ objectifTotal }}<span class="obj-unit">Pts</span></span>
    </div>

    <div class="repondez-questionnaire" v-show="needFormHad || needFormComi || needFormIMC">
      <div class="alarme-wrapper">
        <img src="/img/alarme.png" alt="Alarme">
      </div>
      <div class="row">
        <div class="col-9">
          <h3>"{{ nomPatient }}, pour améliorer<br> votre coaching <span>répondez<br> au questionnaire</span>"</h3>
          <button @click="goQuestionnaire">J'y vais !</button>
        </div>
        <div class="col-3">
          <img class="petitsPoints" src="/img/petitsPoints.png" alt="Petits points">
          <img class="femme" src="/img/journal-femme.png" alt="Femme coach">
        </div>
      </div>
    </div>

    <div class="main-calendar">
      <ul>
        <li><button @click="prevWeek"><font-awesome-icon icon="chevron-left" /></button></li>
        <li><button @click="nextWeek"><font-awesome-icon icon="chevron-right" /></button></li>
      </ul>
      <div class="days">
        <div class="calendar-day" v-for="(day, index) in weekDays" :key="day">
          <span class="day-header">{{ getDayLetter(index) }}</span>
          <span :class="dayNbClass(index, day)">{{ day }}</span>
          <div :class="getPointContainerClass(index, day)">
            <img src="/img/medaille.png" alt="Médaille" v-show="hasMedaille(day)">
            <img src="/img/medaille_grey.png" alt="Médaille" v-show="hasGreyMedaille(day)">
            <div :class="getPointsClass(day)">{{ getResultByDay(day) }}<span v-show="getResultByDay(day) !== ''">pts</span></div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>   
    
    <div class="faireBilan">
      <img class="points-verts" src="/img/journal_pointsVert.png" alt="Points verts">
      <div class="row">
        <div class="col-3">
          <img src="/img/journal-homme.png" alt="Homme">
        </div>
        <div class="col-9">
          <h3>Faites le bilan<br>avec votre<br><span>EMOTI'coach !</span></h3>
          <img class="petitsPoints" src="/img/petitsPoints.png" alt="Petits points">
        </div>
      </div>
    </div>
    <div class="bilan-wrapper">
      <div class="arrow-down"></div> 
      <div class="trait-droit"></div>
      <div class="row bilan-labels">
        <div class="col-3"></div>
        <div class="col-3 text-center"><span class="intense">Intense</span></div>
        <div class="col-3 text-right"><span class="modere">Modéré</span></div>
        <div class="col-3 text-center"><span class="leger">Léger</span></div>
      </div>
      <div class="activite-item" v-for="(saisie) in saisies" :key="saisie">
        <div class="act-date">{{ saisie.date }}</div>
        <div class="act-link"><button v-show="saisie.isEditable" @click="openSaisie(saisie)" ><font-awesome-icon icon="chevron-right" /></button></div>
        <div class="row act-line-wrapper" :class="activiteLineClass(activite)" v-for="(activite) in saisie.activites" :key="activite">
          <div class="col-6">
            <div class="act-line act-type"><span>{{ activite.activiteLabel }}</span></div>
          </div>
          <div class="col-3">
            <div class="act-line act-met"><span class="middle-vert"> {{ activite.met }} <span>MET</span></span></div>
          </div>
          <div class="col-3">
            <div class="act-line act-duree"><span class="middle-vert">{{ activite.duree.HH }}H{{ activite.duree.mm }}</span></div>
          </div>
        </div>
        <div class="row act-res-wrapper">
          <div class="col-3">
            <div :class="getNiveauHumeurClass(saisie)"><img :src="getNiveauHumeurSrc(saisie)" :alt="saisie.humeur"><div>{{ saisie.humeur }}</div></div>
          </div>
          <div class="col-3">
            <div class="act-res act-resDouleur"><img :src="getNiveauDouleurSrc(saisie)" :alt="saisie.niveauDouleur"><div>{{ saisie.niveauDouleur }}</div></div>
          </div>
          <div class="col-3">
            <div class="act-res act-resNuit"><img :src="getNiveauNuitSrc(saisie)" :alt="saisie.niveauNuit"><div>{{ saisie.niveauNuit }}</div></div>
          </div>
          <div class="col-3">
            <div class="act-res act-resStress"><img :src="getNiveauStressSrc(saisie)" :alt="saisie.niveauStress"><div>{{ saisie.niveauStress }}</div></div>
          </div>
        </div>
      </div>
    </div>
    
  </div>  
  </div>
  <emotiv-footer activeItem="journal"></emotiv-footer>
  
</template>

<script>

  import emotivFooter from "../components/EmotivFooter";
  import backend from "../api/backend";
  import { DateTime } from "luxon";
  
  export default {
    name: 'Journal',
    components: {
      emotivFooter
    },
    data() {
      return {
        idPatient: null,
        nomPatient: '',
        isLoading: true,
        hasError: false,
        errorTxt: null,
        showAnimation: false,
        currentWeek: '',
        
        startWeek: null,
        endWeek: null,
        weekDays: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: ''
        },
        humeurIcon: '/img/header-journal/humeurOff.svg',
        symptomeIcon: '/img/header-journal/symptomesOff.svg',
        stressIcon: '/img/header-journal/stressOff.svg',
        sommeilIcon: '/img/header-journal/nuitOff.svg',
        saisieDays: [],
        objectifRealiseDays: [],
        objectifDaysMet: [],
        resultDays: [],
        objectif: 0,
        objectifTotal: 0,
        styleObjBar: 'width: 10%;',
        nbQuestionnaireLink: '1',
        hommeImg : "/img/homme_0.svg",
        needFormHad: false,
        needFormComi: false,
        needFormIMC: false,
        iconsActif: {
          humeur: false,
          symptomes: false,
          stress: false,
          sommeil: false
        },
        saisies: [
          {
            date: 'Samedi 11 déc.',
            dateIso : '',
            activites: [
              {
                activiteLabel: 'MMA (mixed martial arts)',
                met: '9',
                duree: '30:00',  
              }
            ],
            humeur: 'Mouai',
            humeurClass: 'mouai',
            niveauDouleur: 'Douleur modéré',
            niveauDouleurClass: 'modere',
            niveauStress: 'Stress modéré',
            niveauStressClass: 'modere',
            niveauNuit: 'Mauvaise nuit', 
            niveauNuitClass: 'mauvaise', 
          }
        ],
      }
    },
    methods: {
      getIconState(path, state) {
        return path+(state?'On.svg':'Off.svg');
      },
      getHommeImg() {
        var iconsActifs = 0;
        if (this.iconsActif.humeur) iconsActifs++; 
        if (this.iconsActif.sommeil) iconsActifs++; 
        if (this.iconsActif.symptomes) iconsActifs++; 
        if (this.iconsActif.stress) iconsActifs++;
        return "/img/homme_"+iconsActifs+".svg";
      },
      calculateCurrentWeek() {
        let out = 'Du '+ this.startWeek.toObject().day;
        if (this.startWeek.toFormat('LLLL') !== this.endWeek.toFormat('LLLL')) {
          out = out +  ' ' + this.startWeek.toFormat('LLLL');
        }
        this.currentWeek = out + ' au ' + this.endWeek.toObject().day + ' ' + this.endWeek.toFormat('LLLL');
      },
      updateWeekEvents() {
        let weekDays = this.weekDays;
        weekDays[0] = this.startWeek.toFormat('d');
        let tmpDay = DateTime.fromObject(this.startWeek.toObject());
        let weekDaysNb = [1,2,3,4,5,6];
        weekDaysNb.forEach(function (value) {
          tmpDay = tmpDay.plus({days: 1});
          weekDays[value]= tmpDay.toFormat('d');
        });
      },
      getDayLetter(indexDay) {
        console.log('indexDay: ' + indexDay);
        if (indexDay === '0') return "L";
        if (indexDay === '1') return "M";
        if (indexDay === '2') return "M";
        if (indexDay === '3') return "J";
        if (indexDay === '4') return "V";
        if (indexDay === '5') return "S";
        if (indexDay === '6') return "D";
      },
      getResultByDay(day) {
        if (this.resultDays[day]) {
          return this.resultDays[day];
        } else if (this.objectifDaysMet[day]) {
          return this.objectifDaysMet[day];
        } else {
          return "";
        }
      },
      dayNbClass(indexDay, day) {
        let classDay = 'day-nb';
        if (day in this.objectifDaysMet || this.saisieDays.includes(day)) {
          classDay = classDay + ' hasPoints ';
        }
        const nowTime = DateTime.now().setLocale('fr');
        if (this.weekDays[indexDay] === nowTime.toFormat('d')) {
          classDay = classDay + ' isToday ';
        }
        return classDay;
      },
      hasMedaille(day) {
        return this.objectifRealiseDays.includes(day)
      },
      hasGreyMedaille(day) {
        return ((!this.hasMedaille(day)) && (day in this.objectifDaysMet))
      },
      getPointContainerClass(day) {
        let pointContainerClass = 'point-container';
        if (this.hasMedaille(day)) {
          pointContainerClass = pointContainerClass + ' todayPoint ';
        }
        return pointContainerClass;
      },
      getPointsClass(day) {
        let pointClass = 'points';
        if (this.hasMedaille(day)) {
          pointClass = pointClass + ' pointsValide ';
        }
        return pointClass;
      },
      prevWeek() {
        this.startWeek = this.startWeek.minus({weeks: 1});
        this.endWeek = this.endWeek.minus({weeks: 1});
        this.calculateCurrentWeek();
        this.updateWeekEvents();
        this.updateWeekData();
      },
      nextWeek() {
        this.startWeek = this.startWeek.plus({weeks: 1});
        this.endWeek = this.endWeek.plus({weeks: 1});
        this.calculateCurrentWeek();
        this.updateWeekEvents();
        this.updateWeekData();
      },
      openSaisie(saisie) {
        this.$router.push("/saisie?currentday="+saisie.dateIso);
      },
      getNbQuestionnaireLink() {
        var ret = 0;
        if (this.needFormHad) {
          ret = 1;
        }
        if (this.needFormComi) {
          ret++;
        }
        if (this.needFormIMC) {
          ret++;
        }
        return ret;
      },
      goQuestionnaire() {
        if (this.needFormHad) {
          this.$router.push('/formulaire-had');
        } else if (this.needFormComi) {
          this.$router.push('/formulaire-comi');
        } else if (this.needFormIMC) {
          this.$router.push('/formulaire-imc');
        }
      },
      getNiveauHumeurClass(saisie) {
        return "act-res act-resHumeur act-resHumeur-" + saisie.humeurClass;
      },      
      getNiveauHumeurSrc(saisie) {
        return "img/humeur_" + saisie.humeurClass + ".png";
      },
      getNiveauDouleurSrc(saisie) {
        return "img/douleur_" + saisie.niveauDouleurClass + ".png";
      },
      getNiveauStressSrc(saisie) {
        return "img/stress_" + saisie.niveauStressClass + ".png";
      },
      getNiveauNuitSrc(saisie) {
        return "img/nuit_" + saisie.niveauNuitClass + ".png";
      },
      calculateHommeImg(pourc) {
        if (pourc === 0) {
          this.hommeImg = "/img/homme_0.svg";
        } else if (pourc <= 25) {
          this.hommeImg = "/img/homme_1.svg";
        } else if (pourc <= 50) {
          this.hommeImg = "/img/homme_2.svg";
        } else if (pourc <= 75) {
          this.hommeImg = "/img/homme_3.svg";
        } else if (pourc <= 100) {
          this.hommeImg = "/img/homme_4.svg";
        }
      },
      activiteLineClass(act) {
        if (!act.done) {
          return " not-done ";
        }
        return "";
      },
      async updateWeekData() {
        this.isLoading = true;
        const journalData = await backend.getJournalData(this.startWeek.toISODate(), this.endWeek.toISODate());
        if (journalData !== null) {
          console.log('reponse dans le journal ', journalData);
          this.saisies = journalData.saisies;
          this.plannings = journalData.plannings;
          this.objectif = journalData.objectif;
          this.objectifTotal = journalData.objectifTotal;
          this.iconsActif = journalData.iconsActifs;
          this.saisieDays = journalData.saisieDays;
          this.resultDays = journalData.resultDays;
          this.objectifRealiseDays = journalData.objectifRealiseDays;
          this.objectifDaysMet = journalData.objectifDaysMetReturned;
          this.needFormHad = journalData.needFormHad;
          this.needFormComi = journalData.needFormComi;
          this.needFormIMC = journalData.needFormIMC;
          this.nomPatient = journalData.nomPatient;
          var pourcentageObj = (100 * this.objectif / this.objectifTotal);
          if (pourcentageObj > 100) {
            pourcentageObj = 100;
          }
          this.styleObjBar = 'width: ' + pourcentageObj + '%;';
          this.calculateHommeImg(pourcentageObj);
          this.isLoading = false;
          if (journalData.hasAnimation) {
            this.showAnimation = true;
            setTimeout(() => {this.showAnimation = false}, 3000);
          }
        }
      },
    },
    async mounted() {
      const formInitDone = await backend.isFormInitDone();
      if (formInitDone != null) {
        if (formInitDone.needFormInit) {
          this.$router.push('/formulaire-debut');
        }
      }
      const nowTime = DateTime.now().setLocale('fr');
      if (localStorage.currentSaisie) {
        var currentDay = DateTime.fromISO(localStorage.currentSaisie).setLocale('fr');
        this.startWeek = currentDay.startOf('week');
        this.endWeek = currentDay.endOf('week');
      } else {
        this.startWeek = nowTime.startOf('week');
        this.endWeek = nowTime.endOf('week');
      }
      // Reinit saisie parameters
      localStorage.removeItem('selectedActType-' + localStorage.currentSaisie);
      localStorage.removeItem('currentSaisie');
      
      this.calculateCurrentWeek();
      this.updateWeekEvents();
      
      const journalData = await backend.getJournalData(this.startWeek.toISODate(), this.endWeek.toISODate());
      if (journalData !== null) {
        console.log('reponse dans le journal ', journalData);
        this.saisies = journalData.saisies;
        this.plannings = journalData.plannings;
        this.objectif = journalData.objectif;
        this.objectifTotal = journalData.objectifTotal;
        this.iconsActif = journalData.iconsActifs;
        this.saisieDays = journalData.saisieDays;
        this.resultDays = journalData.resultDays;
        this.objectifRealiseDays = journalData.objectifRealiseDays;
        this.objectifDaysMet = journalData.objectifDaysMetReturned;
        this.needFormHad = journalData.needFormHad;
        this.needFormComi = journalData.needFormComi;
        this.needFormIMC = journalData.needFormIMC;
        this.nomPatient = journalData.nomPatient;
        var pourcentageObj = (100 * this.objectif / this.objectifTotal);
        if (pourcentageObj > 100) {
          pourcentageObj = 100;
        }
        this.styleObjBar = 'width: ' + pourcentageObj + '%;';
        this.calculateHommeImg(pourcentageObj);
        this.isLoading = false;
        if (journalData.hasAnimation) {
          this.showAnimation = true;
          setTimeout(() => {this.showAnimation = false}, 3000);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  body {
    background: rgba(229, 229, 229, 0.5) !important;

    .top-template.journal {
      padding-left: 0;
      padding-right:0;
      background: #087db7;
      //border-bottom-left-radius: 400px;
      //border-bottom-right-radius: 400px;
      border-radius: 0px 0px 400px 400px / 0px 0px 70px 70px;
      padding-bottom: 30px;
    }
    .animation {
      position: absolute;
      top: 0;
      z-index: 999999;
      background: #FFF;
      left: 0;
      right: 0;
      text-align: center;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      img {
        max-width: 250px;
      }
    }
    .journal-header {
      // background: url('../../public/img/bgJournal.png') no-repeat center top;
      background: url('../../public/img/bgJournal768.png') no-repeat center top;
      background-size: cover;
      padding-top: 10px;
      height: 417px;
      width: 410px;
      margin: 0 auto;
      position: relative;
      h1 {
        text-align: center;
        margin-top: 142px !important;
        margin-bottom: 0 !important;
        span {
          display: block;
          text-align: center;
          font-size: 12px;
          font-weight: normal;
          color: #FFF;
        }
      }
    }
    .padding-wrapper {
      padding: 0 21px;
    }
    .header-icon {
      text-align: center;
      span {
        color: #FFF;
        font-size: 12px; 
        display: block;
      }
    }
    .homme-icon {
      position: absolute;
      bottom: 82px;
      left: 50%;
      margin-left: -113px;
      width: 226px;
      height: 144px;
    }
    .humeur-icon {
      position: absolute;
      left: 3px;
      bottom: 140px;
    }
    .symptomes-icon {
      position: absolute;
      left: 50px;
      bottom: 233px;
    }
    .stress-icon {
      position: absolute;
      right: 14px;
      bottom: 140px;
    }
    .sommeil-icon {
      position: absolute;
      right: 68px;
      bottom: 233px;
    }
    .date-nav {
      text-align: center;
      color: #FFF;
      position: absolute;
      bottom: 35px;
      left: 50%;
      margin-left: -50%;
      width: 100%;
      span {
        margin: 0 7px;
      }
      button {
        color: #FFF;
        border: 1px solid #FFF;
        width: 27px;
        height: 27px;
        display: inline-block;
        border-radius: 50%;
        font-size: 12px;
      }
    }
    .objectifs {
      h4 {
        font-size: 13px;
        color: #8E8D8D;
        font-weight: 400;
        margin: 20px 0 5px;
      }
      .obj-total {
        position: relative;
        display: block;
        height: 12px;
        width: 60%;
        background: #D6D6D6;
        border-radius: 26px;
        border: 1px solid #FFFF;
        float: left;
        .obj-progress {
          background: #0C86C2;
          height: 12px;
          display: block;
          position: absolute;
          left:0;
          top:0;
          border-radius: 26px;
          border: 1px solid #FFFF;
        }
      }
      .obj-txt-total {
        font-family: 'Lato', sans-serif;
        color: #D6D6D6;
        position: relative;
        top: -8px;
        font-weight: 600;
        font-size: 14px;
        .obj-txt {
          color: #0C86C2;
        } 
        .obj-unit {
          font-size: 12px;
        }
      }
      img {
        position: relative;
        top: -11px;
        margin: 0 7px;
      }
    }
    .repondez-questionnaire {
      margin-top: 28px;
      background: #E08599;
      padding: 30px 20px;
      text-align: center;
      box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.19);
      border-bottom-left-radius: 30px;
      border-top-right-radius: 30px;
      position: relative;
      .alarme-wrapper {
        position: absolute;
        top: -15px;
        left: -15px;
        z-index: 999;
        span {
          position: absolute;
          z-index: 9999;
          top: 7px;
          left: 50%;
          width: 10px;
          margin-left: -6px;
          font-size: 14px;
          color: #FFF;
        }
      }
      .col-3 {
        position: relative;
        img.petitsPoints {
          position: absolute;
          z-index: 99;
          bottom: -40px;
          left: -40px;
        }
        img.femme {
          position: absolute;
          z-index: 999;
          bottom: -30px;
          right: 0;
          width: 108px;
          max-width: 108px;
        }
      }
      h3 {
        color: #FFF;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        position: relative;
        z-index: 9999;
        span {
          color: #0C86C2;
        }
      }
      button {
        color: #FFF;
        border: 1px solid #FFF;
        border-radius: 4px;
        padding: 10px 25px;
        text-align: center;
        
      }
    }
    .main-calendar {
      background: #FFFFFF;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.17);
      border-radius: 8px;
      padding-bottom: 10px;
      margin-top: 22px;
      ul {
        text-align: right;
        margin: 0;
        list-style: none;
        padding: 5px 8px;
        li {
          display: inline-block;
          margin-left: 7px;
          button {
            color: #263238;
            border: 1px solid #BDBDBD;
            border-radius: 50%;
            width: 29px;
            height: 29px;
            font-size: 12px;
          }
        }
      }
      .days {
        text-align: center;
        padding-left: 1%;
        .calendar-day {
          float: left;
          width: 14%;
          text-align: center;
          .day-header {
            display: block;
            color: #D2CECE;
            margin: 0 auto 12px;
          }
          .day-nb {
            display: block;
            color: #8E8D8D;
            width: 36px;
            height: 36px;
            margin: 0 auto;
            padding-top: 4px;
            &.hasPoints {
              border: 1px solid #0472A8;
              color: #0472A8;
              border-radius: 50%;
            }
            &.isToday {
              border: 1px solid #0472A8;
              color: #FFFFFF;
              background: #0472A8;
              border-radius: 50%;
            }
          }
          .point-container {
            height: 70px;
            position: relative;
            border-right: 1px #EDEDED solid;
            margin-top: 6px;
            .points {
              position: absolute;
              bottom: 3px;
              left:0;
              right:0;
              text-align: center;
              color: #E0E0E0;
              &.pointsValide {
                color: #0472A8;
              }
              span {
                font-size: 11px;
                margin-left: 3px;
              }
            }
            &.todayPoint {
              .points {
                color: #0472A8;
              }
            }
          }
          &:last-child {
            .point-container {
              border-right: 0;
            }
          }
        }
      }
    }
    .faireBilan {
      margin-top: 22px;
      background: #B4C572;
      padding: 39px 0 35px;
      box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.19);
      border-bottom-right-radius: 30px;
      border-top-left-radius: 30px;
      position: relative;
      overflow: hidden;
      .points-verts {
        position: absolute;
        width: 201px;
        max-width: 201px;
        z-index: 99;
        left: 50%;
        top: 0;
        margin-left: -100.5px;
      }
      .col-3 {
        position: relative;
        img {
          position: absolute;
          bottom: -35px;
          left: 20px;
        }
      }
      .col-9 {
        position: relative;
        img.petitsPoints {
          position: absolute;
          z-index: 99;
          right: 15%;
          bottom: -30px;
        } 
      }
      h3 {
        position: relative;
        z-index: 9999;
        font-size: 28px;
        color: #FFF;
        text-align: center;
        font-weight: 500;
        span {
          font-weight: bold;
        }
      }
    }
    .bilan-wrapper {
      position: relative;
      padding-bottom: 50px;
      .activite-item {
        margin-bottom: 12px;
      }
      .arrow-down {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 25px solid #AABB66;
        margin: 0 auto;
      }
      .trait-droit {
        position: absolute;
        width: 6px;
        background: #AABB66;
        top: 0;
        left: 50%;
        margin-left: -3px;
        height: 100%;
      }
      .bilan-labels {
        color: #A2A2A2;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 25px;
        span {
          position: relative;
          &:before {
            content: "\2022";
            color: red; 
            font-weight: bold;
            font-size: 32px;
            display: inline-block;
            width: 10px;
            left: -18px;
            position: absolute;
            top: 1px;
            line-height: 10px;
          }
          &.intense:before {
            color: #DF778E;
          }
          &.modere:before {
            color: #EDBE88;
          }
          &.leger:before {
            color: #75A843;
          }
        }
      }
      .act-res-wrapper {
        padding: 15px 0 10px;
        margin: 0;
        .act-res {
          text-align: center;
          div {
            color: #979797;
            font-weight: 500;
            font-size: 11px;
            line-height: 1.3em;
            margin-top: 3px;
          }
          &.act-resHumeur {
            img {
              max-width: 38px;
            }
            div {
              margin-top: 5px;
            }
            &.act-resHumeur-mouai {
              color: #ECBA6D;
            }
          }
        }
      }      
    }
  }
  @media screen and (max-width: 768px) {
    body {
      .animation {
        img {
          max-width: 260px;
        }
      }
      .journal-header {
        background: url('../../public/img/bgJournal414.png') no-repeat center top;
        background-size: cover;
        padding-top: 10px;
        height: 346px;
        width: 414px;
        margin: 0 auto;
        position: relative;
        h1 {
          margin-top: 121px !important;
        }
      }
      .date-nav {
        bottom: 10px;
      }
      .homme-icon {
        max-width: 200px;
        margin-left: -100px;
        bottom: 38px;
      }
      .humeur-icon {
        left: 30px;
        bottom: 105px;
      }
      .symptomes-icon {
        left: 67px;
        bottom: 180px;
      }
      .stress-icon {
        right: 90px;
        bottom: 180px;
      }
      .sommeil-icon {
        right: 37px;
        bottom: 105px;
      }
    }
  }
  @media screen and (max-width: 414px) {
    body {
      .animation {
        img {
          max-width: 230px;
        }
      }
      .journal-header {
        background: url('../../public/img/bgJournal360.png') no-repeat center top;
        background-size: cover;
        padding-top: 10px;
        height: 301px;
        width: 360px;
        margin: 0 auto;
        position: relative;
        h1 {
          margin-top: 104px !important;
        }
      }
      .date-nav {
        bottom: 5px;
      }
      .homme-icon {
        max-width: 184px;
        margin-left: -92px;
        bottom: 25px;
      }
      .humeur-icon {
        left: 21px;
        bottom: 79px;
      }
      .symptomes-icon {
        left: 59px;
        bottom: 156px;
      }
      .stress-icon {
        right: 77px;
        bottom: 156px;
      }
      .sommeil-icon {
        right: 24px;
        bottom: 79px;
      }
    }
  }
  @media screen and (max-width: 360px) {
    body {
      .animation {
        img {
          max-width: 213px;
        }
      }
      .journal-header {
        background: url('../../public/img/bgJournal300.png') no-repeat center top;
        background-size: cover;
        padding-top: 10px;
        height: 279px;
        width: 300px;
        margin: 0 auto;
        position: relative;
        h1 {
          margin-top: 91px !important;
        }
      }
      .date-nav {
        bottom: -3px;
      }
      .homme-icon {
        max-width: 166px;
        margin-left: -83px;
        bottom: 14px;
      }
      .humeur-icon {
        left: 2px;
        bottom: 69px;
      }
      .symptomes-icon {
        left: 32px;
        bottom: 140px;
      }
      .stress-icon {
        right: 54px;
        bottom: 140px;
      }
      .sommeil-icon {
        right: 5px;
        bottom: 69px;
      }
    }
  
  }

</style>