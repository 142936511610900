import axios from "axios";
import router from "../router";

export default {
  secuConfig : {
    headers: { 'Authorization': `${localStorage.apitoken}` }
  },
  refreshConfig() {
    this.secuConfig = {
      headers: { 'Authorization': `${localStorage.apitoken}` }
    };
  },
  manageError(error) {
    console.log('Error', error);
    var response = error.response;
    if (response && (response.status === 401 || response.status === 403)) {
      console.log(response.data);
      if (response.data.message !== "") {
        localStorage.connexionMsg = response.data.message;
      }
      router.push('/connexion');
    }
  },
  getCspValues() {
    var thisContext = this;
    const config = {
        headers: { 'Authorization': `${localStorage.apitoken}` }
      };
    return new Promise((resolve) => {
      axios.get(process.env.VUE_APP_URL_API + '/api/getCspValues', config)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse', response);
        if (!response) {
          router.push('/connexion');
        } else {
          resolve(response.data);
        }
      });
    });    
  },
  isFormInitDone() {
    var thisContext = this;
    const config = {
      headers: { 'Authorization': `${localStorage.apitoken}` }
    };
    return new Promise((resolve) => {
      axios.get(process.env.VUE_APP_URL_API + '/api/isFormInitDone', config)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse', response);
          if (!response) {
            router.push('/connexion');
          } else {
            resolve(response.data);
          }
        });
    });
  },
  getActTypesValues() {
    var thisContext = this;
    return new Promise((resolve) => {
      axios.get(process.env.VUE_APP_URL_API + '/api/getActTypesValues', this.secuConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse', response);
          if (!response) {
            router.push('/connexion');
          } else {
            resolve(response.data);
          }
        });
    });
  },
  getFavorisActTypesValues() {
    var thisContext = this;
    return new Promise((resolve) => {
      axios.get(process.env.VUE_APP_URL_API + '/api/getFavorisActTypesValues', this.secuConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse', response);
          if (!response) {
            router.push('/connexion');
          } else {
            resolve(response.data);
          }
        });
    });
  },
  getUserProfil() {
    var thisContext = this;
    return new Promise((resolve) => {
      axios.get(process.env.VUE_APP_URL_API + '/api/getUserProfil', this.secuConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse', response);
          if (!response) {
            router.push('/connexion');
          } else {
            resolve(response.data);
          }
        });
    });
  },  
  submitFormInit(formContainer) {
    return axios.post(process.env.VUE_APP_URL_API + '/api/submitFormInit', {
      prenom: formContainer.prenom,
      annivDay: formContainer.annivDay,
      annivMonth: formContainer.annivMonth,
      annivYear: formContainer.annivYear,
      genre: formContainer.genre,
      taille: formContainer.taille,
      poidsActuel: formContainer.poidsActuel,
      poidsIdeal: formContainer.poidsIdeal,
      douleurDos: formContainer.douleurDos,
      douleurFessesJambes: formContainer.douleurFessesJambes,
      aspect: formContainer.aspect,
      souhait: formContainer.souhait,
      plusImportant: formContainer.plusImportant,
      freinsPotentiels: formContainer.freinsPotentiels,
      plusaprecie: formContainer.plusaprecie,
      soutenuEntourage: formContainer.soutenuEntourage,
      sourceStress: formContainer.sourceStress,
      tempsLibre: formContainer.tempsLibre,
      secteurActivite: formContainer.secteurActivite,
      profession: formContainer.profession,
      sentezTravail: formContainer.sentezTravail,
      habitudesAlimentaires: formContainer.habitudesAlimentaires,
      journeeType: formContainer.journeeType,
      enduranceCardio: formContainer.enduranceCardio,
      renforcementMusc: formContainer.renforcementMusc,
      actPhysiqueAutre: formContainer.actPhysiqueAutre,
      frequenceExercMusc: formContainer.frequenceExercMusc
    }, this.secuConfig);
  },
  submitFormComi(formContainer) {
    return axios.post(process.env.VUE_APP_URL_API + '/api/submitFormComi', {
      formComi: formContainer
    }, this.secuConfig);
  },
  submitFormHad(formContainer) {
    return axios.post(process.env.VUE_APP_URL_API + '/api/submitFormHad', {
      formHad: formContainer
    }, this.secuConfig);
  },
  submitMic(formContainer) {
    return axios.post(process.env.VUE_APP_URL_API + '/api/submitMic', {
      formHad: formContainer
    }, this.secuConfig);
  },

  submitSaisie(saisie) {
    let retour = null;
    var thisContext = this;
    return new Promise((resolve) => {
      axios.post(process.env.VUE_APP_URL_API + '/api/submitSaisie', {
        saisie
      }, this.secuConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse: '+ response);
          if (!response) {
            router.push('/connexion');
          } else if (response.status === 200 || response.status === 201){
            resolve(response.data);
          } else {
            localStorage.connexionMsg = "Une erreur est survenue";
            retour = 'ko';
            resolve(retour);
          }
        });
    });
  },
  submitProfil(profil) {
    let retour = null;
    var thisContext = this;
    return new Promise((resolve) => {
      axios.post(process.env.VUE_APP_URL_API + '/api/submitProfil', {
        profil: profil
      }, this.secuConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse: '+ response);
          if (!response) {
            router.push('/connexion');
          } else if (response.status === 200 || response.status === 201){
            resolve(response.data);
          } else {
            localStorage.connexionMsg = "Une erreur est survenue";
            retour = 'ko';
            resolve(retour);
          }
        });
    });
  },
  submitActPhysique(title, met, isFavoris) {
    let retour = null;
    var thisContext = this;
    return new Promise((resolve) => {
      axios.post(process.env.VUE_APP_URL_API + '/api/submitActPhysique', {
        title: title,
        met: met,
        isFavoris: isFavoris
      }, this.secuConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse: '+ response);
          if (!response) {
            router.push('/connexion');
          } else if (response.status === 200 || response.status === 201){
            resolve(response.data);
          } else {
            localStorage.connexionMsg = "Une erreur est survenue";
            retour = 'ko';
            resolve(retour);
          }
        });
    });
  },
  
  submitFavoris(favorisActType) {
    let retour = null;
    var thisContext = this;
    return new Promise((resolve) => {
      axios.post(process.env.VUE_APP_URL_API + '/api/submitFavorisActPhysique', {
        favorisActType: favorisActType
      }, this.secuConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse: '+ response);
          if (!response) {
            router.push('/connexion');
          } else if (response.status === 200 || response.status === 201){
            resolve(response.data);
          } else {
            localStorage.connexionMsg = "Une erreur est survenue";
            retour = 'ko';
            resolve(retour);
          }
        });
    });
  },
  getJournalData(startDate, endDate) {
    let specificConfig = this.secuConfig;
    console.log(specificConfig);
    // let urlRequest =  process.env.VUE_APP_URL_API + '/api/getjournaldata/' + startDate + '/' + endDate;
    // console.log('urlRequest ' + urlRequest);
    var thisContext = this;
    return new Promise((resolve) => {
      axios.get(process.env.VUE_APP_URL_API + '/api/getjournaldata/' + startDate + '/' + endDate, specificConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })        
        .then(response => {
          console.log('reponse', response);
          if (!response) {
            router.push('/connexion');
          } else {
            resolve(response.data);
          }
        });
    });
  },
  getProgressionData(type, startDate, endDate) {
    let specificConfig = this.secuConfig;
    console.log(specificConfig);
    // let urlRequest =  process.env.VUE_APP_URL_API + '/api/getjournaldata/' + startDate + '/' + endDate;
    // console.log('urlRequest ' + urlRequest);
    var thisContext = this;
    return new Promise((resolve) => {
      axios.get(process.env.VUE_APP_URL_API + '/api/getProgressionData/' + type + '/' + startDate + '/' + endDate, specificConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })        
        .then(response => {
          console.log('reponse', response);
          if (!response) {
            router.push('/connexion');
          } else {
            resolve(response.data);
          }
        });
    });
  },
  getActivitiesParJour(currentDay, currentDate) {
    var thisContext = this;
    return new Promise((resolve) => {
      axios.get(process.env.VUE_APP_URL_API + '/api/getActivitiesParJour/' + currentDay + '/' +  currentDate, this.secuConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse', response);
          if (!response) {
            router.push('/connexion');
          } else {
            resolve(response.data);
          }
        });
    });
  },
  getExistingSaisie(currentDay, currentDate) {
    var thisContext = this;
    return new Promise((resolve) => {
      axios.get(process.env.VUE_APP_URL_API + '/api/getExistingSaisie/' + currentDay + '/' +  currentDate, this.secuConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse', response);
          if (!response) {
            router.push('/connexion');
          } else {
            resolve(response.data);
          }
        });
    });
  },
  getPlannings() {
    var thisContext = this;
    return new Promise((resolve) => {
      axios.get(process.env.VUE_APP_URL_API + '/api/getplannings', this.secuConfig)
        .catch(function(error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse', response);
          if (!response) {
            router.push('/connexion');
          } else {
            resolve(response.data);
          }
        });
    });
  },
  submitPlanning(activites) {
    let retour = null;
    var thisContext = this;
    return new Promise((resolve) => {
      axios.post(process.env.VUE_APP_URL_API + '/api/submitplanning', {
        activites
      }, this.secuConfig)
        .catch(function (error) {
          thisContext.manageError(error);
        })
        .then(response => {
          console.log('reponse: ' + response);
          if (!response) {
            router.push('/connexion');
          } else if (response.status === 200 || response.status === 201) {
            retour = 'ok';
            resolve(retour);
          } else {
            retour = 'ko';
            resolve(retour);
          }
        });
    });
  },
  // getCspValues() {
  //   return axios.get(process.env.VUE_APP_URL_API + '/api/getCspValues');    
  // },
  
};