<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div class="top-template journal">
    <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
    <div class="profil-header">
      <button class="backbutton" @click="this.$router.push({ path: '/journal'})"><font-awesome-icon icon="chevron-left" /></button>
      <h1>Profil</h1>
    </div>
  </div>

  <div class="profil-form-wrapper">
    <div class="padding-wrapper profil-form" >
      <div class="form-item">
        <label>Prénom ou pseudo</label>
        <div class="input-length-wrapper">
          <input type="text" v-model="formContainer.prenom" maxlength="30">
          <span class="input-length">{{ getSize(formContainer.prenom) }}/30</span>
        </div>
      </div>
      <div class="form-item">
        <label>Id Patient</label>
        <div class="input-length">
          <input type="text" disabled="disabled" v-model="formContainer.idPatient">
        </div>
      </div>
      <div class="form-item">
        <label>Email</label>
        <div class="input-length">
          <input type="email" required v-model="formContainer.email">
        </div>
      </div>
      <div class="form-item">
        <label>Changer de mot de passe</label>
        <div class="input-wrapper">
          <input v-model="formContainer.password" name="password" :type="passwordFieldType" required />
          <button class="switchVisibility" @click="switchVisibility"><img src="/img/eye.png" alt="eye"></button>
        </div>
      </div>
<!--      <div class="form-item">-->
<!--        <label>Centre</label>-->
<!--        <div class="input-wrapper">-->
<!--          <input type="text" disabled="disabled" v-model="formContainer.centre" >-->
<!--        </div>-->
<!--      </div>-->
      <!--    <div class="form-item">-->
      <!--      <label>Coordinateur</label>-->
      <!--      <div class="input-wrapper">-->
      <!--        <input type="text" disabled="disabled" v-model="formContainer.coordinateur" >-->
      <!--      </div>-->
      <!--    </div>-->

      <p :class="resultClass" v-show="resultTxt !== ''">{{ resultTxt }}</p>
      <input type="submit" @click="submitForm" class="btn-emot" value="Enregistrer" >
      <a href="#" @click.prevent.stop="deconnexion()" class="deconnexion">Déconnexion</a>
    </div>
  </div>
  <emotiv-footer activeItem="profil"></emotiv-footer>
</template>

<script>
  // @ is an alias to /src
  // import TheHeader from '@/components/TheHeader.vue'
  import emotivFooter from "../components/EmotivFooter";
  import backend from "../api/backend";
  
  export default {
    name: 'Profil',
    components: {
      emotivFooter
    },
    data() {
      return {
        isLoading: true,
        hasError: false,
        resultTxt: "",
        resultClass: "resultTxt",
        erreur: '',
        success: '',
        passwordFieldType: "password",
        formContainer: {
          prenom: "",
          idPatient: "",
          email: "",
          password: "",
          centre: "",
          coordinateur: ""
        }
      }
    },
    methods: {
      switchVisibility() {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      },
      validateEmail(email) {
        const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
        return emailRegex.test(email)
      },
      getSize(param) {
        if (param) {
          return param.length;
        } else {
          return 0;
        }
      },
      deconnexion() {
        localStorage.removeItem('actTypes');
        localStorage.removeItem('connexionMsg');
        localStorage.removeItem('favorisActType');
        localStorage.removeItem('apitoken');
        this.$router.push('/connexion');
      },
      submitForm() {
        this.hasError = false;
        this.isButtDisabled = true;
        this.resultClass = "resultTxt success";
        this.resultTxt = '';
        if (!this.formContainer.email) {
          this.hasError = true;
          this.resultTxt = "Merci de renseigner votre email."
          this.resultClass = "resultTxt error";
        } else {
          if (!this.validateEmail(this.formContainer.email)) {
            this.hasError = true;
            this.resultTxt = "L'email saisi n'est pas valide."
            this.resultClass = "resultTxt error";
          }
        }
        if (this.formContainer.password && this.formContainer.password.length < 8) {
          this.hasError = true;
          this.resultTxt = "Votre mot de passe doit contenir au moins 8 caractères."
          this.resultClass = "resultTxt error";
        }
        if (!this.hasError) {
          this.isLoading = true;
          backend.submitProfil(this.formContainer).then(response => {
            this.formContainer.password = '';
            if (response.result === "ok") {
              this.resultTxt = "Profil enregistré avec succès !";
              // setTimeout( () => this.$router.push({ path: '/journal'}), 2000);
            } else {
              if (response.errors) {
                var thisContext = this;
                response.errors.forEach(function (err) {
                  thisContext.resultTxt = thisContext.resultTxt + err;
                });
                this.resultClass = "resultTxt error";
              } else {
                this.resultTxt = "Erreur lors de la soumission de votre profil.";
                this.resultClass = "resultTxt error";
              }
              this.resultTxt = "Erreur lors de la soumission de votre profil.";
              this.resultClass = "resultTxt error";
              console.log(response.errors);
            }
            this.isLoading = false;
          });
        }
      }
    },
    async mounted() {
      window.scrollTo(0, 0);
      // Get user infos
      const currentUserData = await backend.getUserProfil();
      if (currentUserData !== null) {
        this.formContainer.prenom = currentUserData.prenom;
        this.formContainer.email = currentUserData.email;
        this.formContainer.idPatient = currentUserData.idPatient;
        this.formContainer.centre = currentUserData.centre;
        this.formContainer.coordinateur = currentUserData.coordinateur;
        this.isLoading = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
body {
  background: #E5E5E5;
  .top-template {
    background: #E5E5E5;
    bottom:0;
  }
  .profil-header {
    background: url('../../public/img/bg.png') no-repeat center bottom;
    background-size: 100% auto;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    color: #EDF9FF;
    .backbutton {
      position: absolute;
      left: 15px;
      top: 15px;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      font-size: 13px;
      color: #0472A8;
      background: #FFF;
      padding-top: 2px;
      border: 1px solid #0472A8;
    }
    h1 {
      margin: 50px auto 70px !important;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #EDF9FF;
    }
  }

  .profil-form {
    label {
      font-size: 15px;
    }
    ::placeholder {
      color: #7C7A7A !important;
      font-family: "Inter", sans-serif;
      opacity: 1;
    }
    .padding-footer {
      padding: 100px 15px 30px;
    }
    input[type=text], input[type=email], input[type=password]{
      background: #FFFFFF;
      box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      margin: 10px 0;
      padding: 15px;
      border: none;
    }
    .form-item {
      margin-bottom: 10px;
    }
    button.switchVisibility {
      position: absolute;
      right: 10px;
      top: 21px;
      width: auto !important;
      background: #FFF;
    }
  }
  .profil-form-wrapper {
    background: #E5E5E5;
    a.deconnexion {
      display: block;
      margin: 20px auto;
      font-size: 14px;
      text-align: center;
    }
  }
  .padding-wrapper {
    padding: 70px 0 80px;
    background: transparent;
    position: relative;
    z-index: 999;
    width: calc(100% - 42px);
    margin: 135px auto 0;
    border-radius: 8px;
  }

  .padding-footer {
    background: #E5E5E5;
    padding: 30px 0;
  }

  p.resultTxt {
    margin: 30px 0 20px;
    text-align: center;
  }
}

</style>