<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div class="top-template home">
    <img src="/img/logo_welcome.png" alt="Emotivapp"><br>
    <h1 class="mt-4">Vivre mieux avec la <br>lombalgie au quotidien</h1>
    <div class="block-btn" >
      <div class="row">
        <div class="col-12">
          <router-link class="btn btn-emot fullwidth mt-5" to="/introduction">Commencer</router-link>
        </div>
        <div class="col-12">
          <router-link class="btn btn-emot btn-emot-inv fullwidth" to="/connexion">Se connecter</router-link>
        </div>
      </div>
    </div>
    <p class="accueil-footer">
      En continuant, vous acceptez nos <a href="#" target="_blank">conditions d’utilisation</a> et <a href="#" target="_blank">politique de protection de la vie privée</a>
    </p>
  </div>
</template>

<script>
  import authentication from "@/api/authentication";

  export default {
    name: 'Accueil',
    data() {
      return {
        isLoading: true,
      }
    },

    async created () {
      // Authenticate
      const isAuthenticate = await authentication.isAuthenticate();
      console.log('isAuthenticate : '+isAuthenticate);
      if (isAuthenticate) {
        this.$router.push('journal');
      }
      this.isLoading = false;
    },
  }
</script>

<style lang="scss">
  .accueil-footer {
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 23px;
    background: #FFF;
  }
  body .btn-emot.btn-emot-inv {
    font-size: 18px;
  }
</style>
