<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div class="top-template mdpOublie">
    <h1>Réinitialiser de votre mot de passe</h1>
    <div class="input-wrapper">
      <input v-model.trim="password" name="password" :type="passwordFieldType" placeholder="Mot de passe*" required />
      <button class="switchVisibility" @click="switchVisibility"><img src="/img/eye.png" alt="eye"></button>
    </div>
      <p :class="resultClass" v-show="hasResult">{{ resultTxt }}</p>
      <input type="submit" @click="submitForm" class="btn-emot" value="Envoyer" >
  </div>
</template>

<script>

  // import axios from 'axios'
  import authentication from "../api/authentication";
  
  export default {
    name: 'ReinitialisationMdp',
    data() {
      return {
        isLoading: false,
        passwordFieldType: "password",
        verifKey: null,
        password: null,
        emailOrIdPatient: null,
        hasResult: false,
        resultClass: 'error',
        resultTxt: null,
        testVar: "test"
      }
    },
    methods: {
      switchVisibility() {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      },
      submitForm() {
        this.hasResult = false;
        let isValid = false;
        if (this.password) {
          isValid = true;
        }
        if (!isValid) {
          this.resultClass = 'error';
          this.hasResult = true;
          this.resultTxt = "Veuillez renseigner le mot de passe."
        } else {
          this.isLoading = true;
          authentication.resetPassword(this.verifKey, this.password).then(response => {
            this.hasResult = true;
            this.testVar = response;
            if (response.status === 200) {
              if (response.data.result === "ok") {
                this.resultClass = 'success';
                this.resultTxt = "Mot de passe réinitialisé avec succès !";
                setTimeout( () => this.$router.push({ path: '/connexion'}), 1000);
              } else {
                this.resultClass = 'error';
                this.resultTxt = response.data.errorTxt;
              }
            } else {
              this.resultClass = 'error';
              this.errorTxt = "Erreur lors de l'accès au serveur";
            }
            this.isLoading = false;
            }
          );
        }
               
      }
    },
    async created() {
      console.log(this.$route.params.verifKey);
      this.verifKey = this.$route.params.verifKey;
    }
  }
</script>

<style lang="scss">
  h1 {
    text-align: center;
    margin: 50px auto !important;
    font-weight: 600 !important;
    font-size: 30px;
  }
</style>