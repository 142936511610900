<template>
  <div v-show="isLoading" class="loader"><img src="/img/scroll-loader.gif" alt="Loader"></div>
  <div class="top-template mdpOublie">
    <h1>Réinitialiser votre mot de passe</h1>
      <div class="input-wrapper"><input v-model.trim="emailOrIdPatient" name="emailOrIdPatient" type="text" placeholder="Email ou ID patient*" required /></div>
      <p :class="resultClass" v-show="hasResult">{{ resultTxt }}</p>
      <input type="submit" @click="submitForm" class="btn-emot" value="Envoyer" >
      
      <p class="switchConnexion mt-3">Retour à l'écran de connexion ? <router-link class="" to="/connexion">Cliquer ici</router-link></p>
  </div>
</template>

<script>

  // import axios from 'axios'
  import authentication from "../api/authentication";
  
  export default {
    name: 'MdpOublie',
    data() {
      return {
        isLoading: false,
        emailOrIdPatient: null,
        hasResult: false,
        resultClass: 'error',
        resultTxt: null
      }
    },
    methods: {
      submitForm() {
        this.hasResult = false;
        let isValid = false;
        if (this.emailOrIdPatient) {
          isValid = true;
        }
        if (!isValid) {
          this.resultClass = 'error';
          this.hasResult = true;
          this.resultTxt = "Veuillez renseigner votre email ou ID patient."
        } else {
          this.isLoading = true;
          authentication.reinitPassword(this.emailOrIdPatient).then(response => {
              if (response.status === 200) {
                this.hasResult = true;
                if (response.data.result === "ok") {
                  this.resultClass = 'success';
                  this.resultTxt = "Un email pour reinitialiser votre mot de passe vous a été envoyé."
                } else {
                  this.resultClass = 'error';
                  this.resultTxt = response.data.errorTxt;
                }
              } else {
                this.hasError = true;
                this.errorTxt = "Erreur lors de l'accès au serveur";
              }
              this.isLoading = false;
            }
          );
        }
             
      }
    },
  }
</script>

<style lang="scss">
  h1 {
    text-align: center;
    margin: 50px auto !important;
    font-weight: 600 !important;
    font-size: 30px;
  }
</style>