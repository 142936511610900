import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import BootstrapVue from 'bootstrap-vue-3/dist/bootstrap-vue-3.es.js';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/app.scss';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faXmark);
library.add(faMinus);
library.add(faPlus);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faCheck);

createApp(App)
  .use(router)
  .use(BootstrapVue)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount('#app')
